import React from 'react';
import { Link } from 'react-router-dom';
import Cancelable from '../cancelable.js';

class NoUserKeyDialog extends React.Component {
    state = {
        loading_keys: { op: null, error: null },
        keys: [],
    }

    componentDidMount () {
        if (this.props.backend) {
            this.loadData();
        }
    }

    loadData () {
        let loading_keys = new Cancelable(this.props.backend.getMyKeys());

        loading_keys
            .then((response) => {
                this.setState({ keys: response.data.keys, loading_keys: { op: null, error: null }});
            })
            .catch((e) => {
                if (e && e.response && e.response.status === 404) {
                    this.setState({ loading_keys: { op: null, error: null } });
                }

                this.setState({ loading_keys: { op: null, error: e }})
            })

        this.setState({
            loading_keys: { op: loading_keys, error: null }
        });
    }

    componentWillUnmount () {
        if (this.state.loading_keys.op) {
            this.state.loading_keys.op.cancel();
        }
    }

    render () {
        let keys = this.props.keys ? this.props.keys : this.state.keys;
        let loading = this.props.loader ? this.props.loader : this.state.loading_keys;

        // Only show message if there are no keys and loading is finished
        if (!keys.length && !loading.op && !loading.error) {
            return (
                <div className="alert alert-danger mt-3 offset-md-2 col-sm-8 text-center" role="alert">
                    You have currently no keys defined, define a key now to share passwords
                    <br />
                    <div className="mt-3 text-center">
                        <Link to="/passwords/keys" className="btn btn-outline-success btn-sm">Define a new key</Link>
                    </div>
                </div>
            );
        }

        return null;
    }
}

export default NoUserKeyDialog;
