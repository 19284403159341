import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select, { Creatable } from 'react-select'

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import ValidationRules from './validation.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import { getDifference } from './helper.js';


export default class EditGroup extends React.Component {
    state = {
        group: {},
        original: {},
        organizations: [],
        groupTypes: [],
        hasChanged: false,
        allOrganizations: [],

        loadingGroup: { op: null },
        saving: { op: null },
        success: null,
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingGroup.op) {
            this.state.loadingGroup.op.cancel();
        }

        if (this.state.saving.op) {
            this.state.saving.op.cancel();
        }
    }

    loadData () {
        let loadingGroup = new Cancelable(this.props.backend.getGroup(this.props.guid));

        loadingGroup
            .then(response => {
                let group = response.data;

                const groupTypes = Object.keys(group.group_type)
                    .map(t => ({ label: t, value: t }));

                group.group_type = groupTypes
                    .filter(t => group.group_type[t.value]);

                try {
                    group.allowed_hosts = JSON.parse(group.allowed_hosts)
                        .map(h => ({ value: h, label: h }));
                } catch (err) {
                    group.allowed_hosts = [];
                }

                this.setState({
                    loadingGroup: { op: null },
                    original: group,
                    group,
                    groupTypes,
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingGroup: { op: null, error: err }});
            });

        this.setState({
            loadingGroup: { op: loadingGroup },
        })
    }

    onChangeField (field, event) {
        let group = Object.assign({}, this.state.group);

        if (ValidationRules[field] && ValidationRules[field].validate) {
            ValidationRules[field].validate(event.target);
        }

        if (event && event.target) {
            group[field] = event.target.value;
        } else if (field === 'organization') {
            group[field] = event.dn;
        } else {
            group[field] = event;
        }

        const difference = getDifference(this.state.original, group);

        this.setState({
            group,
            hasChanged: Object.keys(difference).length > 0
        });
    }

    box (prop, value) {
        return (
            <div key={prop.name} className="form-group row">
                <label className="col-sm-3 col-form-label">{prop.name}</label>
                <div className="col-sm-9">
                    {prop.control && prop.control(prop.read, prop.write)}

                    {!prop.control &&
                        <input type="text" className="form-control" value={value || ''}
                               onChange={this.onChangeField.bind(this, prop.field)}
                               readOnly={prop.read && !prop.write}
                        />
                    }

                    <small className="form-text text-muted">
                        {ValidationRules[prop.field] && ValidationRules[prop.field].hint}
                    </small>
                </div>
            </div>
        );
    }

    save () {
        let difference = getDifference(this.state.original, this.state.group);
        console.log(this.state.original, this.state.group, difference);

        if ('allowed_hosts' in difference) {
            difference.allowed_hosts = difference.allowed_hosts.map(h => h.label);
        }

        let saving = this.props.backend.modifyGroup(this.props.guid, difference)
            .then(response => {
                let original = Object.assign({}, this.state.original);

                for (let prop in response.data.changes) {
                    if (prop === 'allowed_hosts') {
                        original[prop] = response.data.changes[prop].map(h => ({ label: h, value: h }));
                    } else {
                        original[prop] = response.data.changes[prop];
                    }
                }

                this.setState({
                    saving: { op: null },
                    original,
                    group: original,
                    hasChanged: false,
                    success: 'Group sucessfully saved'
                });

                setTimeout(() => this.setState({ success: null }), 4000);
            })
            .catch(err => {
                console.log(err);

                if (err.response && err.response.status === 403) {
                    err = new Error('You are not allowed to change this field.');
                }

                this.setState({ saving: { op: null, error: err } });
            });

        this.setState({ saving: { op: saving }, success: null });
    }

    render () {
        let saveBtn = null;
        let viewFields = [];
        let editFields = [];

        if (!this.state.saving.op) {
            if (this.state.saving.op) {
                saveBtn = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
            } else {
                saveBtn = (
                    <button className="btn btn-outline-success btn-sm"
                            disabled={!this.state.hasChanged} onClick={this.save.bind(this)}>
                        Save
                    </button>
                );
            }
        }

        if (this.props.permissionManager.isAllowedOrganization('groups_list', this.state.group.organization)) {
            viewFields = this.props.permissionManager.getAllowedFields('groups_list');
        }

        if (this.props.permissionManager.isAllowedOrganization('groups_edit', this.state.group.organization)) {
            editFields = this.props.permissionManager.getAllowedFields('groups_edit');
        }

        // TODO: Can groupType be changed in ldap?
        const groupTypeControl = (read, write) => (
            <Select
                options={this.state.groupTypes} value={this.state.group.group_type}
                isMulti isDisabled={true} onChange={this.onChangeField.bind(this, 'group_type')}
            />
        );

        const organizationControl = (read, write) => (
            <Select
                options={this.props.backend.organizations}
                value={this.props.backend.organizations.find(o => o.dn === this.state.group.organization)}
                onChange={this.onChangeField.bind(this, 'organization')}
                getOptionValue={o => o.dn} getOptionLabel={o => o.name}
                isDisabled={read && !write} isClearable placeholder="Select organization..."
            />
        );

        const allowedHostsControl = (read, write) => (
            <Creatable
                value={this.state.group.allowed_hosts}
                isMulti isClearable placeholder="Enter allowed hosts..."
                onChange={this.onChangeField.bind(this, 'allowed_hosts')}
                isDisabled={read && !write}
            />
        );

        let fields = [];
        if (this.props.fields) {
            for (const field of this.props.fields) {
                switch (field.field) {
                    case 'group_type':
                        field.control = groupTypeControl;
                        break;

                    case 'organization':
                        field.control = organizationControl;
                        break;

                    case 'allowed_hosts':
                        field.control = allowedHostsControl;
                        break;

                    default: break;
                }

                if (viewFields.includes(field.field)) {
                    field.read = true;
                }

                if (editFields.includes(field.field)) {
                    field.write = field.read = true;
                }

                if (field.read) {
                    fields.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingGroup, this.state.saving ]}

                    left={() => {
                        return (
                            <Fragment>
                                <Link to={'/groups'} className="btn btn-outline-secondary btn-sm">Back</Link>
                                <Link to={'/groups/' + this.props.guid} className="btn btn-outline-secondary btn-sm ml-1">View</Link>
                            </Fragment>
                        );
                    }}

                    middle={() => (
                        <AlertMessageDialog
                            success={[ this.state.success ]}
                            errors={[ this.state.loadingGroup.error, this.state.saving.error ]}
                        />
                    )}

                    right={() => {
                        if (!this.state.group) {
                            return null;
                        }

                        return (
                            <Fragment>
                                {saveBtn}
                            </Fragment>
                        )
                    }}
                />

                {this.state.group &&
                    <form className="offset-md-2 col-sm-8 mt-3 needs-validation">
                        {fields.map(prop => {
                            let value = this.state.group[prop.field];

                            switch (prop.field) {
                            case 'organization':
                                const org = this.state.organizations.find(o => value.endsWith(o.value));
                                if (org) {
                                    value = org.label;
                                }
                                break;

                            default: break;
                            }


                            return this.box(prop, value);
                        })}
                        {this.props.fields && fields.length === 0 &&
                            <p className="text-center">No fields are allowed to be edited.</p>
                        }
                    </form>
                }
            </div>
        );
    }
}
