import React from 'react';
import PageMenu from './PageMenu.js'
import Cancelable from './cancelable.js';
import ValidationRules from './validation.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import UserPasswordField from './UserPasswordField.js';

class ChangePassword extends React.Component {
    state = {
        password: null,
        new_password: null,
        new_password_repeat: null,

        saving: { op: null, error: null }
    }

    onChange (prop, e) {
        this.setState({
            [prop]: e.target ? e.target.value : e,
        });
    }

    canSave () {
        // Check for password rules?
        return this.state.password &&
            this.state.new_password &&
            this.state.new_password_repeat &&
            this.state.new_password === this.state.new_password_repeat &&
            ValidationRules.password.validate(this.state.new_password);
    }

    save (e) {
        e.preventDefault();

        let saving = new Cancelable(this.props.backend.changeMyPassword(this.state.password, this.state.new_password));

        saving
            .then(() => {
                this.setState({
                    success: 'Password successfully updated',
                    saving: { op: null, error: null },
                    password: null,
                    new_password: null,
                    new_password_repeat: null,
                });
                setTimeout(() => this.setState({ success: null }), 4000);
            })
            .catch(e => {
                console.log(e, e.response);

                this.setState({
                    saving: { op: null, error: e },
                    password: null,
                });
            });

        this.setState({ saving: { op: saving, error: null }});
    }

    render () {
        let saveButton = null;

        if (this.state.saving.op) {
            saveButton = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
        } else {
            saveButton = <button className="btn btn-outline-success btn-sm" disabled={!this.canSave()} onClick={this.save.bind(this)}>Save</button>
        }

        return (
            <div className="container">
                <PageMenu
                    middle={() => {
                        return <AlertMessageDialog
                            success={[this.state.success]}
                            errors={[this.state.saving.error]}
                        />;
                    }}

                    right={() => saveButton}
                />

                <form className="offset-md-2 col-sm-8 mt-3">
                    <div key="old_password" className="form-group row">
                        <label className="col-sm-3 col-form-label">Password</label>
                        <div className="col-sm-9">
                            <input type="password" className="form-control" onChange={this.onChange.bind(this, 'password')}
                                placeholder="Confirm old password" value={(this.state.password || '')} />
                        </div>
                    </div>

                    <div key="new_password" className="form-group row">
                        <label className="col-sm-3 col-form-label">New Password</label>
                        <div className="col-sm-9">
                            <UserPasswordField onChange={this.onChange.bind(this, 'new_password')} />
                        </div>
                    </div>

                    <div key="new_password_repeat" className="form-group row">
                        <label className="col-sm-3 col-form-label">Repeat New Password</label>
                        <div className="col-sm-9">
                            <input type="password" className={"form-control " + (this.state.new_password !== this.state.new_password_repeat ? 'border-warning' : '')}
                                placeholder="Confirm new password" onChange={this.onChange.bind(this, 'new_password_repeat')} value={(this.state.new_password_repeat || '')} />
                            <div className="invalid-feedback">Password does not comply with password guidelines!</div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default ChangePassword;
