import React, { Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";

import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import AlertMessageDialog from '../AlertMessageDialog.js';
import UserPasswordField from '../UserPasswordField.js';

import { KEYUTIL } from 'jsrsasign';

class KeyManager extends React.Component {
    state = {
        keys: [],
        password: null,
        password_confirm: null,
        success: null,

        loading_keys: { op: null, error: null },
        saving: { op: null, error: null },
        generatingKey: false,
        keyToDisplay: null,
    }

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

	componentDidMount () {
		this.loadData();
    }

    loadData () {
        let loading_keys = new Cancelable(this.props.backend.getMyKeys());

        loading_keys
            .then((response) => {
                this.setState({
                    keys: response.data.keys,
                    activeKey: response.data.active_key,
                    loading_keys: { op: null, error: null },
                });
			})
			.catch((e) => {
				if (e && e.response) {
					// keys not found
					if (e.response.status === 404) {
						this.setState({ loading_keys: { op: null, error: null } });
						return;
					}
				}

				this.setState({ loading_keys: { op: null, error: e }})
				setTimeout(() => this.setState({ loading_keys: { op: null, error: null }}), 4000);
			});

		this.setState({ loading_keys: { op: loading_keys, error: null }})
	}

	componentWillUnmount () {
        if (this.state.loading_keys.op) {
            this.state.loading_keys.op.cancel();
        }
	}

    componentDidUpdate (prev) {
        if (this.state.generatingKey) {
            setTimeout(this.generate.bind(this), 100);
        }

        if (this.modalRef.current) {
            this.modalRef.current.addEventListener(
                'hidden.bs.modal',
                () => this.setState({ keyToDisplay: null })
            );
        }
    }

	handleGenerateFormChange (e) {
		this.setState({ [e.target.name]: e.target.value });
	}

    onGenerateFormPasswordChange (password) {
        this.setState({ password });
    }

    onChange (field, index, e) {
        let keys = this.state.keys.slice();
        keys[index][field] = e.target.value;
        keys[index].unsaved = true;

        this.setState({ keys: keys });
    }

    isPasswordValid () {
        return this.state.keyname && this.state.keyname.length > 0 &&
            this.state.password !== null &&
            this.state.password.length >= 8 &&
            this.state.password === this.state.password_confirm;
    }

    isActiveKey (key) {
        return this.state.activeKey === key.id;
    }

    generate () {
        console.log('Starting to generate keypair');
        let t0 = performance.now();

        var rsaKeypair = KEYUTIL.generateKeypair("RSA", 2048);

        let privPem = KEYUTIL.getPEM(rsaKeypair.prvKeyObj, 'PKCS5PRV', this.state.password, 'AES-256-CBC');
        let pubPem = KEYUTIL.getPEM(rsaKeypair.pubKeyObj)

        console.log('Finished generating keypair, took ' + (performance.now() - t0) + 'ms');

        let key = {
            name: this.state.keyname,
            privatekey: privPem,
            publickey: pubPem,
        };

        this.setState({
            keyname: null,
            password: null,
            password_confirm: null,
            generatingKey: false,
        });

        this.save(key);
    }

    onGenerateKey (event) {
        event.preventDefault();

        this.setState({ generatingKey: true });
    }

    canSave (key) {
        return key.name && key.name.length >= 0;
    }

    async updateKeyState (newKey, index, isActive) {
        const activeKey = isActive ? newKey.id : this.state.activeKey;

        let keys = this.state.keys.slice();

        if (index) {
            keys[index] = newKey;
        } else {
            keys.push(newKey);
        }

        this.setState({
            keys,
            activeKey,
        });
    }

    async save (key, index) {
        key = Object.assign({}, key);
        delete key.password;

        let saving = new Cancelable(this.props.backend.postMyKeys(key));

        saving
            .then(async (response) => {
                let key = {
                    id: response.data.id,
                    name: response.data.name,
                    privatekey: response.data.private_key,
                    publickey: response.data.public_key,
                };

                // If we have an index, this key was updated - otherwise freshly generated
                if (index !== undefined) {
                    this.setState({
                        success: { message: 'Successfully updated key', keyId: key.id },
                        saving: { op: null, error: null },
                    });
                } else {
                    this.setState({
                        success: { message: 'Successfully generated and saved key ' + key.name },
                        saving: { op: null, error: null },
                    });
                }

                this.updateKeyState(key, index, response.data.active);

                setTimeout(() => this.setState({ success: null }), 4000);
            })
            .catch((e) => {
                if (index) {
                    e.keyId = key.id;
                }

                this.setState({ saving: { op: null, error: e }});
                setTimeout(() => this.setState({ saving: { op: null, error: null }}), 4000);
            });

        this.setState({
            saving: { op: saving, error: null },
        });
    }

    resetKeyPassword (index) {
        let keys = this.state.keys.slice();
        keys[index].password = null;

        this.setState({ keys });
    }

    testPassword (key, index) {
        let isPair = false;

        try {
            let privKey = KEYUTIL.getKey(key.privatekey, key.password);
            let pubKey = KEYUTIL.getKey(key.publickey);

            // Do a simple sign/verify cycle to check if private/public key matches.
            let message = 'passwordtest';
            let signature = privKey.sign(message, 'sha256');
            isPair = pubKey.verify(message, signature);
        } catch (e) {
            console.log(e);
        }

        if (isPair) {
            // If the signature successfully verifies, private/public key matches.
            this.setState({ success: { message: 'Password for ' + key.name + ' matches', keyId: key.id }});
            this.resetKeyPassword(index);
            setTimeout(() => this.setState({ success: null }), 4000);
        } else {
            let error = new Error('Wrong password for ' + key.name);
            error.keyId = key.id;
            this.setState({ testError: error });
            setTimeout(() => this.setState({ testError: null }), 4000);
        }

        return isPair;
    }

    setActiveKey (key) {
        let saving = new Cancelable(this.props.backend.setActiveKey(key.id));

        saving
            .then(() => {
                this.setState({
                    activeKey: key.id,
                    saving: { op: null, error: null },
                });
                setTimeout(() => this.setState({ success: null }), 4000);

                this.props.onSuccess();
            })
            .catch(e => {
                console.log(e);
                e.keyId = key.id;
                this.setState({ saving: { op: null, error: e }});
            })

        this.setState({ saving: { op: saving, error: null }});
    }

    keyTitle(key) {
        let title = <span>{key.name || '<unnamed key'}</span>;
        let badge = this.isActiveKey(key) && <span className="ml-2 badge badge-primary">Active</span>

        return <Fragment>{title} {badge}</Fragment>;
    }

    renderKey(key, index) {
        return (
            <div key={index} onClick={() => this.setState({ keyToDisplay: index })} data-toggle="modal" data-target="#key-modal" >
                <span className="align-middle pl-3" style={{ cursor: 'pointer' }}>
                    {this.keyTitle(key)}
                </span>

                <span style={{ textAlign: 'right' }}>
                     <button className="btn btn-outline-dark btn-sm mt-1" disabled={this.isActiveKey(key)}
                             onClick={this.setActiveKey.bind(this, key)}>Make active</button>
                </span>
            </div>
        );
    }

    renderKeyEditModal (e) {
        const index = this.state.keyToDisplay;
        let key = this.state.keys[index] || {};

        return (
            <div ref={this.modalRef} className="modal fade" id="key-modal" tabIndex="-1" role="dialog" aria-labelledby="Key Modal" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <PageMenu
                                left={() => <span className="ml-3">{this.keyTitle(key)}</span>}
                                middle={() => {
                                    return <AlertMessageDialog
                                        success={[
                                            () => (this.state.success && this.state.success.keyId === key.id) && this.state.success.message,
                                        ]}
                                        errors={[
                                            () => (this.state.testError && this.state.testError.keyId === key.id) && this.state.testError,
                                            () => (this.state.saving.error && this.state.saving.error.keyId === key.id) && this.state.saving.error,
                                        ]}
                                    />;
                                }}
                                right={() => {
                                    return (
                                        <button type="button" className="mr-3 btn btn-outline-secondary btn-sm" disabled={!key.password || key.password.length < 8}
                                            onClick={this.testPassword.bind(this, key, index)}>Test password</button>
                                    );
                                }}
                            />

                            <form className="col-sm-12 mt-1 mt-4">
                                <div key="password" className="form-group row">
                                    <label className="col-sm-3 col-form-label">Current password</label>
                                    <div className="col-sm-9 input-group">
                                        <input type="password" placeholder="Confirm key password" className="form-control"
                                            onChange={this.onChange.bind(this, 'password', index)} value={key.password || ''}/>
                                        <div className="input-group-append">
                                            <div className="input-group-text">Min. 8 characters</div>
                                        </div>
                                    </div>
                                </div>

                                <div key="name" className="form-group row">
                                    <label className="col-sm-3 col-form-label">Name</label>
                                    <div className="col-sm-9">
                                        <input type="text" placeholder="Key name" className="form-control"
                                            onChange={this.onChange.bind(this, 'name', index)} value={key.name || ''} />
                                    </div>
                                </div>

                                <div key="public_key" className="form-group row">
                                    <label className="col-sm-3 col-form-label">Public Key</label>
                                    <div className="col-sm-9">
                                        <textarea rows="10" style={{fontSize: '12px', fontFamily: 'monospace'}} className="form-control"
                                                  readOnly onChange={this.onChange.bind(this, 'publickey', index)} value={key.publickey || ''} />
                                    </div>
                                </div>

                                <div key="private_key" className="form-group row">
                                    <label className="col-sm-3 col-form-label">Private Key</label>
                                    <div className="col-sm-9">
                                        <textarea rows="10" style={{fontSize: '12px', fontFamily: 'monospace'}} className="form-control"
                                                  readOnly onChange={this.onChange.bind(this, 'privatekey', index)} value={key.privatekey || ''} />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-success" disabled={!this.canSave(key)}
                                    onClick={this.save.bind(this, key, index)}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render () {
        let passwordConfirmClasses = ['form-control'];
        if (this.state.password && this.state.password !== this.state.password_confirm) {
            passwordConfirmClasses.push('has-warning');
        }

        let generateForm = (
            <Fragment>
                <form className="offset-md-3 col-sm-6 mt-3 mb-5">
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <input type="text" placeholder="Key name" className="form-control" value={this.state.keyname || ''}
                                   name="keyname" onChange={this.handleGenerateFormChange.bind(this)}></input>
                        </div>
                        <div className="col-sm-12 mt-2">
                            <UserPasswordField onChange={this.onGenerateFormPasswordChange.bind(this)} />
                        </div>
                        <div className="col-sm-12 mt-2">
                            <input type="password" placeholder="Confirm private key password" className={passwordConfirmClasses.join(' ')}
                                   value={this.state.password_confirm || ''} name="password_confirm" onChange={this.handleGenerateFormChange.bind(this)}></input>
                        </div>

                        <div className="col-sm-12 mt-3">
                            <button className="btn btn-outline-secondary float-right" disabled={!this.isPasswordValid()} onClick={this.onGenerateKey.bind(this)} style={{float: 'left'}}>
                                {this.state.generatingKey ? <div className="lds-dual-ring"></div> : 'Generate key'}
                            </button>
                        </div>
                    </div>
                </form>
            </Fragment>
        );

        let keys = this.state.keys.map((key, index) => this.renderKey(key, index));

        return (
            <Fragment>
                <div className="container">
                    <PageMenu
                        onReload={this.loadData.bind(this)}
                        loaders={[ this.state.loading_keys ]}

                        middle={() => {
                            return <AlertMessageDialog
                                success={[
                                    () => (this.state.success && !this.state.success.keyId) ? this.state.success.message : null,
                                ]}
                                errors={[
                                    this.state.loading_keys.error,
                                    this.state.saving.error,
                                    this.state.testError,
                                ]}
                            />;
                        }}

                        left={() => {
                            return <Link to={'/passwords'} className="btn btn-outline-secondary btn-sm">Back</Link>
                        }}
                    />

                    {generateForm}

                    <div style={{width: '600px', margin: 'auto auto'}} className="table">
                        <div className="table-head">
                            <div className="table-header">
                                <span>Name</span>
                                <span></span>
                            </div>
                        </div>
                        <div className="table-body">
                            {keys}
                        </div>
                    </div>
                </div>

                {this.renderKeyEditModal()}
            </Fragment>
        );
    }
}

export default withRouter(KeyManager);
