import React, { Fragment } from 'react';

class PageMenu extends React.Component {

	render () {
		let left = this.props.left;
		let middle = this.props.middle;
		let right = this.props.right;
		let hasMessage = false;

        if (this.props.loaders) {
    		for (let i = 0; i < this.props.loaders.length; i++) {
    			let loader = this.props.loaders[i];

    			if (loader.op && loader.op !== null) {
    				middle = () => (
    					<div className="text-center">
    						<span>Loading ...</span>
    					</div>
    				);
    			}

    			if (loader.error && loader.error !== null) {
    				hasMessage = true;

    				middle = () => (
                        <Fragment>
                        	<div className="alert alert-danger" role="alert">
                                {loader.error.toString()}
                            </div>
                            <div className="text-center">
                                <button className="btn btn-sm btn-primary" onClick={this.props.onReload}>Reload</button>
                            </div>
                        </Fragment>
                    );
    			}
    		}
        }

		return (
            <div className="row" style={{minHeight: '60px', ...this.props.style}}>
                <div className="col-sm mt-3 text-left">
                    {left ? left() : null}
                </div>

				<div className={'col-sm' + (hasMessage ? '-8' : '') + ' mt-3'}>
					{middle ? middle() : null}
				</div>

                <div className="col-sm mt-3 text-right">
                    {right ? right() : null}
                </div>
            </div>

       	);

/*
		    let inner = (
                <div className="row text-center">
                    <div className="col-sm mt-3">
                        Loading ...
                    </div>
                </div>
            );

            if (this.state.loading.error) {
                inner = (
                    <Fragment>
                        <div className="row">
                            <div className="alert alert-danger offset-md-2 col-sm-8 mt-3" role="alert">
                                {this.state.loading.error.toString()}
                            </div>
                        </div>

                        <div className="row">
                            <div className="offset-md-2 col-sm-8 text-center">
                                <button className="btn btn-sm btn-primary">Reload</button>
                            </div>
                        </div>
                    </Fragment>
                );
            }

            return (
                <div className="container">
                    {inner}
                </div>
            );

        }*/
	}
}

export default PageMenu;
