import React, { Fragment } from 'react';
import QrCode from 'qrcode.react';
import jQuery from 'jquery';
import UAParser from 'ua-parser-js';
import CountryFlag from 'react-country-flag';

import ChromeLogo from '@browser-logos/chrome/chrome_24x24.png';
import ChromiumLogo from '@browser-logos/chromium/chromium_24x24.png';
import EdgeLogo from '@browser-logos/edge/edge_24x24.png';
import FirefoxLogo from '@browser-logos/firefox/firefox_24x24.png';
import SafariLogo from '@browser-logos/safari/safari_24x24.png';

import PageMenu from './PageMenu.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import Cancelable from './cancelable.js';

class SecuritySetup extends React.Component {
    state = {
        loading_logins: { op: null, error: null },
        loadingKnownDevices: { op: null },
        success: { message: null, handle: null },
        error: { message: null, handle: null },

        tfaToken: null,
        tfaSecret: null,
        tfaOtpAuthUrl: null,

        loginData: [],
        knownDevices: [],

        disableSecurityMethodBaseUrl: null,
    }

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        if (this.props.backend.loggedInUser) {
            this.loadLoginHistory(this.props.backend.loggedInUser.guid);
            this.loadKnownDevices(this.props.backend.loggedInUser.guid);
        } else {
            this.props.backend.on('loggedin', this.loadData.bind(this));
        }

        if (this.props.backend.sso) {
            this.setState({
                disableSecurityMethodBaseUrl: this.props.backend.sso.disable_security_method_url,
                enableSecurityMethodBaseUrl: this.props.backend.sso.u2f_setup_url,
            });
        } else {
            this.props.backend.on('sso-config', sso => {
                this.setState({
                    disableSecurityMethodBaseUrl: sso.disable_security_method_url,
                    enableSecurityMethodBaseUrl: this.props.backend.sso.u2f_setup_url,
                });
            });
        }
    }

    loadLoginHistory (guid) {
        const loading_logins = new Cancelable(this.props.backend.getUserLogins(guid, 10));

        loading_logins
            .then(response => {
                this.setState({
                    loading_logins: { op: null },
                    loginData: response.data.logins,
                });
            })
            .catch(e => {
                console.log(e);
                this.setState({ loading_logins: {
                    op: null,
                    error: new Error('Failed to retrieve login history'),
                }});
                setTimeout(() => this.setState({ loading_logins: { op: null, error: null }}), 4000);
            });
    }

    loadKnownDevices (guid) {
        const loadingKnownDevices = new Cancelable(this.props.backend.getUserKnownDevices(guid));

        loadingKnownDevices
            .then(response => {
                this.setState({
                    loadingKnownDevices: { op: null },
                    knownDevices: response.data.devices,
                });
            })
            .catch(e => {
                console.log(e);
                this.setState({ loadingKnownDevices: {
                    op: null,
                    error: new Error('Failed to retrieve known devices'),
                }});
                setTimeout(() => this.setState({ loadingKnownDevices: { op: null }}), 4000);
            });
    }

    componentWillUnmount () {
        clearTimeout(this.state.success.handle);
        clearTimeout(this.state.error.handle);

        if (this.state.loading_logins.op) {
            this.state.loading_logins.op.cancel();
        }

        if (this.state.loadingKnownDevices.op) {
            this.state.loadingKnownDevices.op.cancel();
        }
    }

    showMessagePopup (type, message) {
        const handle = setTimeout(
            () => this.setState({ [type]: { message: null, handle: null } }),
            4000
        );

        this.setState({ [type]: { message, handle } });
    }

    constructDisableSecurityMethodUrl (type) {
        if (this.state.disableSecurityMethodBaseUrl) {
            return new URL(
                this.state.disableSecurityMethodBaseUrl +
                '/' + type +
                '?callback=' + encodeURIComponent(window.location.href)
            );
        }
    }

    constructEnableSecurityMethodUrl (type) {
        if (this.state.enableSecurityMethodBaseUrl) {
            return new URL(
                this.state.enableSecurityMethodBaseUrl +
                '/' + type +
                '?callback=' + encodeURIComponent(window.location.href)
            );
        }
    }

    onEnableTfa () {
        this.props.backend.setupMyTwoFactorAuth()
            .then(response => {
                const tfaSecret = response.data.secret
                    .match(/.{1,4}/g)
                    .join(' ');

                this.setState({
                    tfaOtpAuthUrl: response.data.otpauth_url,
                    tfaSecret,
                });

                jQuery(this.modalRef.current)
                    .on('hidden.bs.modal', () => this.onQrcodeModalHide())
                    .modal('show');
            })
            .catch (e => {
                console.log(e);
                this.showMessagePopup('error', e);
            });
    }

    onVerifyTfaToken (event) {
        event.preventDefault();

        this.props.backend.verifyMyTwoFactorAuth(this.state.tfaToken)
            .then(async () => {
                /* Reload user */
                await this.props.backend.me();

                this.showMessagePopup('success', 'Enabled two-factor authentication');
            })
            .catch(e => {
                console.log(e);
                this.showMessagePopup('error', new Error('2FA token verification failed'));
            })
            .finally(() => {
                jQuery(this.modalRef.current).modal('hide');
            });
    }

    onChange (field, e) {
        this.setState({
            [field]: e.target.value,
        });
    }

    isTokenValid () {
        return this.state.tfaToken && /^\d{3}\s?\d{3}$/g.test(this.state.tfaToken);
    }

    isTfaEnabled () {
        return this.props.backend.loggedInUser && this.props.backend.loggedInUser.has_tfa_enabled;
    }

    isU2fSupported () {
        return !!(navigator.credentials && navigator.credentials.create);
    }

    isU2fEnabled () {
        return this.props.backend.loggedInUser && this.props.backend.loggedInUser.has_u2f_enabled;
    }

    renderAuthMethodStatusRow (enabled, enableCb, disableCb) {
        let status, toggleButton;

        if (enabled) {
            status = (
                <span className="text-success">
                    <i className="fas fa-check"></i>&nbsp;Enabled
                </span>
            );

            toggleButton = disableCb && (
                <a href={disableCb.toString()} rel="noopener nofollower" className="btn btn-sm btn-danger">
                    Disable
                </a>
            );
        } else if (!enableCb) {
            status = (
                <span className="text-danger">
                    <i className="fas fas-times"></i>&nbsp;Currently not available
                </span>
            );
        } else {
            status = (
                <span className="text-danger">
                    <i className="fas fa-times"></i>&nbsp;Disabled
                </span>
            );

            if (typeof enableCb === 'function') {
                toggleButton = (
                    <button type="button" className="btn btn-sm btn-success" onClick={enableCb}>
                        Enable
                    </button>
                );
            } else {
                toggleButton = (
                    <a href={enableCb.toString()} rel="noopener nofollower" className="btn btn-sm btn-success">
                        Enable
                    </a>
                );
            }
        }

        return (
            <div className="row">
                <div className="col-sm-11">
                    {status}
                </div>

                <div className="col-sm-1">
                    {toggleButton}
                </div>
            </div>
        );
    }

    renderQrcodeModal () {
        return (
            <div ref={this.modalRef} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="Qrcode Modal">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Two-Factor Token Verification</h5>
                        </div>
                        <div className="modal-body">
                            <p className="container">
                                To setup two-factor authentication, you need a 2FA app on your smartphone and
                                either scan this QR code or manually enter the two-factor key displayed below ("Time-based" must be checked).
                                <br/><br/>
                                If you do not have a 2FA app installed yet, you can use e.g. the 'Google Authenticator' app,
                                which is available for&nbsp;
                                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                   target="_blank" rel="noopener noreferrer">
                                    Android
                                </a>
                                &nbsp;and&nbsp;
                                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                   target="_blank" rel="noopener noreferrer">
                                    iOS
                                </a>
                                .
                            </p>
                            <hr className="mb-4"/>

                            <form onSubmit={this.onVerifyTfaToken.bind(this)}>
                                <div className="form-group">
                                    {this.state.tfaOtpAuthUrl &&
                                        <QrCode className="centered" size={192} value={this.state.tfaOtpAuthUrl} />
                                    }

                                    <textarea readOnly className="form-control-plaintext centered mt-2" rows="2" cols="33"
                                              id="tfa-key-display" value={this.state.tfaSecret || ''}
                                    />
                                </div>
                                <hr />

                                <div className="form-group mt-2">
                                    <p className="container">
                                        After scanning (or entering the key manually), please enter the token
                                        (6 digit number) below for verification, which will be displayed in your 2FA app:
                                    </p>

                                    <input className="centered form-control" type="text" style={{maxWidth: '40%'}}
                                           onChange={this.onChange.bind(this, 'tfaToken')}
                                           value={this.state.tfaToken || ''} autoFocus placeholder="2FA token"
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>

                            <button type="button" className="btn btn-success" onClick={this.onVerifyTfaToken.bind(this)}
                                    disabled={!this.isTokenValid()}>
                                Verify and enable 2FA
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onQrcodeModalHide () {
        this.setState({
            tfaToken: null,
            tfaOtpAuthUrl: null,
            tfaSecret: null,
        });
    }

    getBrowserLogo (name) {
        switch (name) {
            case 'Chrome': return ChromeLogo;
            case 'Chromium': return ChromiumLogo;
            case 'Edge': return EdgeLogo;
            case 'Firefox': return FirefoxLogo;
            case 'Safari': return SafariLogo;
            default: return null;
        }
    }

    getUserAgentIdentifier (userAgent) {
        if (!userAgent) {
            return 'Unknown';
        }

        const ua = UAParser(userAgent);
        const platform = ua.os.name + '/' + ua.cpu.architecture;

        return (
            <span>
                <img src={this.getBrowserLogo(ua.browser.name)} alt="" />
                &nbsp;
                {ua.browser.name} on {platform}
            </span>
        );
    }

    getHumanizedTimeDiff (date) {
        const msPerDay = 1000 * 60 * 60 * 24;

        // const diff = Math.floor((Date.now() - date) / msPerDay);
        let today = new Date();
        today = today.getTime() - (today.getTime() % msPerDay);

        date = new Date(date);
        const normalizedDate = date.getTime() - (date.getTime() % msPerDay);

        const diff = (today - normalizedDate) / msPerDay;
        let readable = diff + ' days ago';

        if (diff === 0) {
            readable = 'Today';
        } else if (diff === 1) {
            readable = 'Yesterday';
        }

        return (
            <span title={date}>
                {readable}
            </span>
        );
    }

    getUserSecurityMethod (login) {
        if (!login.security) {
            return 'None';
        } else if (login.security === 'reauth') {
            return 'Reauth';
        } else {
            return login.security.toUpperCase();
        }
    }

    getUserLoginsTable () {
        return (
            <div className="container">
                <table className="table table-striped table-hover text-center" style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '140px' }}>Date</th>
                            <th scope="col" style={{ width: '160px' }}>Origin</th>
                            <th scope="col" style={{ width: '100px' }}>Security</th>
                            <th scope="col" style={{ width: '275px' }}>Application</th>
                            <th scope="col" style={{ width: 'auto' }}>User Agent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.loginData.map(l => (
                            <tr key={l.id}>
                                <td>
                                    {this.getHumanizedTimeDiff(l.created_at)}
                                </td>
                                <td>
                                    {l.ip_cc && <CountryFlag code={l.ip_cc} />}
                                    &nbsp;
                                    {l.ip}
                                </td>
                                <td>
                                    {this.getUserSecurityMethod(l)}
                                </td>
                                <td className="ellipsis-overflowing-text" title={l.application_name}>
                                    {l.application_name}
                                </td>
                                <td className="ellipsis-overflowing-text" title={l.user_agent}>
                                    {this.getUserAgentIdentifier(l.user_agent)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    removeKnownDevice (device) {
        this.props.backend.deleteUserKnownDevice(this.props.backend.loggedInUser.guid, device.id)
            .then(() => {
                let devices = this.state.knownDevices.slice();
                const idx = devices.findIndex(d => d.id === device.id);

                if (idx !== -1) {
                    devices.splice(idx, 1);
                    this.setState({ knownDevices: devices });
                }
            })
            .catch(e => {
                console.log(e);
                this.setState({ loadingKnownDevices: { error: e }});
            });
    }

    getKnownDevicesTable () {
        return (
            <div className="container">
                <table className="table table-striped table-hover text-center" style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '140px' }}>Last Login</th>
                            <th scope="col" style={{ width: '180px' }}>Origin</th>
                            <th scope="col" style={{ width: '300px' }}>Device</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.knownDevices.map(d => (
                            <tr key={d.id}>
                                <td>
                                    {this.getHumanizedTimeDiff(d.updated_at)}
                                </td>
                                <td>
                                    {d.origin_ip_cc && <CountryFlag code={d.origin_ip_cc} />}
                                    &nbsp;
                                    {d.origin_ip}
                                </td>
                                <td className="ellipsis-overflowing-text text-left" title={d.user_agent}>
                                    {this.getUserAgentIdentifier(d.user_agent)}
                                </td>
                                <td style={{ textAlign: 'right' }}>
                                    <button type="button" className="btn btn-sm btn-outline-danger" onClick={this.removeKnownDevice.bind(this, d)}>
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    render () {
        return (
            <Fragment>
                <div className="container">
                    <PageMenu
                        loaders={[this.state.loading_logins, this.state.loadingKnownDevices]}

                        middle={() => {
                            return <AlertMessageDialog
                                success={[this.state.success.message]}
                                errors={[this.state.error.message, this.state.loading_logins.error, this.state.loadingKnownDevices.error]}
                            />;
                        }}
                    />

                    <div className="row mt-3">
                        <h5 className="col-sm">Two-Factor Authentication</h5>
                    </div>
                    <hr className="mt-0"/>

                    <p style={{ maxWidth: '70%' }}>
                        Two-factor authentication adds an extra layer of security to your SSO login.
                        After successfully activating it, you must provide the time-based code displayed
                        on your smartphone every time you try to log in.
                        <br/>
                        This will ensure that only you
                        can access your account, even if your password was compromised.
                    </p>

                    {this.renderAuthMethodStatusRow(
                        this.isTfaEnabled(),
                        this.onEnableTfa.bind(this), this.constructDisableSecurityMethodUrl('2fa')
                    )}


                    <div className="row mt-5">
                        <h5 className="col-sm">U2F Authentication</h5>
                    </div>
                    <hr className="mt-0"/>

                    <p style={{ maxWidth: '70%' }}>
                        U2F (Universal 2nd Factor) authentication adds an extra layer of security to your SSO login.
                        <br/>
                        In constract to 2FA, it requires a hardware-based dongle connected (normally via USB) to the computer.
                        While authenticating, a physical button must be pressed on the device to activate it and authenticate
                        with the server.
                        <br/>
                        This will ensure that only you
                        can access your account, even if your password was compromised.
                    </p>

                    {this.isU2fSupported() &&
                        this.renderAuthMethodStatusRow(
                            this.isU2fEnabled(),
                            this.constructEnableSecurityMethodUrl('u2f'),
                            this.constructDisableSecurityMethodUrl('u2f'),
                        )
                    }

                    {!this.isU2fSupported() &&
                        <div className="row">
                            <span className="text-danger">
                                <i className="fas fa-times"></i>
                                &nbsp;
                                U2F authentication API is not supported by the current browser.
                            </span>
                        </div>
                    }


                    <div className="row mt-5">
                        <h5 className="col-sm">Login History</h5>
                    </div>
                    <hr className="mt-0"/>

                    <p style={{ maxWidth: '70%' }}>
                        This is a security log of your last 10 logins through SSO.
                    </p>

                    {this.getUserLoginsTable()}


                    <div className="row mt-5">
                        <h5 className="col-sm">Known devices</h5>
                    </div>
                    <hr className="mt-0"/>

                    <p style={{ maxWidth: '70%' }}>
                        This shows all your devices/browsers that are authorized to login.
                    </p>

                    {this.getKnownDevicesTable()}
                </div>

                {this.renderQrcodeModal()}
            </Fragment>
        );
    }
}

export default SecuritySetup;
