import React, { Fragment } from 'react';
import { Link, Route, Switch } from "react-router-dom";
import Select from 'react-select';
import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import NewPassword from './NewPassword.js';
import KeyManager from './KeyManager.js';
import ShowPassword from './ShowPassword.js';
import NoUserKeyDialog from './NoUserKeyDialog.js';
import AlertMessageDialog from '../AlertMessageDialog.js';
import Unaccent from '../unaccent.js';

class PasswordList extends React.Component {
    state = {
        passwords: [],
        labels: [],

        loading_passwords: { op: null, error: null },
        loading_labels: { op: null, error: null },
        filter: {
            name: '',
            description: '',
            labels: [],
        },
        success: null,
        error: null,
    }

    all_labels = []
    all_passwords = []

	componentDidMount () {
		this.loadData();
	}

    loadData () {
        let loading_passwords = new Cancelable(this.props.backend.getPasswords());

        loading_passwords
            .then(response => {
                this.all_passwords = response.data.passwords;
                this.doFilter();

                const labels = this.all_passwords
                    .map(p => p.labels)
                    .reduce((arr, label) => arr.concat(label), []);

                this.doFilterLabels(labels);

                this.setState({
                    loading_passwords: { op: null, error: null },
                });
            })
            .catch(e => {
                this.setState({ loading_passwords: { op: null, error: e }});
            });

        let loading_labels = new Cancelable(this.props.backend.getPasswordLabels());

        loading_labels
            .then(response => {
                this.all_labels = Object.values(response.data);
                this.doFilterLabels();
            })
            .catch(e => {
                this.setState({ loading_labels: { op: null, error: e }});
            });

        this.setState({
            loading_passwords: { op: loading_passwords, error: null }
        });
    }

    componentWillUnmount () {
        if (this.state.loading_passwords.op) {
            this.state.loading_passwords.op.cancel();
        }

        if (this.state.loading_labels.op) {
            this.state.loading_labels.op.cancel();
        }
    }

    showSuccessMessage(msg) {
        this.setState({success: msg});
        this.loadData(); // Reload data
        this.props.backend.me();

        setTimeout(() => this.setState({success: null}), 4000);
    }

    showNewPassword () {
        let onNewPassword = (message, newPassword) => {
            this.showSuccessMessage(message);

            this.all_passwords.push(newPassword);
            this.doFilter();
        };

        return <NewPassword backend={this.props.backend} labels={this.all_labels} onSuccess={onNewPassword}/>
    }

    showKeyManager () {
        return <KeyManager backend={this.props.backend} onSuccess={this.showSuccessMessage.bind(this)} />
    }

    showPassword ({ match }) {
        return (
            <ShowPassword backend={this.props.backend} id={match.params.id} key={match.params.id}
                          labels={this.all_labels} onSuccess={this.showSuccessMessage.bind(this)}
                          successMessage={this.state.success} />
        );
    }

    onNew (e) {
        e.preventDefault();
        this.props.history.push('/passwords/new');
    }

    onKeys (e) {
        e.preventDefault();
        this.props.history.push('/passwords/keys');
    }

    onDelete (item, e) {
        e.preventDefault();

        let response = this.props.backend.deletePassword(item.id);

        response.then(() => {
            for (let i = 0; i < this.all_passwords.length; i++) {
                if (this.all_passwords[i].identifier === item.identifier) {
                    // If found, remove element from 'all_passwords' and re-apply filter.
                    this.all_passwords.splice(i, 1);
                    this.showSuccessMessage('Deleted password ' + item.name);
                    this.doFilter();
                    break;
                }
            }
        }).catch(e => {
            console.log(e);
            this.setState({ error: e });
            setTimeout(() => this.setState({ error: null }), 4000);
        });
    }

    filter (field, event) {
        let filter = Object.assign({}, this.state.filter);
        filter[field] = event.target.value;

        this.doFilter(filter);
    }

    filterByLabels (labels) {
        let filter = Object.assign({}, this.state.filter);
        filter.labels = labels;

        this.doFilter(filter);
    }

    doFilterLabels (labels) {
        labels = labels || this.state.labels;

        if (this.all_labels.length && labels.length) {
            labels = labels.reduce((arr, label) => {
                if (!arr.some(l => l.id === label.id)) {
                    return arr.concat(label);
                }

                return arr;
            }, []);
        }

        this.setState({ labels });
    }

    doFilter (filter) {
        filter = filter || this.state.filter;

        let data = this.all_passwords.filter((a) => {
            let result = true;

            for (var property in filter) {
                if (typeof filter[property] === 'string') {
                    let search = Unaccent.unaccent(filter[property].toLowerCase());

                    result &= Unaccent.unaccent((a[property] || '').toLowerCase()).indexOf(search) !== -1;
                } else if (typeof filter[property] === 'boolean') {
                    result &= a[property] === filter[property];
                } else if (Array.isArray(filter[property])) {
                    for (let value of filter[property]) {
                        result &= a[property].some(b => b.id === value.id);
                    }
                }

                if (!result) {
                    break;
                }
            }

            return result;
        });

        data.sort((a, b) => {
            if (a.name === null || !b.name === null) {
                return 1;
            }

            if (b.name === null || !a.name === null) {
                return -1;
            }

            return (a.name || '').localeCompare(b.name || '');
        });

        this.setState({ passwords: data, filter: filter });
    }

    render () {
        let new_password = <button onClick={this.onNew.bind(this)} className="btn btn-outline-success btn-sm">New</button>
        let keys = <button onClick={this.onKeys.bind(this)} className="btn btn-outline-secondary btn-sm">Keys</button>

        return (
            <div className="container">
                <Switch>
                    <Route exact path="/passwords/keys" render={this.showKeyManager.bind(this)} />
                    <Route exact path="/passwords/new" render={this.showNewPassword.bind(this)} />
                    <Route exact path="/passwords/:id" render={this.showPassword.bind(this)} />
                    <Route exact path="/passwords">
                        <Fragment>
                            <PageMenu
                                onReload={this.loadData.bind(this)}
                                loaders={[ this.state.loading_passwords, this.state.loading_labels ]}

                                middle={() => {
                                    return <AlertMessageDialog
                                        success={[this.state.success]}
                                        errors={[this.state.loading_passwords.error, this.state.loading_labels.error]}
                                    />;
                                }}

                                right={() => {
                                    return (
                                        <span style={{padding: '1rem'}}>{this.state.passwords.length} of {this.all_passwords.length} records</span>
                                    );
                                }}
                            />

                            <div style={{tableLayout: 'fixed', display: 'table'}} className="table table-striped">
                                <div className="table-head">
                                    <Link to="#">
                                        <span
                                            style={{width: '200px'}}><input className="form-control" placeholder="Search Name" type="text"
                                            value={this.state.filter.name} onChange={this.filter.bind(this, 'name')} />
                                        </span>

                                        <div className="container" style={{width: '750px'}}>
                                            <div className="row">
                                                <div className="col-sm-7">
                                                    <input
                                                        className="form-control" placeholder="Search Description" type="text" value={this.state.filter.description}
                                                        onChange={this.filter.bind(this, 'description')} />
                                                </div>
                                                <div className="col-sm-5">
                                                    <Select
                                                        options={this.all_labels} getOptionLabel={o => o.title} getOptionValue={o => o.id} isClearable isMulti
                                                        placeholder="Search labels" menuPortalTarget={document.body} onChange={this.filterByLabels.bind(this)}
                                                        styles={{
                                                            menuPortal: base => ({ ...base, zIndex: 999 }),
                                                            placeholder: base => ({ ...base, fontWeight: 'normal' }),
                                                            multiValueLabel: base => ({ ...base, fontWeight: 'normal' }),
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <span className="text-right">{keys} {new_password}</span>
                                    </Link>

                                    <Link to="#">
                                        <span style={{width: '200px'}}>Name</span>
                                        <span style={{width: '750px'}}>Description</span>
                                        <span style={{textAlign: 'right'}}>Actions</span>
                                    </Link>
                                </div>
                                <div>
                                    {this.state.passwords.map((item, index) => {
                                        let deleteBtn;

                                        if (item.permission !== "0") {
                                            // Only owner can delete a password
                                            deleteBtn = <button onClick={this.onDelete.bind(this, item)} className="btn btn-outline-danger btn-sm">Delete</button>
                                        }

                                        const labels = (
                                            <Fragment>
                                                {item.labels && item.labels.map(l => {
                                                    return <span key={l.id} className="badge badge-secondary mr-1">{l.title}</span>;
                                                })}
                                            </Fragment>
                                        );

                                        return (
                                            <Link to={'/passwords/' + item.id} key={item.id}>
                                                <span>{item.name}</span>
                                                <span>{item.description}<br/>{labels}</span>
                                                <span style={{textAlign: 'right'}}>{deleteBtn}</span>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>

                            <NoUserKeyDialog backend={this.props.backend} />
                        </Fragment>
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default PasswordList;
