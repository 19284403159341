
class Cancelable {

	constructor (promise) {
		this.hasCanceled = false;
		this.promise = promise;

		const wrappedPromise = new Promise((resolve, reject) => {
    		promise.then(
      			val => {
      				if (!this.hasCanceled) {
      					resolve(val);
      				}
      			},
      			error => {
      				if (!this.hasCanceled) {
      					reject(error)
      				}
      			}
    		);
  		});

		this.then = wrappedPromise.then.bind(wrappedPromise);
		this.catch = wrappedPromise.catch.bind(wrappedPromise);
	}

	cancel () {
		this.hasCanceled = true;
	}
}

export default Cancelable;