import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select'

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import ValidationRules from './validation.js';
import AlertMessageDialog from './AlertMessageDialog.js';


export default class NewComputer extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            computer: this.newComputer(),

            success: null,
            saving: { op: null },
        };

        this.formRef = React.createRef();
    }

    newComputer () {
        return {
            computername: null,
            organization: null,
        };
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        this.setState({
            organizations: this.props.permissionManager.getAllowedOrganizations('computers_create'),
        });
    }

    componentWillUnmount() {
        if (this.state.saving.op) {
            this.state.saving.op.cancel();
        }
    }

    onChange (field, event) {
        let computer = Object.assign({}, this.state.computer);

        if (ValidationRules[field] && ValidationRules[field].validate) {
            ValidationRules[field].validate(event.target);
        }

        if (!event) {
            computer[field] = null;
        } else if (event.target) {
            computer[field] = event.target.value;
        } else {
            computer[field] = event;
        }

        this.setState({ computer });
    }

    box (prop, value) {
        return (
            <div key={prop.field} className="form-group row">
                <label className="col-sm-3 col-form-label">{prop.name}</label>
                <div className="col-sm-9">
                    <Fragment>
                        {prop.control && prop.control(value)}

                        {!prop.control &&
                            <input type="text" className="form-control"
                                value={value || ''} onChange={this.onChange.bind(this, prop.field)}
                            />
                        }

                        <small className="form-text text-muted">
                            {ValidationRules[prop.field] && ValidationRules[prop.field].hint}
                        </small>
                    </Fragment>
                </div>
            </div>
        );
    }

    canCreate () {
        if (!this.formRef.current) {
            return true;
        }

        const { computername, organization } = this.state.computer;

        return computername && computername.length > 0 && organization &&
            this.formRef.current.getElementsByClassName('validation-has-error').length === 0;
    }

    onCreate () {
        const computer = {
            ...this.state.computer,
            organization: this.state.computer.organization.dn,
        }

        let saving = new Cancelable(this.props.backend.createComputer(computer));

        saving
            .then(() => {
                this.setState({
                    saving: { op: null },
                    computer: this.newComputer(),
                    success: 'Computer created successfully',
                });
            })
            .catch(err => {
                console.log(err);
                if (err.response && err.response.data) {
                    err += ' ' + JSON.stringify(err.response.data);
                }

                this.setState({ saving: { op: null, error: err }});
            });

        this.setState({ saving: { op: saving }});
    }

    render () {
        let saveBtn = null;

        if (this.state.saving.op) {
            saveBtn = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
        } else {
            saveBtn = (
                <button className="btn btn-outline-success btn-sm" onClick={this.onCreate.bind(this)}
                        disabled={!this.canCreate()}>
                    Create
                </button>
            );
        }

        const createFields = this.props.permissionManager.getAllowedFields('computers_create');

        const organizationControl = value => (
            <Select
                options={this.state.organizations} getOptionLabel={o => o.name} getOptionValue={o => o.dn}
                isClearable value={value} onChange={this.onChange.bind(this, 'organization')}
                placeholder="Select organization..."
            />
        );

        let fields = [];
        if (this.props.fields) {
            for (const field of this.props.fields) {
                switch (field.field) {
                   case 'organization':
                        field.control = organizationControl;
                        break;

                    default: break;
                }

                if (createFields.includes(field.field)) {
                    fields.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.saving ]}

                    left={() => {
                        return <Link to={'/computers'} className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    middle={() => (
                        <AlertMessageDialog
                            success={[ this.state.success ]}
                        />
                    )}

                    right={() => saveBtn}
                />

                <form ref={this.formRef} className="offset-md-2 col-sm-8 mt-3 needs-validation">
                    {fields.map(prop => {
                        const value = this.state.computer[prop.field];
                        return this.box(prop, value);
                    })}
                </form>
            </div>
        );
    }
}
