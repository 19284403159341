import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import Select, { createFilter } from 'react-select';
import { getDifference, organizationDnToPath, sortByOrganizationDn } from './helper.js';
import AlertMessageDialog from './AlertMessageDialog.js';

const EMPTY_ARRAY = [];

class EditUserPermissions extends React.Component {
    field_map = {}
    state = {
        permission_map: {},
        organization_map: {},
        group_map: {},

        user_permissions: [],

        new_right: this.newRight(),
        edit_right: null,
        edit_right_original: null,

        loadingPerms: { op: null, error: null },
        loadingGroups: { op: null, error: null },
        saving: { op: null, error: null },
        saving_edit: { op: null, error: null },
        deleting: { op: null, error: null }
    }

    newRight () {
        return {
            permission: null,
            fields: EMPTY_ARRAY,
            organizations: EMPTY_ARRAY,
            groups: [],
        };
    }

    recalulate () {
        for (const key in this.props.fields) {
            this.field_map[key] = this.props.fields[key].reduce((obj, item) => { obj[item.field] = item; return obj; }, {});
        }
    }

    componentDidUpdate (prevProps) {
        if (this.props.fields !== prevProps.fields) {
            this.recalulate();
        }

        if (this.props.permissions !== prevProps.permissions) {
            this.setState({
                permissions: this.props.permissions.filter((p) => !this.state.user_permissions.find((u) => u.permission_id === p.id)),
                permission_map: this.props.permissions.reduce((obj, item) => { obj[item.id] = item; return obj; }, {}),
            });
        }

        if (this.props.organizations !== prevProps.organizations) {
            this.setState({
                organizations: this.props.organizations,
                organization_map: this.props.organizations.reduce((obj, item) => { obj[item.dn] = item; return obj; }, {}),
            });
        }
    }

    componentDidMount () {
        this.loadData();

        this.recalulate();
    }

    async loadData () {
        await this.props.backend.waitAuthorized();

        let loadingUser = new Cancelable(this.props.backend.getUser(this.props.guid));

        loadingUser
            .then(response => {
                this.setState({ loadingUser: { op: null }, user: response.data });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingUser: { op: null, error: err } });
            });

        let loadingPerms = new Cancelable(this.props.backend.getPermissions(this.props.guid));

        loadingPerms
            .then((response) => {
                response.data.user_permissions.sort((a, b) => {
                    return a.permission_id - b.permission_id;
                });

                this.setState(
                    {
                        permissions: this.props.permissions.filter((p) => !response.data.user_permissions.find((u) => u.permission_id === p.id)),
                        permission_map: this.props.permissions.reduce((obj, item) => { obj[item.id] = item; return obj; }, {}),
                        user_permissions: response.data.user_permissions,
                        loadingPerms: { op: null, error: null }
                    }
                );
            })
            .catch((e) => {
                this.setState({ loadingPerms: { op: null, error: e } });
            });

        let loadingGroups = new Cancelable(this.props.backend.getGroups());

        loadingGroups
            .then(response => {
                let groups = response.data.groups.map(g => ({ dnPath: organizationDnToPath(g.dn), ...g }));

                this.setState({
                    groups: sortByOrganizationDn(groups),
                    group_map: groups.reduce((obj, item) => { obj[item.dn] = item; return obj; }, {}),
                    loadingGroups: { op: null },
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingGroups: { op: null, error: err }});
            });

        this.setState({
            loadingUser: { op: loadingUser },
            loadingPerms: { op: loadingPerms },
            loadingGroups: { op: loadingGroups },
            organizations: this.props.organizations,
            organization_map: this.props.organizations.reduce((obj, item) => { obj[item.dn] = item; return obj; }, {}),
        });
    }

    componentWillUnmount () {
        if (this.state.loadingPerms.op) {
            this.state.loadingPerms.op.cancel();
        }
    }

    box (name, value) {
        return (
            <div className="form-group row">
                <label className="col-sm-3 col-form-label">{name}</label>
                <div className="col-sm-9">
                    <input type="text" readOnly className="form-control" value={value || ''} />
                </div>
            </div>
        );
    }

    onPermissionChanged (permission) {
        let obj = Object.assign({}, this.state.new_right);
        obj.permission = permission;
        this.setState({ new_right: obj });
    }

    onFieldsChanged (fields) {
        let obj = Object.assign({}, this.state.new_right);
        obj.fields = fields;
        this.setState({ new_right: obj });
    }

    onOrganizationsChanged (organizations) {
        let obj = Object.assign({}, this.state.new_right);
        obj.organizations = organizations;
        this.setState({ new_right: obj });
    }

    onGroupsChanged (groups) {
        let obj = { ...this.state.new_right };
        obj.groups = groups;
        this.setState({ new_right: obj });
    }

    hasUserPermission (name) {
        const listPerm = this.props.permissions.find(p => p.name === name);

        return !!this.state.user_permissions.find(p => p.permission_id === listPerm.id);
    }

    isPermissionType (perm, name) {
        const p = this.props.permissions.find(p => p.id === perm.permission_id);

        return p && p.name === name;
    }

    getPermissionType (perm) {
        if (perm.name.startsWith('users_') || perm.name.startsWith('self_')) {
            return 'user';
        } else if (perm.name.startsWith('groups_')) {
            return 'group';
        } else if (perm.name.startsWith('computers_')) {
            return 'computer';
        }
    }

    async createUserPermissionFrom (perm, name) {
        const listPerm = this.props.permissions.find(p => p.name === name);

        const user_permission = {
            permission_id: listPerm.id,
            additional_settings: perm.additional_settings,
        };

        return this.props.backend.createPermission(this.props.guid, user_permission);
    }

    save (e) {
        e.preventDefault();

        let user_permission = {
            permission_id: this.state.new_right.permission.id,
            additional_settings: {
                fields: this.state.new_right.fields.map((f) => f.field),
                organizations: this.state.new_right.organizations.map((f) => f.dn),
                groups: this.state.new_right.groups.map(f => f.dn),
            },
        };

        let saving = this.props.backend.createPermission(this.props.guid, user_permission)
            .then(async () => {
                this.setState({ saving: { op: null, error: null }, new_right: this.newRight() });

                if (this.isPermissionType(user_permission, 'users_edit') && !this.hasUserPermission('users_list')) {
                    await this.createUserPermissionFrom(user_permission, 'users_list');
                }

                if (this.props.guid === this.props.backend.loggedInUser.guid) {
                    // Update the permissions we have
                    this.props.backend.me();
                }

                this.loadData();
            })
            .catch((e) => {
                this.setState({ saving: { op: null, error: e }});
            });

        this.setState({ saving: { op: saving, error: null }});
    }

    editPrepare (item, e) {
        e.preventDefault();

        this.setState({ edit_right: item, edit_right_original: item });
    }

    editSave (item, e) {
        // todo save
        let saving = this.props.backend.updatePermission(this.props.guid, item.permission_id, item)
            .then((response) => {
                this.setState({
                    saving_edit: { op: null, error: null },
                    edit_right: null,
                    edit_right_original: null
                });

                if (this.props.guid === this.props.backend.loggedInUser.guid) {
                    // Update the permissions we have
                    this.props.backend.me();
                }

                this.loadData();
            })
            .catch((e) => {
                this.setState({ saving_edit: { op: null, error: e }});
            });

        this.setState({ saving_edit: { op: saving, error: null, id: item.id }});
    }

    editCancel (item, e) {
        // splice back into a copy
        let idx = this.state.user_permissions.indexOf(item);
        let spliced = this.state.user_permissions.slice();
        spliced.splice(idx, 1, this.state.edit_right_original);

        this.setState({ edit_right: null, edit_right_original: null, user_permissions: spliced })
    }

    editChangeFields (item, fields) {
        let copy = Object.assign({}, item);
        let additional_settings = Object.assign({}, copy.additional_settings);

        additional_settings.fields = [];

        for (let i = 0; i < fields.length; i++) {
            additional_settings.fields.push(fields[i].field);
        }

        copy.additional_settings = additional_settings;

        // splice back into a copy
        let idx = this.state.user_permissions.indexOf(item);
        let spliced = this.state.user_permissions.slice();
        spliced.splice(idx, 1, copy);

        this.setState({ edit_right: copy, user_permissions: spliced });
    }

    editChangeOrganization (item, orgs) {
        let copy = Object.assign({}, item);
        let additional_settings = Object.assign({}, copy.additional_settings);

        additional_settings.organizations = [];

        for (let i = 0; i < orgs.length; i++) {
            additional_settings.organizations.push(orgs[i].dn);
        }

        copy.additional_settings = additional_settings;

        // splice back into a copy
        let idx = this.state.user_permissions.indexOf(item);
        let spliced = this.state.user_permissions.slice();
        spliced.splice(idx, 1, copy);

        this.setState({ edit_right: copy, user_permissions: spliced });
    }

    editChangeGroup (item, groups) {
        let copy = { ...item };

        let additional_settings = { ...item.additional_settings };
        additional_settings.groups = groups.map(g => g.dn);

        copy.additional_settings = additional_settings;

        // splice back into a copy
        let idx = this.state.user_permissions.indexOf(item);
        let spliced = this.state.user_permissions.slice();
        spliced.splice(idx, 1, copy);

        this.setState({ edit_right: copy, user_permissions: spliced });
    }

    onSelectAll (perm, field) {
        let copy = { ...perm };

        let additional_settings = { ...perm.additional_settings };
        additional_settings[field] = this.state[field].map(g => g.dn);

        copy.additional_settings = additional_settings;

        // splice back into a copy
        let idx = this.state.user_permissions.indexOf(perm);
        let spliced = this.state.user_permissions.slice();
        spliced.splice(idx, 1, copy);

        this.setState({ edit_right: copy, user_permissions: spliced });
    }

    delete (item, e) {
        e.preventDefault();

        let deleting = this.props.backend.deletePermission(this.props.guid, item.permission_id)
            .then((response) => {
                this.setState({ deleting: { op: null, error: null }});

                if (this.props.guid === this.props.backend.loggedInUser.guid) {
                    // Update the permissions we have
                    this.props.backend.me();
                }

                this.loadData();
            })
            .catch((e) => {
                this.setState({ deleting: { op: null, error: e }});
            });

        this.setState({ deleting: { op: deleting, error: null, id: item.id }});
    }

    render () {
        let saveButton = null;

        let fields = null;
        let organizations = null;
        let groups = null;

        if (this.state.new_right.permission && this.state.new_right.permission.available_settings.fields) {
            const permType = this.getPermissionType(this.state.new_right.permission);
            fields = (
                <div className="form-group row">
                    <div className="col-sm">
                        <Select isMulti options={this.props.fields[permType]} getOptionLabel={(p) => p.name} getOptionValue={(p) => p.field}
                                value={this.state.new_right.fields} onChange={this.onFieldsChanged.bind(this)}
                                isLoading={this.state.loadingPerms.op}
                                placeholder="Select Fields..."
                        />
                    </div>
                </div>
            );
        }

        if (this.state.new_right.permission && this.state.new_right.permission.available_settings.organizations) {
            organizations = (
                <div className="form-group row">
                    <div className="col-sm">
                        <Select isMulti options={this.state.organizations} getOptionLabel={p => p.dnPath} getOptionValue={(p) => p.dn}
                                value={this.state.new_right.organizations} onChange={this.onOrganizationsChanged.bind(this)}
                                placeholder="Select Organizations..."
                        />
                    </div>
                </div>
            );
        }

        if (this.state.new_right.permission && this.state.new_right.permission.available_settings.groups) {
            groups = (
                <div className="form-group row">
                    <div className="col-sm">
                        <Select isMulti options={this.state.groups} getOptionLabel={g => g.dnPath} getOptionValue={g => g.dn}
                            value={this.state.new_right.groups} onChange={this.onGroupsChanged.bind(this)}
                            placeholder="Select Allowed Groups..."
                        />
                    </div>
                </div>
            );
        }

        if (!this.state.loadingPerms.op) {
            if (this.state.saving.op) {
                saveButton = <button type="button" className="btn btn-outline-success btn-sm" disabled>Saving...</button>
            } else {
                saveButton = <button type="button" className="btn btn-outline-success btn-sm" disabled={Object.keys(getDifference(this.newRight(), this.state.new_right)).length === 0} onClick={this.save.bind(this)}>Save</button>
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingPerms, this.state.loadingGroups, this.state.saving, this.state.saving_edit, this.state.deleting ]}

                    left={() => {
                        return (
                            <Fragment>
                                <Link to={'/users'} className="btn btn-outline-secondary btn-sm">Back</Link>&nbsp;
                                <Link to={'/users/' + this.props.guid} className="btn btn-outline-secondary btn-sm">View</Link>
                            </Fragment>
                        );
                    }}
                    middle={() => (
                        <Fragment>
                            <AlertMessageDialog
                                errors={[ this.state.loadingPerms.error, this.state.loadingGroups.error, this.state.saving.error, this.state.saving_edit.error, this.state.deleting.error ]}
                            />
                            <h5 style={{textAlign: 'center'}}>{this.state.user ? this.state.user.firstname + ' ' + this.state.user.lastname : null}</h5>
                        </Fragment>
                    )}
                    right={() => {
                        return null;
                    }}
                />
                <form className="offset-md-2 col-sm-8 mt-3">
                    <div className="card bg-light mt-3">
                        <div className="card-body">
                            <div className="float-right">
                                {saveButton}
                            </div>
                            <h5 className="card-title">New Permission</h5>
                            <div className="form-group row">
                                <div className="col-sm">
                                    <Select options={this.state.permissions} getOptionLabel={(p) => p.display} getOptionValue={(o) => o.id}
                                            value={this.state.new_right.permission} onChange={this.onPermissionChanged.bind(this)}
                                            isLoading={this.state.loadingPerms.op}
                                            placeholder="Select a Permission..."
                                    />
                                </div>
                            </div>
                            {fields}
                            {groups}
                            {organizations}
                        </div>
                    </div>
                </form>

                <div className="col-sm-8 offset-md-2 mt-3">
                    {this.state.user_permissions.map((perm) => {
                        let resolved_fields = [];
                        let resolved_organizations = [];
                        let resolved_groups = [];
                        let field_select = null;
                        let organization_select = null;
                        let group_select = null;
                        let buttons = null;

                        let permission = this.state.permission_map[perm.permission_id];
                        if (!permission) {
                            return null;
                        }

                        const permType = this.getPermissionType(permission);

                        if (permission.available_settings && permission.available_settings.fields) {
                            if (perm.additional_settings && perm.additional_settings.fields) {
                                for (let i = 0; i < perm.additional_settings.fields.length; i++) {
                                    let field = perm.additional_settings.fields[i];

                                    if (field in this.field_map[permType]) {
                                        resolved_fields.push(this.field_map[permType][field]);
                                    }
                                }
                            }

                            field_select = (
                                <Select isMulti options={this.props.fields[permType]} getOptionLabel={(p) => p.name} getOptionValue={(p) => p.field}
                                        value={resolved_fields} isDisabled={(this.state.edit_right !== perm)} onChange={this.editChangeFields.bind(this, perm)}
                                        placeholder="Select Fields..."
                                />
                            );
                        }

                        if (permission.available_settings && permission.available_settings.organizations) {
                            if (perm.additional_settings && perm.additional_settings.organizations) {
                                for (let i = 0; i < perm.additional_settings.organizations.length; i++) {
                                    let organization = perm.additional_settings.organizations[i];

                                    if (organization in this.state.organization_map) {
                                        resolved_organizations.push(this.state.organization_map[organization]);
                                    }
                                }
                            }

                            organization_select = (
                                <Fragment>
                                    <Select className="mt-3" isMulti options={this.state.organizations} getOptionLabel={p => p.dnPath} getOptionValue={(p) => p.dn}
                                        value={resolved_organizations} isDisabled={(this.state.edit_right !== perm)} onChange={this.editChangeOrganization.bind(this, perm)}
                                        placeholder="Select Organizations..."
                                    />
                                    <div className="mt-2 d-flex pr-0 justify-content-end">
                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.onSelectAll.bind(this, perm, 'organizations')}
                                                disabled={this.state.edit_right !== perm}>
                                            Select All Organizations
                                        </button>
                                    </div>
                                </Fragment>
                            );
                        }

                        if (permission.available_settings && permission.available_settings.groups) {
                            if (perm.additional_settings && perm.additional_settings.groups) {
                                for (const group of perm.additional_settings.groups) {
                                    if (group in this.state.group_map) {
                                        resolved_groups.push(this.state.group_map[group]);
                                    }
                                }
                            }

                            group_select = (
                                <Fragment>
                                    <Select className="mt-3" isMulti options={this.state.groups} getOptionLabel={g => g.dnPath} getOptionValue={g => g.dn}
                                        value={resolved_groups} isDisabled={this.state.edit_right !== perm} onChange={this.editChangeGroup.bind(this, perm)}
                                        placeholder="Select Allowed Groups..." filterOption={createFilter({ ignoreAccents: false })}
                                    />
                                    <div className="mt-2 d-flex pr-0 justify-content-end">
                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.onSelectAll.bind(this, perm, 'groups')}
                                                disabled={this.state.edit_right !== perm}>
                                            Select All Groups
                                        </button>
                                    </div>
                                </Fragment>
                            )
                        }

                        if (this.state.edit_right !== perm) {
                            let edit = null;
                            let del = <button type="button" className="btn btn-outline-danger btn-sm" disabled={(this.state.deleting.op === null ? false : true)} onClick={this.delete.bind(this, perm)}>{((this.state.deleting.op !== null && this.state.deleting.id === perm.id) ? 'Deleting...' : 'Delete')}</button>

                            if (Object.keys(permission.available_settings).length > 0) {
                                edit = <button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.editPrepare.bind(this, perm)}>Edit</button>;
                            }

                            buttons = (
                                <Fragment>
                                    {edit}&nbsp;{del}
                                </Fragment>
                            );
                        } else {
                            buttons = (
                                <Fragment>
                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.editCancel.bind(this, perm)}>Cancel</button>&nbsp;
                                    <button type="button" className="btn btn-outline-success btn-sm" onClick={this.editSave.bind(this, perm)} disabled={Object.keys(getDifference(this.state.edit_right_original, perm)).length === 0}>{((this.state.saving_edit.op !== null && this.state.saving_edit.id === perm.id) ? 'Saving...' : 'Save')}</button>
                                </Fragment>
                            );
                        }

                        return (
                            <div key={perm.id} className="card bg-light mt-3">
                                <div className="card-body">
                                    <div className="float-right">
                                        {buttons}
                                    </div>
                                    <h5 className="card-title">{this.state.permission_map[perm.permission_id].display}</h5>
                                    {field_select}
                                    {group_select}
                                    {organization_select}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }
}

export default EditUserPermissions;
