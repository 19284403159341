import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select, { createFilter } from 'react-select';

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import Helper from './helper.js';

export default class Groups extends React.Component {
    allGroups = []

    state = {
        groups: [],
        sorting: { column: 'groupname', direction: 'asc' },

        loadingGroups: { op: null },
    }

    static newFilter () {
        return {
            groupname: '',
            description: '',
            organization: { value: '', exactMatch: true },
        };
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingGroups.op) {
            this.state.loadingGroups.op.cancel();
        }
    }

    loadData () {
        const loadingGroups = new Cancelable(this.props.backend.getGroups());

        loadingGroups
            .then(response => {
                this.allGroups  = response.data.groups;
                this.doFiltering();

                this.setState({
                    loadingGroups: { op: null },
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingGroups: { op: null, error: err }});
            });

        this.setState({
            loadingGroups: { op: loadingGroups },
        });
    }

    setSorting (prop) {
        if (prop === this.state.sorting.column) {
            let direction = this.state.sorting.direction === 'asc' ? 'desc' : 'asc';

            this.doFiltering(this.props.filter, prop, direction);
        } else {
            this.doFiltering(this.props.filter, prop, this.state.sorting.direction);
        }
    }

    setFilter(prop, event) {
        let filter = Object.assign({}, this.props.filter);

        if (!event && prop === 'organization') {
            // react-select will pass null if the search is cleared
            filter[prop] = { value: '', exactMatch: true };
        } else if (typeof event === 'string') {
            filter[prop] = event;
        } else if (event.value) {
            filter[prop] = event.value;
        } else if (prop === 'organization') {
            filter[prop] = { value: event.dn, exactMatch: true };
        } else {
            filter[prop] = event.target.value;
        }

        this.doFiltering(filter);
    }

    doFiltering (filter, column, direction) {
        filter = filter || this.props.filter;
        column = column || this.state.sorting.column;
        direction = direction || this.state.sorting.direction;

        this.setState({
            groups: Helper.filterAndSortData(this.allGroups, filter, column, direction),
            sorting: { column, direction },
        });

        this.props.setFilter(filter);
    }

    onEditBtn (group, event) {
        event.preventDefault();

        this.props.history.push('/groups/' + group.guid + '/edit');
    }

    getEntries () {
        return this.state.groups.map(group => {
            let editBtn = null;
            if (group.organization &&
                this.props.permissionManager.isAllowedOrganization('groups_edit', group.organization)) {
                editBtn = (
                    <button className="btn btn-sm btn-outline-secondary" onClick={this.onEditBtn.bind(this, group)}>
                        Edit
                    </button>
                );
            }

            const makeCell = value => (
                <span onMouseEnter={Helper.onCellHoverSelect}
                      onMouseLeave={() => window.getSelection().removeAllRanges()}>
                    {value}
                </span>
            );

            return (
                <Link to={'/groups/' + group.guid} key={group.guid}>
                    {makeCell(group.groupname)}
                    {makeCell(group.description)}
                    {makeCell(Helper.organizationDnToPath(group.organization || ''))}
                    <span style={{textAlign: 'right'}}>
                        {editBtn}
                    </span>
                </Link>
            );
        });
    }

    onNew () {
        this.props.history.push('/groups/new');
    }

    onClearFilter () {
        this.doFiltering(Groups.newFilter());
    }

    render () {
        let newGroupBtn = null;

        const organizations = this.props.permissionManager.getAllowedOrganizations('groups_list')
            .filter(o => this.allGroups.findIndex(r => r.organization === o.dn) !== -1);

        const allowedOrgs = this.props.permissionManager.getAllowedOrganizations('groups_create');
        if (allowedOrgs.length > 0) {
            newGroupBtn = <button onClick={this.onNew.bind(this)} className="btn btn-outline-success btn-sm">New group</button>
        }

        const orgFilter = organizations.find(o => o.dn === this.props.filter.organization.value);

        return (
             <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingGroups ]}

                    right={() => {
                        return (
                            <Fragment>
                                <span style={{padding: '1rem'}}>{this.state.groups.length} of {this.allGroups.length} records</span>
                                {newGroupBtn}
                            </Fragment>
                        );
                    }}
                />

                <div className="table">
                    <div className="table-head">
                        <div className="table-filter-header">
                            <span>
                                <input className="form-control" placeholder="Groupname" type="text"
                                       value={this.props.filter.groupname} onChange={this.setFilter.bind(this, 'groupname')}
                                />
                            </span>
                            <span>
                                <input className="form-control" placeholder="Description" type="text"
                                       value={this.props.filter.description} onChange={this.setFilter.bind(this, 'description')}
                                />
                            </span>
                            <span>
                                <Select
                                    isClearable
                                    placeholder="Org."
                                    onChange={this.setFilter.bind(this, 'organization')}
                                    options={organizations}
                                    value={orgFilter || null}
                                    getOptionValue={o => o.dn}
                                    getOptionLabel={o => o.dnPath}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        placeholder: base => ({ ...base, fontWeight: 'normal' }),
                                        singleValue: base => ({ ...base, fontWeight: 'normal' }),
                                    }}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                />
                            </span>
                            <span>
                                <button type="button" className="btn btn-outline-secondary float-right" onClick={this.onClearFilter.bind(this)}>
                                    Clear
                                </button>
                            </span>

                        </div>

                        <div className="table-header">
                            <span onClick={this.setSorting.bind(this, 'groupname')}>Name</span>
                            <span onClick={this.setSorting.bind(this, 'description')}>Description</span>
                            <span onClick={this.setSorting.bind(this, 'organization')}>Org.</span>
                            <span className="actions">Actions</span>
                        </div>
                    </div>
                    <div className="table-body">
                        {this.getEntries()}
                    </div>
                </div>
             </div>
        );
    }
}
