import React, { Fragment } from 'react';

class AlertMessageDialog extends React.Component {
    formatMessage (message) {
        if (typeof message === 'function') {
            message = message();
            return message && message.toString()
        } else if (message) {
            return message.toString();
        }
    }

    successMessages () {
        let messages = [];
        let classes = 'alert alert-success ';
        if (this.props.sticky) {
            classes += 'alert-sticky';
        }

        for (let i = 0; i < this.props.success.length; i++) {
            let str = this.formatMessage(this.props.success[i]);

            if (str) {
                messages.push(
                    <div className={classes} role="alert" key={'success' + i}>
                        {str}
                    </div>
                );
            }
        }

        return messages;
    }

    errorMessages () {
        let messages = [];
        let classes = 'alert alert-danger ';
        if (this.props.sticky) {
            classes += 'alert-sticky';
        }

        for (let i = 0; i < this.props.errors.length; i++) {
            let str = this.formatMessage(this.props.errors[i]);

            if (str) {
                messages.push(
                    <div className={classes} role="alert" key={'error' + i}>
                        {str}
                    </div>
                );
            }
        }

        return messages;
    }

    render () {
        return (
            <Fragment>
                {this.props.success && this.successMessages()}
                {this.props.errors && this.errorMessages()}
            </Fragment>
        );
    }
}

export default AlertMessageDialog;
