import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import Helper from './helper.js';

export default class EditUserGroups extends React.Component {
    state = {
        user: null,
        available_groups: [],
        selectedGroup: null,
        selectedGroups: [],

        loadingUser: { op: null },
        loadingGroups: { op: null },
        adding: { op: null },
        deleting: { op: null },
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingUser.op) {
            this.state.loadingUser.op.cancel();
        }

        if (this.state.loadingGroups.op) {
            this.state.loadingGroups.op.cancel();
        }

        if (this.state.adding.op) {
            this.state.adding.op.cancel();
        }

        if (this.state.deleting.op) {
            this.state.adding.op.cancel();
        }
    }

    async loadData () {
        await this.props.backend.waitAuthorized();

        let loadingUser = new Cancelable(this.props.backend.getUser(this.props.guid));

        loadingUser
            .then(response => {
                this.setState({ loadingUser: { op: null }, user: response.data });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingUser: { op: null, error: err } });
            });

        let loadingGroups = new Cancelable(this.props.backend.getUserGroups(this.props.guid));

        loadingGroups
            .then(response => {
                const available_groups = response.data.available_groups
                    .filter(g => response.data.user_groups.findIndex(ug => ug === g.id) === -1)
                    .map(g => {
                        g.label = Helper.organizationDnToPath(g.dn);
                        return g;
                    })

                available_groups.sort((a, b) => a.label.localeCompare(b.label));

                // build a group id map
                let all_group_map = {};

                for (let group of response.data.groups) {
                    all_group_map[group.id] = group;
                }

                // build the full tree of relationships:
                for (let group of response.data.groups) {
                    if (group.id in response.data.group_map) {
                        for (let parent_group_id of response.data.group_map[group.id]) {
                            let parent = all_group_map[parent_group_id];

                            if (parent) {
                                if (group.parents) {
                                    group.parents.push(parent);
                                } else {
                                    group.parents = [ parent ];
                                }
                            } else {
                                console.log('Group with id ' + parent_group_id + ' not found!');
                            }
                        }
                    }
                }

                // select all the groups the user has
                let all_user_groups = [];

                for (let group_id of response.data.user_groups) {
                    let parent = all_group_map[group_id];

                    if (parent) {
                        all_user_groups.push(parent);
                    } else {
                        console.log('Group with id ' + group_id + ' not found!');
                    }
                }

                this.sortSelectedGroups(all_user_groups);

                console.log('after sort');
                console.log(all_user_groups);

                for (let group of all_user_groups) {
                    console.log(group.dn, this.props.permissionManager.isAllowedGroup(group.dn, 'users_edit_groups'))
                }

                this.setState({
                    loadingGroups: { op: null },
                    available_groups,
                    selectedGroups: all_user_groups,
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingGroups: { op: null, error: err } });
            });


        this.setState({
            loadingUser: { op: loadingUser },
            loadingGroups: { op: loadingGroups },
        });
    }

    box (name, value) {
        return (
            <div className="form-group row">
                <label className="col-sm-3 col-form-label">{name}</label>
                <div className="col-sm-9">
                    <input type="text" readOnly className="form-control" value={value || ''} />
                </div>
            </div>
        );
    }

    sortSelectedGroups (groups) {
        groups.sort((a, b) => {
            let a_allowed = this.props.permissionManager.isAllowedGroup(a.dn, 'users_edit_groups');
            let b_allowed = this.props.permissionManager.isAllowedGroup(b.dn, 'users_edit_groups');

            if (a_allowed == b_allowed) {
                return Helper.organizationDnToPath(a.dn).localeCompare(Helper.organizationDnToPath(b.dn));
            }

            if (a_allowed) {
                return -1;
            }

            if (b_allowed) {
                return 1;
            }

            return 0;
        });
    }

    onAddGroup (event) {
        event.preventDefault();

        let adding = new Cancelable(this.props.backend.addUserToGroup(this.props.guid, this.state.selectedGroup.guid));

        adding
            .then(response => {
                let selectedGroups = this.state.selectedGroups.slice();
                selectedGroups.push(this.state.selectedGroup);

                let groups = this.state.available_groups.slice();
                groups.splice(groups.indexOf(this.state.selectedGroup), 1);

                this.sortSelectedGroups(selectedGroups);

                this.setState({
                    groups,
                    selectedGroups,
                    selectedGroup: null,
                    adding: { op: null },
                });

            })
            .catch(err => {
                console.log(err);
                this.setState({ adding: { op: null, error: err } });
            });

        this.setState({ adding: { op: adding }});
    }

    onChangeAddGroup (group) {
        this.setState({ selectedGroup: group });
    }

    onRemoveGroup (group, event) {
        event.preventDefault();

        let deleting = new Cancelable(this.props.backend.removeUserFromGroup(this.props.guid, group.guid));
        deleting.guid = group.guid;

        deleting
            .then(response => {
                let selectedGroups = this.state.selectedGroups.slice();
                selectedGroups.splice(selectedGroups.findIndex(g => g.guid === group.guid), 1);

                let groups = this.state.available_groups.slice();
                groups.push(group);

                this.setState({
                    groups,
                    selectedGroups,
                    selectedGroup: null,
                    deleting: { op: null },
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ deleting: { op: null, error: err } });
            });

        this.setState({ deleting: { op: deleting }});
    }

    renderParents (parents, depth) {
        if (!parents) {
            return null;
        }

        return (
            <div>
                {(parents || []).map(g => {
                    return (
                        <Fragment key={g.guid}>
                            <h6>{'---'.repeat(depth)} {g.groupname}</h6>
                            {this.renderParents(g.parents, depth + 1)}
                        </Fragment>
                    )
                })}
            </div>
        );
    }

    getEntries () {
        return this.state.selectedGroups.map(group => {
            return (
                <div key={group.guid} className="card bg-light mt-3">
                    <div className="card-body">
                        <div className="float-right">
                            {this.props.permissionManager.isAllowedGroup(group.dn, 'groups_list') &&
                                <Link to={'/groups/' + group.guid + '/members'} className="btn btn-outline-secondary btn-sm mr-1">
                                    View
                                </Link>
                            }

                            {this.props.permissionManager.isAllowedGroup(group.dn, 'users_edit_groups') &&
                                <button
                                    type="button" className="btn btn-outline-danger btn-sm" disabled={this.state.deleting.op}
                                    onClick={this.onRemoveGroup.bind(this, group)}>
                                        {this.state.deleting.op && this.state.deleting.id === group.guid ? 'Deleting...' : 'Delete'}
                                </button>
                            }
                        </div>
                        <h5 className="card-title">{Helper.organizationDnToPath(group.dn)}</h5>
                        {this.renderParents(group.parents, 0)}
                    </div>
                </div>
            );
        });
    }

    render () {
        let addBtn = null;

        if (this.state.adding.op) {
            addBtn = <button className="btn btn-outline-success btn-sm" disabled>adding...</button>
        } else {
            addBtn = <button className="btn btn-outline-success btn-sm" onClick={this.onAddGroup.bind(this)}>Add</button>
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingUser, this.state.loadingGroups, this.state.adding, this.state.deleting ]}

                    left={() => {
                        return (
                            <Fragment>
                                <Link to="/users" className="btn btn-outline-secondary btn-sm">Back</Link>&nbsp;
                                <Link to={'/users/' + this.props.guid} className="btn btn-outline-secondary btn-sm">View</Link>
                            </Fragment>
                        );
                    }}

                    middle={() => (
                        <Fragment>
                            <AlertMessageDialog
                                errors={[ this.state.adding.error, this.state.deleting.error, this.state.loadingUser.error, this.state.loadingGroups.error ]}
                            />
                            <h5 style={{textAlign: 'center'}}>{this.state.user ? this.state.user.firstname + ' ' + this.state.user.lastname : null}</h5>
                        </Fragment>
                    )}
                />
                <form className="offset-md-2 col-sm-8 mt-3">
                    <div className="card bg-light mt-3">
                        <div className="card-body">
                            <div className="float-right">
                                {addBtn}
                            </div>

                            <h5 className="card-title">Add group</h5>
                            <div className="form-group row">
                                <div className="col-sm">
                                    <Select
                                        options={this.state.available_groups}
                                        value={this.state.selectedGroup}
                                        getOptionLabel={(o) => o.label}
                                        getOptionValue={(o) => o.guid}
                                        onChange={this.onChangeAddGroup.bind(this)}
                                        placeholder="Select a group to add.."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <form className="offset-md-2 col-sm-8 mt-3">
                    {this.getEntries()}
                </form>
            </div>
        )
    }
}
