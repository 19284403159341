import React from 'react';
import ReactDOM from 'react-dom';
import Main from './main';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

console.log(process.env);

ReactDOM.render((<Main />), document.getElementById('root'));