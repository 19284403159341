import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import Helper from './helper.js';

class ShowUser extends React.Component {
    state = {
        user: null,

        success: null,
        loading_user: { op: null, error: null },
        lock_loading: { op: null, error: null }
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        let loading_user = new Cancelable(this.props.backend.getUser(this.props.guid));

        loading_user
            .then((response) => {
                this.setState({ loading_user: { op: null, error: null }, user: response.data })
            })
            .catch((e) => {
                this.setState({ loading_user: { op: null, error: e } });
            });

        this.setState({
            loading_user: { op: loading_user, error: null },
        });
    }

    displaySuccessMessage(message) {
        this.setState({ success: message });
        setTimeout(() => this.setState.bind({ success: null }), 4000);
    }

    onLock (item, e) {
        e.preventDefault();

        let lock_loading;
        if (item.locked) {
            lock_loading = new Cancelable(this.props.backend.unlockUser(item.guid));
        } else {
            lock_loading = new Cancelable(this.props.backend.lockUser(item.guid));
        }

        lock_loading
            .then(() => {
                let copy = Object.assign({}, this.state.user);
                copy.locked = true;
                this.setState({ lock_loading: { op: null, error: null }, user: copy });

                this.displaySuccessMessage((item.locked ? 'Unlock' : 'Lock') + 'ed user');
            })
            .catch(e => {
                this.setState({ lock_loading: { op: null, error: e }});
            });

        this.setState({ lock_loading: { op: lock_loading }});
    }

    componentWillUnmount () {
        if (this.state.loading_user.op) {
            this.state.loading_user.op.cancel();
        }
    }

    box (name, value) {
        return (
            <div key={name} className="form-group row">
                <label className="col-sm-3 col-form-label">{name}</label>
                <div className="col-sm-9">
                    <input type="text" readOnly className="form-control" value={value || ''} />
                </div>
            </div>
        );
    }

    managerNameFromDn(name) {
        if (!name) {
            return name;
        }

        let matches = /cn=([\S ]+?),/.exec(name);
        return matches.length === 2 ? matches[1] : name;
    }

    render () {
        let item = this.state.user;
        let applications = null;
        let permissions = null;
        let groups = null;
        let edit = null;
        let lock = null;

        if (item &&
            this.props.permissionManager.isAllowedOrganization('users_service_providers_list', item.organization)) {
            applications = <Fragment><Link to={'/users/' + this.props.guid + '/applications'} className="btn btn-outline-secondary btn-sm">Applications</Link>&nbsp;</Fragment>
        }

        if (item &&
            this.props.permissionManager.isAllowedOrganization('users_edit_permissions', item.organization)) {
            permissions = <Fragment><Link to={'/users/' + this.props.guid + '/permissions'} className="btn btn-outline-secondary btn-sm">Permissions</Link>&nbsp;</Fragment>
        }

        if (item &&
            this.props.permissionManager.isAllowedOrganization('users_edit_groups', item.organization)) {
            groups = <Fragment><Link to={'/users/' + this.props.guid + '/groups'} className="btn btn-outline-secondary btn-sm">Groups</Link>&nbsp;</Fragment>
        }

        if (item &&
            this.props.permissionManager.isAllowedOrganization('users_edit', item.organization)) {
            edit = <Fragment><Link to={'/users/' + this.props.guid + '/edit'} className="btn btn-outline-secondary btn-sm">Edit</Link>&nbsp;</Fragment>
        }

        if (item &&
            this.props.permissionManager.isAllowedOrganization('users_lock', item.organization)) {

            let text;
            if (item.locked) {
                text = this.state.lock_loading.op ? 'Unlocking...' : 'Unlock';
            } else {
                text = this.state.lock_loading.op ? 'Locking...' : 'Lock';
            }

            lock = (<Fragment>
                <button className="btn btn-outline-danger btn-sm" onClick={this.onLock.bind(this, item)}
                    disabled={this.state.lock_loading.op !== null}>{text}</button>
            </Fragment>);
        }

        let view_fields = this.props.permissionManager.getAllowedFields('users_list');

        if (item &&
            !this.props.permissionManager.isAllowedOrganization('users_list', item.organization)) {
            view_fields = [];
        }

        let all_properties = [];

        if (this.props.fields) {
            for (let i = 0; i < this.props.fields.length; i++) {
                let field = Object.assign({}, this.props.fields[i]);

                if (view_fields.indexOf(field.field) !== -1 && field.field !== 'password') {
                    all_properties.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loading_user ]}

                    left={() => {
                        return <Link to={'/users'} className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    middle={() =>
                        <Fragment>
                            <AlertMessageDialog
                                success={[this.state.success]}
                                errors={[ this.state.loading_user.error ]}
                            />
                            <h5 style={{textAlign: 'center'}}>{this.state.user ? this.state.user.firstname + ' ' + this.state.user.lastname : null}</h5>
                        </Fragment>
                    }

                    right={() => {
                        if (!item) {
                            return null;
                        }

                        return (
                            <Fragment>
                                {applications}
                                {permissions}
                                {groups}
                                {edit}
                                {lock}
                            </Fragment>
                        )
                    }}
                />
                {(() => {
                    if (item) {
                        return (
                            <form className="offset-md-2 col-sm-8 mt-3">
                                {all_properties.map((prop) => {
                                    let value = item[prop.field];

                                    switch (prop.field) {
                                        case 'organization':
                                            value = Helper.organizationDnToPath(value);
                                            break;

                                        case 'manager':
                                            value = this.managerNameFromDn(value);
                                            break;

                                        case 'tfa_enabled': /* fallthrough */
                                        case 'u2f_enabled':
                                            value = value ? 'Enabled' : 'Disabled';
                                            break;

                                        case 'locked':
                                            value = value.toString();
                                            break;

                                        case 'guid':
                                            value = Helper.formatObjectGuid(value);
                                            break;

                                        default: break;
                                    }

                                    return this.box(prop.name, value);
                                })}
                                {this.props.fields && all_properties.length === 0 &&
                                    <p className="text-center">No fields are allowed to be viewed.</p>
                                }
                            </form>
                        );
                    }

                    return null;
                })()}

            </div>
        )
    }
}

export default ShowUser;
