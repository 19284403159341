import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select'

import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import ValidationRules from '../validation.js';
import AlertMessageDialog from '../AlertMessageDialog.js';


export default class NewRole extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            role: this.newRole(),

            success: null,
            saving: { op: null },
        };

        this.formRef = React.createRef();
    }

    newRole () {
        return {
            name: null,
        };
    }

    componentDidMount () {
    }

    componentWillUnmount() {
        if (this.state.saving.op) {
            this.state.saving.op.cancel();
        }
    }

    onChange (field, event) {
        let role = Object.assign({}, this.state.role);

        if (ValidationRules[field] && ValidationRules[field].validate) {
            ValidationRules[field].validate(event.target);
        }

        if (!event) {
            role[field] = null;
        } else if (event.target) {
            role[field] = event.target.value;
        } else {
            role[field] = event;
        }

        this.setState({ role });
    }

    box (prop, value) {
        return (
            <div key={prop.field} className="form-group row">
                <label className="col-sm-3 col-form-label">{prop.name}</label>
                <div className="col-sm-9">
                    <Fragment>
                        {prop.control && prop.control(value)}

                        {!prop.control &&
                            <input type="text" className="form-control"
                                value={value || ''} onChange={this.onChange.bind(this, prop.field)}
                            />
                        }

                        <small className="form-text text-muted">
                            {ValidationRules[prop.field] && ValidationRules[prop.field].hint}
                        </small>
                    </Fragment>
                </div>
            </div>
        );
    }

    canCreate () {
        if (!this.formRef.current) {
            return true;
        }

        const { name } = this.state.role;

        return name && name.length > 0 &&
            this.formRef.current.getElementsByClassName('validation-has-error').length === 0;
    }

    onCreate () {
        const role = {
            ...this.state.role
        }

        let saving = new Cancelable(this.props.backend.createRole(role));

        saving
            .then(() => {
                this.setState({
                    saving: { op: null },
                    role: this.newRole(),
                    success: 'Role created successfully',
                });
            })
            .catch(err => {
                console.log(err);
                if (err.response && err.response.data) {
                    err += ' ' + JSON.stringify(err.response.data);
                }

                this.setState({ saving: { op: null, error: err }});
            });

        this.setState({ saving: { op: saving }});
    }

    render () {
        let saveBtn = null;

        if (this.state.saving.op) {
            saveBtn = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
        } else {
            saveBtn = (
                <button className="btn btn-outline-success btn-sm" onClick={this.onCreate.bind(this)}
                        disabled={!this.canCreate()}>
                    Create
                </button>
            );
        }

        const createFields = [ "name" ]; // this.props.permissionManager.getAllowedFields('roles_create');

        const organizationControl = value => (
            <Select
                options={this.state.organizations} getOptionLabel={o => o.name} getOptionValue={o => o.dn}
                isClearable value={value} onChange={this.onChange.bind(this, 'organization')}
                placeholder="Select organization..."
            />
        );

        let fields = [];
        if (this.props.fields) {
            for (const field of this.props.fields) {
                if (createFields.includes(field.field)) {
                    fields.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    loaders={[ this.state.saving ]}

                    left={() => {
                        return <Link to={'/roles'} className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    middle={() => (
                        <AlertMessageDialog
                            success={[ this.state.success ]}
                        />
                    )}

                    right={() => saveBtn}
                />

                <form ref={this.formRef} className="offset-md-2 col-sm-8 mt-3 needs-validation">
                    {fields.map(prop => {
                        const value = this.state.role[prop.field];
                        return this.box(prop, value);
                    })}
                </form>
            </div>
        );
    }
}
