import React, { Fragment } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import Select from 'react-select';
import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import AlertMessageDialog from '../AlertMessageDialog.js';
import Helper from '../helper.js';

import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default class NfcAccessList extends React.Component {
    constructor (props) {
        super(props);

        this.original_result = [];

        this.state = {
            users: [],

            tag_map: {},
            user_map: {},
            door_map: {},

            result: [],
            sortColumn: 'name',
            sortDirection: 'asc',

            start: moment().subtract(6, 'days'),
            end: moment(),

            success: null,

            /* set to error message if a click on a user is performed without the user_list permission */
            permError: null,

            loading_list: { op: null, error: null },
            loading_del: { op: null, error: null },
        };
    }

    static newFilter () {
        return {
        };
    }

    componentDidMount () {
        this.loadData();
    }

    loadList(filter) {
        let loading_list = new Cancelable(this.props.backend.getAccessList(filter));

        loading_list
            .then(response => {
                this.original_result = response.data.access;

                this.setState({ loading_list: { op: null, error: null }});
            })
            .catch(err => {
                this.setState({ loading_list: { op: null, error: err }});
            });

        return loading_list
    }

    loadData () {
        let loading_tags = new Cancelable(this.props.backend.getNfcTags());

        loading_tags
            .then(response => {
                let tag_map = {};

                for (let item of response.data.tags) {
                    tag_map[item.id] = item;
                }

                this.setState({ tags: response.data.tags, tag_map: tag_map });

                this.setState({ loading_tags: { op: null, error: null }});
            })
            .catch(err => {
                this.setState({ loading_tags: { op: null, error: err }});
            })

        let loading_users = new Cancelable(this.props.backend.getNfcUsers());

        loading_users
            .then(response => {
                let user_map = {};

                for (let item of response.data.users) {
                    user_map[item.id] = item;
                }

                this.setState({ users: response.data.users, user_map: user_map });

                this.setState({ loading_users: { op: null, error: null }});
            })
            .catch(err => {
                this.setState({ loading_users: { op: null, error: err }});
            })

        let loading_doors = new Cancelable(this.props.backend.getDoors());

        loading_doors
            .then(response => {
                let door_map = {};

                for (let item of response.data.doors) {
                    door_map[item.id] = item;
                }

                this.setState({ doors: response.data.doors, door_map: door_map });

                this.setState({ loading_doors: { op: null, error: null }});
            })
            .catch(err => {
                this.setState({ loading_doors: { op: null, error: err }});
            })

        this.setState({
            loading_doors: { op: loading_doors, error: null },
            loading_tags: { op: loading_tags, error: null },
            loading_users: { op: loading_users, error: null },
        });


        Promise.all([ loading_tags, loading_users ])
        .then(() => {
            let loading_list = this.loadList();

            loading_list
                .then(() => {
                    this.doFilter();
                });

            this.setState({
                loading_list: { op: loading_list, error: null },
            });
        });
    }

    componentWillUnmount () {
        if (this.state.loading_list.op) {
            this.state.loading_list.op.cancel();
        }
    }

    displaySuccessMessage(message) {
        this.setState({ success: message });
        setTimeout(() => this.setState({ success: null }), 4000);
    }

    selectFilter (property, value, e) {
        e.preventDefault();

        let filter = Object.assign({}, this.props.filter);
        filter[property] = value;

        this.doFilter(filter);
    }

    filterByUsers (users) {
        let filter = Object.assign({}, this.props.filter);

        let ids = [];

        for (let user of users) {
            ids.push(user.id);
        }

        filter.user_id = ids;

        this.doFilter(filter);
    }

    filterByTags (tags) {
        let filter = Object.assign({}, this.props.filter);

        let ids = [];

        for (let tag of tags) {
            ids.push(tag.id);
        }

        filter.nfctag_id = ids;

        this.doFilter(filter);
    }

    filterByDoors (doors) {
        let filter = Object.assign({}, this.props.filter);

        let ids = [];

        for (let door of doors) {
            ids.push(door.id);
        }

        filter.door_id = ids;

        this.doFilter(filter);
    }

    filterByDateRange (start, end) {
        let filter = Object.assign({}, this.props.filter);

        this.setState({
            start: start,
            end: end,
        });


        filter.from = start.format('YYYY-MM-DD 00:00');
        filter.to = end.format('YYYY-MM-DD 23:59:59');

        this.doFilter(filter);
    }

    filter (field, event) {
        let filter = Object.assign({}, this.props.filter);

        if (!event && field === 'organization') {
            // react-select will pass null if the search is cleared
            filter[field] = { value: '', exactMatch: true };
        } else if (typeof event === 'string') {
            filter[field] = event;
        } else if (event.value) {
            filter[field] = event.value;
        } else if (field === 'organization') {
            filter[field] = { value: event.dn, exactMatch: true };
        } else {
            filter[field] = event.target.value;
        }

        this.doFilter(filter);
    }

    selectSort (column) {
        if (column === this.state.sortColumn) {
            let direction = this.state.sortDirection === 'asc' ? 'desc' : 'asc';

            this.doFilter(this.props.filter, column, direction);
        } else {
            this.doFilter(this.props.filter, column, this.state.sortDirection);
        }
    }

    getEntries () {
        return this.state.result.map((item, index) => {

            const makeCell = value => (
                <span onMouseEnter={Helper.onCellHoverSelect}
                      onMouseLeave={() => window.getSelection().removeAllRanges()}>
                    {value}
                </span>
            );

            return (
                <Link to={'#'} key={item.id}>
                    {makeCell(item.created_at_local)}
                    {makeCell(item.tag.tag)}
                    {makeCell(item.user.fullname)}
                    {makeCell(item.door.description)}
                    {makeCell(item.access_granted)}
                    <span style={{textAlign: 'right'}}>
                    </span>
                </Link>
            )
        });
    }

    doFilter (filter, column, direction) {
        filter = filter || this.props.filter;
        column = column || this.state.sortColumn;
        direction = direction || this.state.sortDirection;

        filter.from = this.state.start.format('YYYY-MM-DD 00:00');
        filter.to = this.state.end.format('YYYY-MM-DD 23:59:59');

        // Load new data if a variable we can filter is set
        let loading = this.loadList(filter)

        loading.then(() => {
            // since we filter some properties on server, lets remove them from the local filter
            let local_filter = Object.assign({}, filter);
            local_filter.user_id = null;
            local_filter.nfctag_id = null;
            local_filter.door_id = null;
            local_filter.from = null;
            local_filter.to = null;

            let items = Helper.filterAndSortData(this.original_result, local_filter, column, direction);

            for (let item of items) {
                if (!(item.user_id in this.state.user_map)) {
                    item.user = { tag: '<Unknown User>' };
                } else {
                    item.user = this.state.user_map[item.user_id];
                }

                if (!(item.nfctag_id in this.state.tag_map)) {
                    item.tag = { tag: '<Unknown Tag>' };
                } else {
                    item.tag = this.state.tag_map[item.nfctag_id];
                }

                if (!(item.door_id in this.state.door_map)) {
                    item.door = { description: '<Unknown Door>' };
                } else {
                    item.door = this.state.door_map[item.door_id];
                }

                item.access_granted = item.result ? 'Yes' : 'No';

                item.created_at_local = moment(new Date(item.created_at + ' UTC')).format('YYYY-MM-DD HH:mm:ss')
            }

            this.setState({
                result: items,
                sortColumn: column,
                sortDirection: direction
            });

            this.props.setFilter(filter);
        });
    }

    onClearFilter () {
        this.doFilter(NfcAccessList.newFilter());
    }

    render () {
        let entries = this.getEntries();

        let label = 'Not selected';

        if (this.state.start && this.state.end) {
            label = this.state.start.format('DD.MM.YYYY') + ' - ' + this.state.end.format('DD.MM.YYYY');
        }

        let start = null;
        let end = null;

        if (this.state.start) {
            start = this.state.start.toDate();
        }

        if (this.state.end) {
            end = this.state.end.toDate();
        }

        let selectedTags = [];
        let selectedUsers = [];
        let selectedDoors = [];

        if (this.props.filter.nfctag_id instanceof Array) {
            for (let nfctag_id of this.props.filter.nfctag_id) {
                selectedTags.push(this.state.tag_map[nfctag_id]);
            }
        }

        if (this.props.filter.user_id instanceof Array) {
            for (let user_id of this.props.filter.user_id) {
                selectedUsers.push(this.state.user_map[user_id]);
            }
        }

        if (this.props.filter.door_id instanceof Array) {
            for (let door_id of this.props.filter.door_id) {
                selectedDoors.push(this.state.door_map[door_id]);
            }
        }

        return (
            <div className="container">
                <Switch>
                    <Route exact path="/nfc/accesslist">
                        <Fragment>
                            <PageMenu
                                onReload={this.loadData.bind(this)}
                                loaders={[ this.state.loading_list, this.state.loading_del ]}

                                middle={() => {
                                    return <AlertMessageDialog
                                        success={[this.state.success]}
                                    />;
                                }}

                                right={() => {
                                    return (
                                        <Fragment>
                                            <span style={{padding: '1rem'}}>{this.state.result.length} of {this.original_result.length} records</span>
                                        </Fragment>
                                    );
                                }}
                            />
                            <div className="table">
                                <div className="table-head">
                                    <div className="table-filter-header">
                                        <span style={{width: '260px'}}>
                                            <DateRangePicker
                                                initialSettings={{
                                                  startDate: start,
                                                  endDate: end,
                                                  ranges: {
                                                    Today: [ moment().toDate(), moment().toDate() ],
                                                    Yesterday: [
                                                      moment().subtract(1, 'days').toDate(),
                                                      moment().subtract(1, 'days').toDate(),
                                                    ],
                                                    'Last 7 Days': [
                                                      moment().subtract(6, 'days').toDate(),
                                                      moment().toDate(),
                                                    ],
                                                    'Last 30 Days': [
                                                      moment().subtract(29, 'days').toDate(),
                                                      moment().toDate(),
                                                    ],
                                                    'This Month': [
                                                      moment().startOf('month').toDate(),
                                                      moment().endOf('month').toDate(),
                                                    ],
                                                    'Last Month': [
                                                      moment().subtract(1, 'month').startOf('month').toDate(),
                                                      moment().subtract(1, 'month').endOf('month').toDate(),
                                                    ],
                                                  },
                                                }}
                                                onCallback={this.filterByDateRange.bind(this)}
                                              >
                                                <div
                                                  style={{
                                                    background: '#fff',
                                                    cursor: 'pointer',
                                                    padding: '5px 10px',
                                                    border: '1px solid #ccc',
                                                    width: '100%',
                                                    paddingBottom: '7px',
                                                    borderRadius: '4px',
                                                  }}
                                                >
                                                  <i className="fa fa-calendar"></i>&nbsp;
                                                  <span>{label}</span> <i className="fa fa-caret-down"></i>
                                                </div>
                                              </DateRangePicker>
                                        </span>
                                        <span style={{minWidth: '150px'}}>
                                            <Select
                                                options={this.state.tags} getOptionLabel={o => o.tag} getOptionValue={o => o.id} isClearable isMulti
                                                placeholder="Search tags" menuPortalTarget={document.body} onChange={this.filterByTags.bind(this)}
                                                value={selectedTags}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 999 }),
                                                    placeholder: base => ({ ...base, fontWeight: 'normal' }),
                                                    multiValueLabel: base => ({ ...base, fontWeight: 'normal' }),
                                                }}
                                            />
                                        </span>
                                        <span style={{minWidth: '165px'}}>
                                            <Select
                                                options={this.state.users} getOptionLabel={o => o.fullname} getOptionValue={o => o.id} isClearable isMulti
                                                placeholder="Search users" menuPortalTarget={document.body} onChange={this.filterByUsers.bind(this)}
                                                value={selectedUsers}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 999 }),
                                                    placeholder: base => ({ ...base, fontWeight: 'normal' }),
                                                    multiValueLabel: base => ({ ...base, fontWeight: 'normal' }),
                                                }}
                                            />
                                        </span>
                                        <span style={{minWidth: '165px'}}>
                                            <Select
                                                options={this.state.doors} getOptionLabel={o => o.description} getOptionValue={o => o.id} isClearable isMulti
                                                placeholder="Search doors" menuPortalTarget={document.body} onChange={this.filterByDoors.bind(this)}
                                                value={selectedDoors}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 999 }),
                                                    placeholder: base => ({ ...base, fontWeight: 'normal' }),
                                                    multiValueLabel: base => ({ ...base, fontWeight: 'normal' }),
                                                }}
                                            />
                                        </span>
                                        <span></span>
                                        <span>
                                            <button type="button" className="btn btn-outline-secondary float-right" onClick={this.onClearFilter.bind(this)}>
                                                Clear
                                            </button>
                                        </span>
                                    </div>
                                    <div className="table-header">
                                        <span onClick={this.selectSort.bind(this, 'nfctag_id')}>Access Time</span>
                                        <span onClick={this.selectSort.bind(this, 'nfctag_id')}>Tag</span>
                                        <span onClick={this.selectSort.bind(this, 'user_id')}>User</span>
                                        <span onClick={this.selectSort.bind(this, 'door_id')}>Door</span>
                                        <span onClick={this.selectSort.bind(this, 'result')}>Access granted</span>
                                        <span className="actions">Actions</span>
                                    </div>
                                </div>
                                <div className="table-body">
                                    {entries}
                                </div>
                            </div>

                            <AlertMessageDialog sticky
                                errors={[this.state.permError]}
                            />
                        </Fragment>
                    </Route>
                </Switch>
            </div>
        )
    }
}