import React, { Fragment } from 'react';
import Select, { createFilter } from 'react-select';
import { Link, Route, Switch } from 'react-router-dom';

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import Helper from './helper.js';

class Users extends React.Component {
    constructor (props) {
        super(props);

        this.original_result = [];

        this.state = {
            result: [],
            sortColumn: 'email',
            sortDirection: 'asc',

            success: null,

            /* set to error message if a click on a user is performed without the user_list permission */
            permError: null,

            loading_users: { op: null, error: null },
            lock_loading: { op: null, error: null },
        };
    }

    static newFilter () {
        return {
            firstname: '',
            lastname: '',
            email: '',
            jobtitle: '',
            department: '',
            organization: { value: '', exactMatch: true },
            locked: false,
        };
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        let loading_users = new Cancelable(this.props.backend.getUsers([ "guid", "firstname", "lastname", "email", "jobtitle", "locked", "department", "organization" ]));

        loading_users
            .then(response => {
                this.original_result = response.data.users;
                this.doFilter(this.props.filter);

                this.setState({ loading_users: { op: null, error: null }});
            })
            .catch(err => {
                this.setState({ loading_users: { op: null, error: err }});
            });

        this.setState({
            loading_users: { op: loading_users, error: null },
        });
    }

    componentWillUnmount () {
        if (this.state.loading_users.op) {
            this.state.loading_users.op.cancel();
        }
    }

    onNew (e) {
        this.props.history.push('/users/new');
        e.preventDefault();
    }

    onEdit (item, e) {
        this.props.history.push('/users/' + item.guid + '/edit');
        e.preventDefault();
    }

    displaySuccessMessage(message) {
        this.setState({ success: message });
        setTimeout(() => this.setState({ success: null }), 4000);
    }

    onLock (item, e) {
        e.preventDefault();

        let lock_loading;
        if (item.locked) {
            lock_loading = new Cancelable(this.props.backend.unlockUser(item.guid));
        } else {
            lock_loading = new Cancelable(this.props.backend.lockUser(item.guid));
        }

        lock_loading
            .then(() => {
                let index = this.original_result.indexOf(item);
                this.original_result[index].locked = !item.locked;
                this.doFilter(this.props.filter);

                this.displaySuccessMessage((item.locked ? 'Lock' : 'Unlock') + 'ed user');
            })
            .catch(e => {
                this.setState({ lock_loading: { op: null, error: e }});
            });

        this.setState({ lock_loading: { op: lock_loading }});
   }

    selectFilter (property, value, e) {
        e.preventDefault();

        let filter = Object.assign({}, this.props.filter);
        filter[property] = value;

        this.doFilter(filter);
    }

    filter (field, event) {
        let filter = Object.assign({}, this.props.filter);

        if (!event && field === 'organization') {
            // react-select will pass null if the search is cleared
            filter[field] = { value: '', exactMatch: true };
        } else if (typeof event === 'string') {
            filter[field] = event;
        } else if (event.value) {
            filter[field] = event.value;
        } else if (field === 'organization') {
            filter[field] = { value: event.dn, exactMatch: true };
        } else {
            filter[field] = event.target.value;
        }

        this.doFilter(filter);
    }

    selectSort (column) {
        if (column === this.state.sortColumn) {
            let direction = this.state.sortDirection === 'asc' ? 'desc' : 'asc';

            this.doFilter(this.props.filter, column, direction);
        } else {
            this.doFilter(this.props.filter, column, this.state.sortDirection);
        }
    }

    getUserEntries () {
        return this.state.result.map((item, index) => {
            let edit = null;
            let lock = null;

            if (item &&
                this.props.permissionManager.isAllowedOrganization('users_edit', item.organization)) {
                edit = <button onClick={this.onEdit.bind(this, item)} className="btn btn-outline-secondary btn-sm mb-2">Edit</button>
            }

            if (item &&
                this.props.permissionManager.isAllowedOrganization('users_lock', item.organization)) {
                lock = <button onClick={this.onLock.bind(this, item)} className="btn btn-outline-danger btn-sm">{(item.locked ? 'Unlock' : 'Lock')}</button>
            }

            let clickHandler;
            let userLink = '#';
            if (item &&
                this.props.permissionManager.isAllowedOrganization('users_list', item.organization)) {

                userLink = '/users/' + item.guid;
            } else {
                clickHandler = () => {
                    this.setState({ permError: new Error("Insufficient permissions to view user (needs 'users_list')") });
                    setTimeout(() => this.setState({ permError: null }), 3000);
                };
            }

            let locked = item.locked ? 'locked' : '';

            const makeCell = value => (
                <span onMouseEnter={Helper.onCellHoverSelect}
                      onMouseLeave={() => window.getSelection().removeAllRanges()}>
                    {value}
                </span>
            );

            return (
                <Link to={userLink} key={item.guid} className={locked} onClick={clickHandler}>
                    {makeCell(item.firstname)}
                    {makeCell(item.lastname)}
                    {makeCell(item.email)}
                    {makeCell(item.jobtitle)}
                    {makeCell(item.department)}
                    {makeCell(Helper.organizationDnToPath(item.organization || ''))}
                    <span style={{textAlign: 'right'}}>
                        {edit}<br/>{lock}
                    </span>
                </Link>
            )
        });
    }

    doFilter (filter, column, direction) {
        filter = filter || this.props.filter;
        column = column || this.state.sortColumn;
        direction = direction || this.state.sortDirection;

        this.setState({
            result: Helper.filterAndSortData(this.original_result, filter, column, direction),
            sortColumn: column,
            sortDirection: direction
        });

        this.props.setFilter(filter);
    }

    onClearFilter () {
        this.doFilter(Users.newFilter());
    }

    render () {
        let new_user = null;

        const organizations = this.props.permissionManager.getAllowedOrganizations('users_list')
            .filter(o => this.original_result.findIndex(r => r.organization === o.dn) !== -1);

        let allowed_orgs = this.props.permissionManager.getAllowedOrganizations('users_create');

        if (allowed_orgs.length > 0) {
            new_user = <button onClick={this.onNew.bind(this)} className="btn btn-outline-success btn-sm">New user</button>
        }

        let user_entries = this.getUserEntries();

        const orgFilter = organizations.find(o => o.dn === this.props.filter.organization.value);

        return (
            <div className="container">
                <Switch>
                    <Route exact path="/users">
                        <Fragment>
                            <PageMenu
                                onReload={this.loadData.bind(this)}
                                loaders={[ this.state.loading_users ]}

                                left={() => {
                                    return (
                                        <ul className="nav nav-pills mb-3" style={{fontSize: '10pt'}}>
                                            <li className="nav-item">
                                                <a className={'nav-link ' + (this.props.filter.locked === null ? 'active' : '')} onClick={this.selectFilter.bind(this, 'locked', null)} href="/users">All</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={'nav-link ' + (this.props.filter.locked === false ? 'active' : '')} onClick={this.selectFilter.bind(this, 'locked', false)} href="/users">Active</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={'nav-link ' + (this.props.filter.locked === true ? 'active' : '')} onClick={this.selectFilter.bind(this, 'locked', true)} href="/users">Locked</a>
                                            </li>
                                        </ul>
                                    );
                                }}

                                middle={() => {
                                    return <AlertMessageDialog
                                        success={[this.state.success]}
                                    />;
                                }}

                                right={() => {
                                    return (
                                        <Fragment>
                                            <span style={{padding: '1rem'}}>{this.state.result.length} of {this.original_result.length} records</span>
                                            {new_user}
                                        </Fragment>
                                    );
                                }}
                            />
                            <div className="table">
                                <div className="table-head">
                                    <div className="table-filter-header">
                                        <span>
                                            <input className="form-control" placeholder="Firstname" type="text" value={this.props.filter.firstname} onChange={this.filter.bind(this, 'firstname')} />
                                        </span>
                                        <span>
                                            <input className="form-control" placeholder="Lastname" type="text" value={this.props.filter.lastname} onChange={this.filter.bind(this, 'lastname')} />
                                        </span>
                                        <span>
                                            <input className="form-control" placeholder="Email" type="text" value={this.props.filter.email} onChange={this.filter.bind(this, 'email')} />
                                        </span>
                                        <span>
                                            <input className="form-control" placeholder="Position" type="text" value={this.props.filter.jobtitle} onChange={this.filter.bind(this, 'jobtitle')} />
                                        </span>
                                        <span>
                                            <input className="form-control" placeholder="Department" type="text" value={this.props.filter.department} onChange={this.filter.bind(this, 'department')} />
                                        </span>
                                        <span style={{width: '120px'}}>
                                            <Select
                                                isClearable
                                                placeholder="Org."
                                                onChange={this.filter.bind(this, 'organization')}
                                                options={organizations}
                                                getOptionValue={o => o.dn}
                                                getOptionLabel={o => o.dnPath}
                                                value={orgFilter || null}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    placeholder: base => ({ ...base, fontWeight: 'normal' }),
                                                    singleValue: base => ({ ...base, fontWeight: 'normal' }),
                                                }}
                                                filterOption={createFilter({ ignoreAccents: false })}
                                            />
                                        </span>
                                        <span>
                                            <button type="button" className="btn btn-outline-secondary float-right" onClick={this.onClearFilter.bind(this)}>
                                                Clear
                                            </button>
                                        </span>
                                    </div>
                                    <div className="table-header">
                                        <span onClick={this.selectSort.bind(this, 'firstname')}>Firstname</span>
                                        <span onClick={this.selectSort.bind(this, 'lastname')}>Lastname</span>
                                        <span onClick={this.selectSort.bind(this, 'email')}>Email</span>
                                        <span onClick={this.selectSort.bind(this, 'jobtitle')}>Jobtitle</span>
                                        <span onClick={this.selectSort.bind(this, 'department')}>Department</span>
                                        <span onClick={this.selectSort.bind(this, 'organization')}>Org.</span>
                                        <span className="actions">Actions</span>
                                    </div>
                                </div>
                                <div className="table-body">
                                    {user_entries}
                                </div>
                            </div>

                            <AlertMessageDialog sticky
                                errors={[this.state.permError]}
                            />
                        </Fragment>
                    </Route>
                </Switch>
            </div>
        )
    }
}

export default Users;
