import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select'

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import Helper from './helper.js';
import AlertMessageDialog from './AlertMessageDialog.js';


export default class EditGroupMembers extends React.Component {
    allUsers = []
    allMembers = []

    state = {
        members: [],
        groupMembers: [],
        memberOf: [],
        selectedUser: null,
        selectableUsers: [],

        currentDisplay: 'users',

        userFilter: '',
        groupFilter: '',

        loadingUsers: { op: null },
        loadingGroupMembers: { op: null },
        addingAction: { op: null },
        deletingAction: { op: null },
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingUsers.op) {
            this.state.loadingUsers.op.cancel();
        }

        if (this.state.loadingGroupMembers.op) {
            this.state.loadingGroupMembers.op.cancel();
        }

        if (this.state.addingAction.op) {
            this.state.loadingAction.op.cancel();
        }

        if (this.state.deletingAction.op) {
            this.state.deletingAction.op.cancel();
        }
    }

    loadData () {
        let loadingGroup = new Cancelable(this.props.backend.getGroup(this.props.guid))

        loadingGroup
            .then(response => {
                this.setState({
                    group: response.data,
                    loadingGroup: { op: null, error: null },
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingGroup: { op: null, error: err }});
            })

        let loadingUsers = new Cancelable(this.props.backend.getUsers([ 'guid', 'fullname', 'locked', 'organization', 'dn' ]));

        loadingUsers
            .then(response => {
                this.allUsers = response.data.users;
                let selectable = response.data.users
                    .filter(u => !u.locked)
                    .filter(u => this.state.members.findIndex(m => m.guid === u.guid) === -1)
                    .map(u => ({ label: Helper.organizationDnToPath(u.dn), value: u.guid }));

                selectable.sort((a, b) => a.label.localeCompare(b.label));

                this.setState({
                    selectableUsers: selectable,
                    loadingUsers: { op: null }
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingUsers: { op: null, error: err }});
            });

        let loadingGroupMembers = new Cancelable(this.props.backend.getGroupMembers(this.props.guid));

        loadingGroupMembers
            .then(response => {
                let members = response.data.members;
                this.sortMembers(members);

                let groupMembers = response.data.groupMembers
                this.sortGroupMembers(groupMembers);

                let memberOf = response.data.memberOf
                this.sortGroupMembers(memberOf);

                // TODO: fix race condition with loading above!
                const selectable = this.state.selectableUsers.slice()
                    .filter(u => members.findIndex(m => m.guid === u.value) === -1);

                this.allMembers = members;
                this.groupMembers = groupMembers;
                this.memberOf = memberOf;

                this.setState({
                    members,
                    groupMembers,
                    memberOf,
                    selectableUsers: selectable,
                    loadingGroupMembers: { op: null },
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingGroupMembers: { op: null, error: err }});
            })

        this.setState({
            loadingGroup: { op: loadingGroup },
            loadingUsers: { op: loadingUsers },
            loadingGroupMembers: { op: loadingGroupMembers },
        });
    }

    onAddMember (event) {
        event.preventDefault();

        let addingAction = new Cancelable(this.props.backend.addUserToGroup(this.state.selectedUser.value, this.props.guid));

        addingAction
            .then(response => {
                const user = this.allUsers.find(u => u.guid === this.state.selectedUser.value);

                let members = this.state.members.slice();
                members.push(user);

                this.allMembers.push(user);

                let selectable = this.state.selectableUsers.slice();
                selectable.splice(selectable.indexOf(this.state.selectedUser), 1);

                this.setState({
                    members,
                    selectableUsers: selectable,
                    selectedUser: null,
                    addingAction: { op: null },
                });

            })
            .catch(err => {
                console.log(err);
                this.setState({ addingAction: { op: null, error: err } });
            });

        this.setState({ addingAction: { op: addingAction }});
    }

    onRemoveMember (user, event) {
        event.preventDefault();

        let deletingAction = new Cancelable(this.props.backend.removeUserFromGroup(user.guid, this.props.guid));

        deletingAction
            .then(response => {
                let members = this.state.members.slice();
                members.splice(members.indexOf(user), 1);

                this.allMembers.splice(this.allMembers.indexOf(user), 1);

                let selectable = this.state.selectableUsers.slice();
                selectable.push({ label: user.fullname, value: user.guid });
                selectable.sort((a, b) => a.label.localeCompare(b.label));

                this.setState({
                    members,
                    selectableUsers: selectable,
                    selectedUser: null,
                    deletingAction: { op: null },
                });

            })
            .catch(err => {
                console.log(err);
                this.setState({ deletingAction: { op: null, error: err } });
            });

        this.setState({ deletingAction: { op: deletingAction }});
    }

    onChangeAddUser (user) {
        this.setState({ selectedUser: user });
    }

    sortMembers (members) {
        members.sort((a, b) => {

            let a_allowed = this.props.permissionManager.isAllowedOrganization('users_edit_groups', a.organization);
            let b_allowed = this.props.permissionManager.isAllowedOrganization('users_edit_groups', b.organization);

            if (a_allowed == b_allowed) {
                return Helper.organizationDnToPath(a.dn).localeCompare(Helper.organizationDnToPath(b.dn));
            }

            if (a_allowed) {
                return -1;
            }

            if (b_allowed) {
                return 1;
            }

            return 0;
        });
    }

    sortGroupMembers (members) {
        members.sort((a, b) => {

            let a_allowed = this.props.permissionManager.isAllowedGroup(a.dn, 'users_edit_groups');
            let b_allowed = this.props.permissionManager.isAllowedGroup(b.dn, 'users_edit_groups');

            if (a_allowed == b_allowed) {
                return Helper.organizationDnToPath(a.dn).localeCompare(Helper.organizationDnToPath(b.dn));
            }

            if (a_allowed) {
                return -1;
            }

            if (b_allowed) {
                return 1;
            }

            return 0;
        });
    }


    setUserFilter (event) {
        const filter = { fullname: event.target.value };
        let members = Helper.filterAndSortData(this.allMembers, filter, 'fullname', 'asc');
        this.sortMembers(members);

        this.setState({
            userFilter: event.target.value,
            members,
        });
    }

    setGroupFilter (event) {
        const filter = { groupname: event.target.value };
        let groupMembers = Helper.filterAndSortData(this.groupMembers, filter, 'groupname', 'asc');
        this.sortGroupMembers(groupMembers);

        this.setState({
            groupFilter: event.target.value,
            groupMembers,
        });
    }

    setMemberOfFilter (event) {
        const filter = { groupname: event.target.value };
        let memberOf = Helper.filterAndSortData(this.memberOf, filter, 'groupname', 'asc');
        this.sortGroupMembers(memberOf);

        this.setState({
            groupFilter: event.target.value,
            memberOf,
        });
    }

    getMemberOfEntries () {
        return this.state.memberOf.map(group => (
            <div key={group.guid} className="card bg-light mt-3">
                <div className="card-body">
                    <div className="float-right">
                        <Link to={'/groups/' + group.guid} className="btn btn-outline-secondary btn-sm">View</Link>
                    </div>

                    <h5 className="card-title">
                        {Helper.organizationDnToPath(group.dn)}
                    </h5>
                </div>
            </div>
        ));
    }

    getGroupEntries () {
        return this.state.groupMembers.map(group => (
            <div key={group.guid} className="card bg-light mt-3">
                <div className="card-body">
                    <div className="float-right">
                        <Link to={'/groups/' + group.guid} className="btn btn-outline-secondary btn-sm">View</Link>&nbsp;
                        {this.props.permissionManager.isAllowedOrganization('users_edit_groups', group.organization) &&
                            <button
                                type="button" className="btn btn-outline-danger btn-sm" disabled={this.state.deletingAction.op}
                                onClick={this.onRemoveMember.bind(this, group)}>
                                    {this.state.deletingAction.op && this.state.deletingAction.id === group.guid ? 'Deleting...' : 'Delete'}
                            </button>
                        }
                    </div>
                    <h5 className="card-title">
                        {Helper.organizationDnToPath(group.dn)}
                    </h5>
                </div>
            </div>
        ));
    }

    getUserEntries () {
        return this.state.members.map(user => (
            <div key={user.guid} className="card bg-light mt-3">
                <div className="card-body">
                    <div className="float-right">
                        <Link to={'/users/' + user.guid} className="btn btn-outline-secondary btn-sm">View</Link>&nbsp;
                        {this.props.permissionManager.isAllowedOrganization('users_edit_groups', user.organization) &&
                            <button
                                type="button" className="btn btn-outline-danger btn-sm" disabled={this.state.deletingAction.op}
                                onClick={this.onRemoveMember.bind(this, user)}>
                                    {this.state.deletingAction.op && this.state.deletingAction.id === user.guid ? 'Deleting...' : 'Delete'}
                            </button>
                        }
                    </div>
                    <h5 className="card-title">
                        {user.locked ? <Fragment><i className="fas fa-user-slash"></i>&nbsp;</Fragment> : ''}
                        {Helper.organizationDnToPath(user.dn)}

                    </h5>
                </div>
            </div>
        ));
    }

    switchDisplay (target, e) {
        this.setState({ currentDisplay: target });

        e.preventDefault();
    }

    render () {
        let addBtn = null;

        if (this.state.addingAction.op) {
            addBtn = <button className="btn btn-outline-success btn-sm" disabled>Adding...</button>
        } else {
            addBtn = (
                <button className="btn btn-outline-success btn-sm" onClick={this.onAddMember.bind(this)}
                    disabled={!this.state.selectedUser}>Add
                </button>
            );
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingUsers, this.state.loadingGroupMembers, this.state.addingAction, this.state.deletingAction ]}

                    left={() => {
                        return (
                            <Fragment>
                                <Link to="/groups" className="btn btn-outline-secondary btn-sm">Back</Link>&nbsp;
                                <Link to={'/groups/' + this.props.guid} className="btn btn-outline-secondary btn-sm">View</Link>
                            </Fragment>
                        );
                    }}

                    middle={() => (
                        <Fragment>
                            <AlertMessageDialog
                                errors={[ this.state.loadingUsers.error, this.state.loadingGroupMembers.error, this.state.addingAction.error, this.state.deletingAction.error ]}
                            />
                            {this.state.group ? <h5 style={{textAlign: 'center'}}>{this.state.group.groupname}</h5> : null}
                        </Fragment>
                    )}
                />
                <form className="offset-md-2 col-sm-8 mt-3">
                    <div className="card bg-light mt-3">
                        <div className="card-body">
                            <div className="float-right">
                                {addBtn}
                            </div>

                            <h5 className="card-title">Add group member</h5>
                            <div className="form-group row">
                                <div className="col-sm">
                                    <Select
                                        options={this.state.selectableUsers}
                                        value={this.state.selectedUser}
                                        getOptionLabel={(o) => o.label}
                                        getOptionValue={(o) => o.guid}
                                        onChange={this.onChangeAddUser.bind(this)}
                                        placeholder="Select a user to add.."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <nav className="nav nav-tabs offset-md-2 col-sm-8 mt-3">
                  <a className={(this.state.currentDisplay == 'users' ? 'active' : '') + " nav-link"} href="#" onClick={this.switchDisplay.bind(this, 'users')}>Users</a>
                  <a className={(this.state.currentDisplay == 'groups' ? 'active' : '') + " nav-link"} href="#" onClick={this.switchDisplay.bind(this, 'groups')}>Groups</a>
                  <a className={(this.state.currentDisplay == 'memberof' ? 'active' : '') + " nav-link"} href="#" onClick={this.switchDisplay.bind(this, 'memberof')}>Member Of</a>
                </nav>

                {this.state.currentDisplay == 'users' ?
                    <form className="offset-md-2 col-sm-8 mt-3">
                        <input className="form-control" placeholder="Name of user to find.." type="text"
                               value={this.state.userFilter} onChange={this.setUserFilter.bind(this)}
                        />

                        {this.getUserEntries()}
                    </form>
                : null}

                {this.state.currentDisplay == 'groups' ?
                    <form className="offset-md-2 col-sm-8 mt-3">
                        <input className="form-control" placeholder="Name of group to find.." type="text"
                               value={this.state.groupFilter} onChange={this.setGroupFilter.bind(this)}
                        />

                        {this.getGroupEntries()}
                    </form>
                : null}

                {this.state.currentDisplay == 'memberof' ?
                    <form className="offset-md-2 col-sm-8 mt-3">
                        <input className="form-control" placeholder="Name of group to find.." type="text"
                               value={this.state.memberOfFilter} onChange={this.setMemberOfFilter.bind(this)}
                        />

                        {this.getMemberOfEntries()}
                    </form>
                : null}
            </div>
        )
    }
}
