import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select, { Creatable } from 'react-select'

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import ValidationRules from './validation.js';
import AlertMessageDialog from './AlertMessageDialog.js';


const GROUP_TYPES = [
    { label: 'Global', value: 'global' },
    { label: 'Security', value: 'security' },
];

export default class NewGroup extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            group: this.newGroup(),

            success: null,
            saving: { op: null },
        };

        this.formRef = React.createRef();
    }

    newGroup () {
        return {
            groupname: null,
            description: null,
            group_type: [],
            gid_number: null,
            organization: null,
            allowed_hosts: [],
        };
    }

    componentWillUnmount() {
        if (this.state.saving.op) {
            this.state.saving.op.cancel();
        }
    }

    onChange (field, event) {
        let group = Object.assign({}, this.state.group);

        if (ValidationRules[field] && ValidationRules[field].validate) {
            ValidationRules[field].validate(event.target);
        }

        if (!event) {
            group[field] = null;
        } else if (event.target) {
            group[field] = event.target.value;
        } else {
            group[field] = event;
        }

        this.setState({ group });
    }

    box (prop, value) {
        return (
            <div key={prop.field} className="form-group row">
                <label className="col-sm-3 col-form-label">{prop.name}</label>
                <div className="col-sm-9">
                    <Fragment>
                        {prop.control && prop.control(value)}

                        {!prop.control &&
                            <input type="text" className="form-control"
                                value={value || ''} onChange={this.onChange.bind(this, prop.field)}
                            />
                        }

                        <small className="form-text text-muted">
                            {ValidationRules[prop.field] && ValidationRules[prop.field].hint}
                        </small>
                    </Fragment>
                </div>
            </div>
        );
    }

    canCreate () {
        if (!this.formRef.current) {
            return true;
        }

        const { groupname, organization } = this.state.group;

        return groupname && groupname.length > 0 && organization &&
            this.formRef.current.getElementsByClassName('validation-has-error').length === 0;
    }

    onCreate () {
        const group = {
            ...this.state.group,
            organization: this.state.group.organization.dn,
            group_type: this.state.group.group_type.map(t => t.value),
            allowed_hosts: this.state.group.allowed_hosts.map(t => t.label),
        }

        let saving = new Cancelable(this.props.backend.createGroup(group));

        saving
            .then(() => {
                this.setState({
                    saving: { op: null },
                    group: this.newGroup(),
                    success: 'Group created successfully',
                });
            })
            .catch(err => {
                console.log(err);
                if (err.response && err.response.data) {
                    err += ' ' + JSON.stringify(err.response.data);
                }

                this.setState({ saving: { op: null, error: err }});
            });

        this.setState({ saving: { op: saving }});
    }

    render () {
        let saveBtn = null;

        if (this.state.saving.op) {
            saveBtn = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
        } else {
            saveBtn = (
                <button className="btn btn-outline-success btn-sm" onClick={this.onCreate.bind(this)}
                        disabled={!this.canCreate()}>
                    Create
                </button>
            );
        }

        const organizations = this.props.permissionManager.getAllowedOrganizations('groups_create');
        const createFields = this.props.permissionManager.getAllowedFields('groups_create');

        const organizationControl = value => (
            <Select
                options={organizations} getOptionLabel={o => o.name} getOptionValue={o => o.dn}
                isClearable value={value} onChange={this.onChange.bind(this, 'organization')}
                placeholder="Select organization..."
            />
        );

        const groupTypeControl = value => (
            <Select
                options={GROUP_TYPES} value={value}
                isMulti isClearable placeholder="Select group types..."
                onChange={this.onChange.bind(this, 'group_type')}
            />
        );

        const allowedHostsControl = value => (
            <Creatable
                value={value}
                isMulti isClearable placeholder="Enter allowed hosts..."
                onChange={this.onChange.bind(this, 'allowed_hosts')}
            />
        );

        let fields = [];
        if (this.props.fields) {
            for (const field of this.props.fields) {
                switch (field.field) {
                    case 'group_type':
                        field.control = groupTypeControl;
                        break;

                    case 'organization':
                        field.control = organizationControl;
                        break;

                    case 'allowed_hosts':
                        field.control = allowedHostsControl;
                        break;

                    default: break;
                }

                if (createFields.includes(field.field)) {
                    fields.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    loaders={[ this.state.saving ]}

                    left={() => {
                        return <Link to={'/groups'} className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    middle={() => (
                        <AlertMessageDialog
                            success={[this.state.success]}
                        />
                    )}

                    right={() => saveBtn}
                />

                <form ref={this.formRef} className="offset-md-2 col-sm-8 mt-3 needs-validation">
                    {fields.map(prop => {
                        const value = this.state.group[prop.field];
                        return this.box(prop, value);
                    })}
                </form>
            </div>
        );
    }
}
