import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import ValidationRules from './validation.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import { getDifference } from './helper.js';


export default class EditComputer extends React.Component {
    state = {
        computer: {},
        original: {},
        hasChanged: false,

        loadingComputer: { op: null },
        saving: { op: null },
        success: { handle: null, message: null },
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingComputer.op) {
            this.state.loadingComputer.op.cancel();
        }

        if (this.state.saving.op) {
            this.state.saving.op.cancel();
        }

        clearTimeout(this.state.success.handle);
    }

    loadData () {
        let loadingComputer = new Cancelable(this.props.backend.getComputer(this.props.guid));

        loadingComputer
            .then(response => {
                this.setState({
                    loadingComputer: { op: null },
                    original: { ...response.data },
                    computer: response.data,
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingComputer: { op: null, error: err }});
            });

        this.setState({
            loadingComputer: { op: loadingComputer },
        });
    }

    onChangeField (field, event) {
        let item = Object.assign({}, this.state.computer);

        if (ValidationRules[field] && ValidationRules[field].validate) {
            ValidationRules[field].validate(event.target);
        }

        if (event && event.target) {
            item[field] = event.target.value;
        } else if (field === 'organization') {
            item[field] = event.dn;
        } else {
            item[field] = event;
        }

        const difference = getDifference(this.state.original, item);

        this.setState({
            computer: item,
            hasChanged: Object.keys(difference).length > 0
        });
    }

    box (prop, value) {
        return (
            <div key={prop.name} className="form-group row">
                <label className="col-sm-3 col-form-label">{prop.name}</label>
                <div className="col-sm-9">
                    {prop.control && prop.control(prop.read, prop.write)}

                    {!prop.control &&
                        <input type="text" className="form-control" value={value || ''}
                               onChange={this.onChangeField.bind(this, prop.field)}
                               readOnly={prop.read && !prop.write}
                        />
                    }

                    <small className="form-text text-muted">
                        {ValidationRules[prop.field] && ValidationRules[prop.field].hint}
                    </small>
                </div>
            </div>
        );
    }

    save () {
        let difference = getDifference(this.state.original, this.state.computer);

        let saving = this.props.backend.modifyComputer(this.props.guid, difference)
            .then(response => {
                let original = Object.assign({}, this.state.original);

                for (let prop in response.data.changes) {
                    original[prop] = response.data.changes[prop];
                }

                this.setState({
                    saving: { op: null },
                    original,
                    computer: original,
                    hasChanged: false,
                    success: {
                        handle: setTimeout(() => this.setState({ success: { message: null, handle: null } }), 4000),
                        message: 'Computer sucessfully saved',
                    },
                });

            })
            .catch(err => {
                console.log(err);

                if (err.response && err.response.status === 403) {
                    err = new Error('You are not allowed to change this field.');
                }

                this.setState({ saving: { op: null, error: err } });
            });

        this.setState({ saving: { op: saving }, success: null });
    }

    render () {
        let saveBtn = null;
        let viewFields = [];
        let editFields = [];

        if (!this.state.saving.op) {
            if (this.state.saving.op) {
                saveBtn = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
            } else {
                saveBtn = (
                    <button className="btn btn-outline-success btn-sm"
                            disabled={!this.state.hasChanged} onClick={this.save.bind(this)}>
                        Save
                    </button>
                );
            }
        }

        if (this.props.permissionManager.isAllowedOrganization('computers_list', this.state.computer.organization)) {
            viewFields = this.props.permissionManager.getAllowedFields('computers_list');
        }

        if (this.props.permissionManager.isAllowedOrganization('computers_edit', this.state.computer.organization)) {
            editFields = this.props.permissionManager.getAllowedFields('computers_edit');
        }

        const organizations = this.props.permissionManager.getAllowedOrganizations('computers_edit');
        const organizationControl = (read, write) => (
            <Select
                options={organizations}
                value={organizations.find(o => o.dn === this.state.computer.organization)}
                onChange={this.onChangeField.bind(this, 'organization')}
                getOptionValue={o => o.dn} getOptionLabel={o => o.name}
                isDisabled={read && !write} isClearable placeholder="Select organization..."
            />
        );

        let fields = [];
        if (this.props.fields) {
            for (const field of this.props.fields) {
                switch (field.field) {
                    case 'organization':
                        field.control = organizationControl;
                        break;

                    default: break;
                }

                if (viewFields.includes(field.field)) {
                    field.read = true;
                }

                if (editFields.includes(field.field)) {
                    field.write = field.read = true;
                }

                if (field.read) {
                    fields.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingComputer, this.state.saving ]}

                    left={() => {
                        return (
                            <Fragment>
                                <Link to="/computers" className="btn btn-outline-secondary btn-sm">Back</Link>
                                <Link to={'/computers/' + this.props.guid} className="btn btn-outline-secondary btn-sm ml-1">View</Link>
                            </Fragment>
                        );
                    }}

                    middle={() => (
                        <AlertMessageDialog
                            success={[ this.state.success.message ]}
                        />
                    )}

                    right={() => {
                        if (!this.state.computer) {
                            return null;
                        }

                        return (
                            <Fragment>
                                {saveBtn}
                            </Fragment>
                        )
                    }}
                />

                {this.state.computer &&
                    <form className="offset-md-2 col-sm-8 mt-3 needs-validation">
                        {fields.map(prop => {
                            let value = this.state.computer[prop.field];

                            switch (prop.field) {
                            case 'organization':
                                const org = organizations.find(o => o.dn === value);
                                if (org) {
                                    value = org.label;
                                }
                                break;

                            default: break;
                            }


                            return this.box(prop, value);
                        })}
                        {this.props.fields && fields.length === 0 &&
                            <p className="text-center">No fields are allowed to be edited.</p>
                        }
                    </form>
                }
            </div>
        );
    }
}
