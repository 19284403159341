import React, { Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import Select, { Creatable, createFilter } from 'react-select';
import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import NoUserKeyDialog from './NoUserKeyDialog.js';
import AlertMessageDialog from '../AlertMessageDialog.js';

import { encryptPassword } from './crypto_helper.js';

export const PASSWORD_SUBFIELD_TYPES = [
    { name: 'Text', type: 'text' },
    { name: 'Password', type: 'password' },
    { name: 'URL', type: 'url' },
    { name: 'Email', type: 'email' },
    { name: 'One-Time Password', type: 'otp' },
    { name: 'Phone', type: 'phone' },
];

/*
Password content definition:


{
	fields: [
		{
			id: xx
			type: 'text',
			label: 'My Field Name'
			value: 'My field value'
		}
	]
}

*/


var content_id = 1;

class NewPassword extends React.Component {
	original_users = []
    serverKey = null

	state = {
		password: this.newPassword(),

		keys: [],
		selectedType: null,
		edit_field: null,

		users: [],
		selectedUser: null,

		selectedUsers: [],

		loading_keys: { op: null, error: null },
		loading_users: { op: null, error: null },
        loading_serverkey: { op: null, error: null },
		saving: { op: null, error: null },
	}

    newPassword () {
        return {
            name: null,
            description: null,
            content: [
                { id: content_id++, type: 'text', label: 'Username', value: '' },
                { id: content_id++, type: 'password', label: 'Password', value: '' },
            ],
            labels: [],
            options: {
                recoverable: false,
            },
        };
    }

	componentDidMount () {
		this.loadData();
	}

	loadData () {
		let loading_keys = new Cancelable(this.props.backend.getMyKeys());

		loading_keys
			.then((response) => {
				this.setState({ keys: response.data.keys, loading_keys: { op: null, error: null }});
			})
			.catch((e) => {
                if (e && e.response) {
					// keys not found -> show message
					if (e.response.status === 404) {
						this.setState({ loading_keys: { op: null, error: null }, keys: '' });
						return;
					}
				}

				this.setState({ loading_keys: { op: null, error: e }})
			})

		let loading_users = new Cancelable(this.props.backend.getUsers());

        loading_users
            .then((response) => {
                let users = response.data.users.filter(u => !u.locked && !!u.active_key)

                users.sort((a, b) => {
                    return a.fullname.localeCompare(b.fullname);
                });

                this.original_users = users;
                this.filterUsers();

                this.setState({ loading_users: { op: null, error: null } });
            })
            .catch((e) => {
                this.setState({ loading_users: { op: null, error: e }});
            });

        let loading_serverkey = new Cancelable(this.props.backend.getServerPublicKey());

        loading_serverkey
            .then(response => {
                this.serverKey = response.data.key;

                this.setState({ loading_serverkey: { op: null, error: null }});
            })
            .catch(e => {
                this.setState({ loading_serverkey: { op: null, error: e }});
            });

        this.setState({
            loading_keys: { op: loading_keys, error: null },
            loading_users: { op: loading_users, error: null },
            loading_serverkey: { op: loading_serverkey, error: null },
        });
    }

    componentWillUnmount () {
        if (this.state.loading_keys.op) {
            this.state.loading_keys.op.cancel();
        }

        if (this.state.loading_users.op) {
            this.state.loading_users.op.cancel();
        }
    }

    filterUsers (selected_users) {
        let user = this.props.backend.loggedInUser;

        // It may happen that users are already loaded, but not our own
        // Just delay the filter action by 100ms
        if (!user) {
            setTimeout(this.filterUsers.bind(this, selected_users), 100);
            return;
        }

		selected_users = selected_users || this.state.selectedUsers;
		let users = this.original_users.filter((u) => selected_users.indexOf(u) === -1 && (user && u.guid !== user.guid));

		this.setState({ users: users });
	}

	isValidPassword () {
		if (!this.state.password.name ||
			this.state.password.name.length === 0 ||
			this.state.password.name.length > 50) {
			return false;
		}

		if (this.state.password.content.length === 0) {
			return false;
		}

		return true;
	}

    getActiveUserKey () {
        let user = this.props.backend.loggedInUser;

        if (user) {
            return this.state.keys.find(k => k.id === user.active_key);
        }
    }

	onChange (field, e) {
		let item = Object.assign({}, this.state.password);

        item[field] = e.target.value;

        this.setState({ password: item });
	}

	onChangeType (type) {
		this.setState({ selectedType: type });
	}

    onChangeOption (e) {
        let password = Object.assign({}, this.state.password);

        password.options[e.target.name] = e.target.checked;

        this.setState({ password });
    }

	onEditLabel (field) {
		this.setState({ edit_field: field });
	}

	onBlurLabel () {
		this.setState({ edit_field: null });
	}

    onOpenUrl (field) {
        window.open(field.value, '_blank');
    }

	onChangeSelectedUser (user) {
		this.setState({ selectedUser: user });
	}

    addSelectedUser () {
        if (!this.state.selectedUser) {
            return;
        }

		let selectedUsers = this.state.selectedUsers.slice();
		selectedUsers.push(this.state.selectedUser);

		selectedUsers.sort((a, b) => {
			return a.fullname.localeCompare(b.fullname);
		});

		this.setState({ selectedUser: null, selectedUsers });

		this.filterUsers(selectedUsers);
	}

	removeUser (user) {
		let selectedUsers = this.state.selectedUsers.slice();
		let idx = selectedUsers.indexOf(user);

		selectedUsers.splice(idx, 1);

		selectedUsers.sort((a, b) => {
			return a.fullname.localeCompare(b.fullname);
		});

		this.setState({ selectedUser: null, selectedUsers });

		this.filterUsers(selectedUsers);
	}

	onChangeLabel (field, e) {
		let password = Object.assign({}, this.state.password);
		password.content = password.content.slice();

		let idx = password.content.indexOf(field);
		let new_field = Object.assign({}, field);
		new_field.label = e.target.value;

		password.content.splice(idx, 1, new_field);

		this.setState({ edit_field: new_field, password: password });
	}

    onDragStartField (index, e) {
        e.dataTransfer.setData('index', index);
    }

    onDropField (toIndex, e) {
        e.preventDefault();

        let content = this.state.password.content.slice();

        const fromIndex = parseInt(e.dataTransfer.getData('index'));
        const element = content[fromIndex];

        const rect = e.target.getBoundingClientRect();
        const threshold = rect.y + (rect.height / 2);
        const offset = (e.clientY - threshold) > 0 ? 1 : 0;

        content.splice(fromIndex, 1); // Remove element from old position
        content.splice(toIndex + offset, 0, element); // and insert at new position

        let password = Object.assign({}, this.state.password);
        password.content = content;

        this.setState({ password });
    }

	onChangeValue (field, e) {
		let password = Object.assign({}, this.state.password);
		password.content = password.content.slice();

		let idx = password.content.indexOf(field);
		let new_field = Object.assign({}, field);
		new_field.value = e.target.value;

		password.content.splice(idx, 1, new_field);

		this.setState({ password: password });
	}

	addType (e) {
		e.preventDefault();

		if (!this.state.selectedType) {
			return; // TODO: show error?
		}

		let field = { id: content_id++, type: this.state.selectedType.type, label: this.state.selectedType.name, value: '' };

		let password = Object.assign({}, this.state.password);
		password.content = password.content.slice();
		password.content.push(field);

		this.setState({ password: password, selectedType: null });
	}

	removeField (field, e) {
		let password = Object.assign({}, this.state.password);
		password.content = password.content.slice();

		let idx = password.content.indexOf(field);

		password.content.splice(idx, 1);

		this.setState({ password: password });
	}

    onChangePasswordLabels (labels) {
        let password = Object.assign({}, this.state.password);
        password.labels = labels;

        this.setState({ password });
    }

    onCreateNewPasswordLabel (title) {
        let password = Object.assign({}, this.state.password);
        password.labels = password.labels.concat({ title });

        this.setState({ password });
    }

    async save (e) {
        e.preventDefault();

        try {
            let password = Object.assign({}, this.state.password);

            // If the user leaves the description field empty, it becomes null. Set it to empty string.
            if (!password.description) {
                password.description = '';
            }

            await encryptPassword({
                password,
                backend: this.props.backend,
                users: this.state.selectedUsers,
                serverKey: this.serverKey,
            });

            const response = await this.props.backend.createPassword(password);

            this.setState({ saving: { op: null, error: null }});
            this.props.onSuccess('Successfully created new password', response.data);
            this.props.history.goBack();
        } catch (e) {
            console.log(e);
            this.setState({ saving: { op: null, error: e }});
        }
	}

	render () {
		let item = this.state.password;
		let content = null;
		let saveButton = null;

        // Only show message if there are no keys and loading is finished
        if (this.state.keys.length && !this.state.loading_keys.op) {
            let activeKey = this.getActiveUserKey();
            let saveBtnText = "Encrypt with key '" + (activeKey && activeKey.name) + "'";

            saveButton = (
                <Fragment>
                    <button className="btn btn-outline-success" onClick={this.save.bind(this)} disabled={!this.isValidPassword()}>Save</button>
                    <small className="form-text text-muted">{saveBtnText}</small>
                </Fragment>
            );

			content = (
				<Fragment>
					<form className="offset-md-2 col-sm-8 mt-3">
						<h5>New Password</h5>

	                	<div key="name" className="form-group row">
			                <label className="col-sm-3 col-form-label">Name</label>
			                <div className="col-sm-9">
			                    <input type="text" className={"form-control "} onChange={this.onChange.bind(this, "name")} value={this.state.password.name || ''} />
			                </div>
			            </div>

                        {this.serverKey && /* Currently only one option available */
                            <div key="options" className="form-group row">
                                <label className="col-sm-3 col-form-label">Options</label>
                                <div className="col-sm-9 password-options">
                                    <ul>
                                        <li>
                                            {this.serverKey && /* Only make option available if the server has a public key defined to encrypt with */
                                                <Fragment>
                                                    <input type="checkbox" className="form-check-input" name="recoverable" id="password-option-recoverable"
                                                           onChange={this.onChangeOption.bind(this)} checked={this.state.password.options.recoverable}
                                                    />
                                                    <label className="form-check-label" htmlFor="password-option-recoverable">Password recoverable by admin</label>
                                                </Fragment>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }

			            <div key="description" className="form-group row">
			                <label className="col-sm-3 col-form-label">Description</label>
			                <div className="col-sm-9">
			                    <textarea className={"form-control "} onChange={this.onChange.bind(this, "description")} value={this.state.password.description || ''} />
			                </div>
			            </div>

                        <div key="labels" className="form-group row">
                            <label className="col-sm-3 col-form-label">Labels</label>
                            <div className="col-sm-9">
                                <Creatable
                                    isClearable isMulti placeholder="Select labels ..."
                                    options={this.props.labels} getOptionLabel={o => o.title} getOptionValue={o => o.id || o.title}
                                    value={this.state.password.labels} onChange={this.onChangePasswordLabels.bind(this)}
                                    isValidNewOption={s => s.length > 0}
                                    getNewOptionData={(title, label) => ({title: label})}
                                    onCreateOption={this.onCreateNewPasswordLabel.bind(this)}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </div>
                        </div>

                        <div className="form-group row flex-row justify-content-end">
                           <div className="d-table-cell col-sm-6 ml-3">
                                <Select
                                    options={PASSWORD_SUBFIELD_TYPES} getOptionLabel={o => o.name} getOptionValue={o => o.id} isClearable
                                    value={this.state.selectedType} onChange={this.onChangeType.bind(this)} filterOption={createFilter({ ignoreAccents: false })}
                                    onKeyDown={e => (e.keyCode === 13) && this.addType(e)}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </div>
                            <div className="d-table-cell mr-3">
                                <button className="btn btn-outline-success" onClick={this.addType.bind(this)} disabled={this.state.selectedType === null}>Add</button>
                            </div>
                        </div>

			            {this.state.password.content.map((field, index) => {
			            	let label = null;
			            	let input = null;
                            let additionalInput = null;

                            if (field === this.state.edit_field) {
                                label = <input
                                    type="text" className="col-form-label form-control col-sm-3" onChange={this.onChangeLabel.bind(this, field)}
                                    onBlur={this.onBlurLabel.bind(this)} value={field.label || ''}
                                />;
                            } else {
                                label = (
                                    <label className="col-form-label col-sm-3" onClick={this.onEditLabel.bind(this, field)} draggable="true"
                                           onDragStart={this.onDragStartField.bind(this, index)}
                                           onDragOver={e => e.preventDefault()}
                                           onDrop={this.onDropField.bind(this, index)}>
                                        {field.label}
                                    </label>
                                );
                            }

                            switch (field.type) {
                            case 'text':
                            case 'email':
                                input = <input type="text" className="form-control" onChange={this.onChangeValue.bind(this, field)} value={field.value || ''} autoComplete="off" />
                                break;

                            case 'url':
                                input = <input type="url" className="form-control" onChange={this.onChangeValue.bind(this, field)} value={field.value || ''} autoComplete="off" />
                                additionalInput = (
                                    <button type="button" className="btn btn-outline-secondary" tabIndex="-1" onClick={this.onOpenUrl.bind(this, field)}>
                                        <i className="fas fa-external-link-alt"></i>
                                    </button>
                                );
                                break;

                            case 'password':
                                input = (
                                    <input type="password" className="form-control" onChange={this.onChangeValue.bind(this, field)}
                                           value={field.value || ''} autoComplete="new-password" />
                                );
                                break;

                            default:
                                input = <div className="form-control-plaintext">Not Implemented!</div>
                                break;
                            }

			            	return (
			            		<div key={field.id} className="form-group row">
					                {label}
					                <div className="input-group col-sm-9">
					                	{input}
						                <div className="input-group-append">
                                            {additionalInput}
                                            <button type="button" className="btn btn-outline-danger" tabIndex="-1" onClick={this.removeField.bind(this, field)}>
                                                <i className="fas fa-times"></i>
                                            </button>
						            	</div>
						            </div>
					            </div>
			            	)
			            })}
	                </form>
	                <div className="offset-md-2 col-sm-8 mt-3">
	                	<h5>Users with Access</h5>
                        <div key="users" className="form-group row flex-row justify-content-end">
                            <div className="d-table-cell col-sm-6 ml-3">
                                <Select
                                    options={this.state.users} getOptionLabel={o => o.fullname} getOptionValue={o => o.dn} isClearable
                                    value={this.state.selectedUser} onChange={this.onChangeSelectedUser.bind(this)}
                                    onKeyDown={e => (e.keyCode === 13) && this.addSelectedUser()}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </div>
                            <div className="d-table-cell mr-3">
                                <button className="btn btn-outline-success" onClick={this.addSelectedUser.bind(this)} disabled={this.state.selectedUser === null}>Add</button>
                            </div>
                        </div>

			            {this.state.selectedUsers.map((user) => {
			            	return (
			            		<div key={user.guid} className="form-group row">
			            			<div className="d-table-cell offset-sm-3 col-sm-8">
					                	{user.fullname}
					                </div>
					                <div className="d-table-cell mr-3">
						            	<button className="btn btn-outline-danger" onClick={this.removeUser.bind(this, user)}>X</button>
					            	</div>
			            		</div>
			            	);
			            })}
	                </div>
	            </Fragment>
			)
		}

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loading_users, this.state.loading_keys, this.state.loading_serverkey ]}

                    left={() => {
                        return <Link to={'/passwords'} className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    middle={() => {
                        return <AlertMessageDialog errors={[this.state.saving.error]} />;
                    }}

                    right={() => {
                        if (!item) {
                            return null;
                        }

                        return (
                            <Fragment>
                                {saveButton}
                            </Fragment>
                        )
                    }}
                />
                <NoUserKeyDialog loader={this.state.loading_keys} keys={this.state.keys} />

                {content}
            </div>
        );
    }
}

export default withRouter(NewPassword);
