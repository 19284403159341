import EventEmitter from './eventemitter.js';
import axios from 'axios';

import { organizationDnToPath } from './helper.js';

export default class Backend extends EventEmitter {
    _authorization: null;
    _loggedInUser: null;

    constructor (config) {
        super();

        this._waitingOnAuthorized = [];
        this.baseUrl = config.url;
        this.sso = { config: config.ssoConfig };
        this.organizations = [];

        this.instance = axios.create({
            baseURL: config.url,
            timeout: 10000,
            headers: { 'Content-Type': 'application/json' },
        });

        // TODO: make a long running connection to detect server side failures (?)

        if (localStorage.getItem('authorization')) {
            this.authorization = localStorage.getItem('authorization');
        } else {
            // We might got here from SSO authentication, so try to retrieve the jwt token
            // from the backend
            this.checkAuthToken()
                .then(token => this.authorization = token);
        }
    }

    hasPermission (right) {
        if (!this.loggedInUser) {
            return false;
        }

        if (!(right in this.loggedInUser.permissions)) {
            return false;
        }

        const perm = this.loggedInUser.permissions[right];
        return perm.permission;
    }

    isAllowedOrganization (right, organization) {
        if (!this.loggedInUser) {
            return false;
        }

        if (!(right in this.loggedInUser.permissions)) {
            return false;
        }

        let perm = this.loggedInUser.permissions[right];

        if (perm.additional_settings &&
            perm.additional_settings.organizations &&
            perm.additional_settings.organizations.indexOf(organization) === -1) {
            return false;
        }

        return perm.permission;
    }

    getAllowedOrganizations (right) {
        if (!this.loggedInUser) {
            return [];
        }

        if (!(right in this.loggedInUser.permissions)) {
            return [];
        }

        let perm = this.loggedInUser.permissions[right];

        if (perm.additional_settings &&
            perm.additional_settings.organizations) {

            return this.organizations
                .filter(o => perm.additional_settings.organizations.includes(o.dn));
        }

        return [];
    }

    getAllowedFields (right) {
        if (!this.loggedInUser) {
            return [];
        }

        if (!(right in this.loggedInUser.permissions)) {
            return [];
        }

        let perm = this.loggedInUser.permissions[right];

        if (perm.additional_settings &&
            perm.additional_settings.fields) {

            if (Array.isArray(perm.additional_settings.fields)) {
                return perm.additional_settings.fields;
            } else {
                return [];
            }
        }

        return [];
    }

    isAllowedGroup (group, right) {
        if (!this.loggedInUser || !(right in this.loggedInUser.permissions)) {
            return false;
        }

        let perm = this.loggedInUser.permissions[right];

        if (perm.additional_settings &&
            perm.additional_settings.groups &&
            perm.additional_settings.groups.indexOf(group) === -1) {

            return false;
        }

        return perm.permission;
    }

    async waitAuthorized() {
        return new Promise((resolve, reject) => {
            if (this.loggedInUser != null) {
                return resolve();
            }

            this._waitingOnAuthorized.push(resolve);
        });
    }


    get loggedInUser() {
        return this._loggedInUser;
    }

    set loggedInUser (value) {
        this._loggedInUser = value;
    }

    get authorization () {
        return this._authorization;
    }

    set authorization (value) {
        this._authorization = value;
        this.instance.defaults.headers.Authorization = value;

        if (value === null) {
            console.log('logged out user because set null');
            this.loggedInUser = null;
            localStorage.removeItem('authorization');
            this.emit('loggedin', false, null);
        } else {
            if (this.loggedInUser) {
                console.log('user logged in');
                localStorage.setItem('authorization', value);
                this.emit('loggedin', true, this.loggedInUser);
            } else {
                console.log('loading user');
                this.me()
                    .then(() => {
                        this.emit('connected', true);
                        localStorage.setItem('authorization', value);
                        this.emit('loggedin', true, this.loggedInUser);
                    })
                    .catch((err) => {
                        if (err.toString() === 'Error: Network Error') {
                            this.emit('connected', false);
                        } else {
                            console.log(err, err.code);
                            this.emit('connected', true);
                            this.emit('loggedin', false, null);

                            if (err.toString() === 'Error: Request failed with status code 401') {
                                // we got back a 401 Unauthroized from the me endpoint -> remove any authorization
                                console.log('Removed authorization from local storage');
                                localStorage.removeItem('authorization')
                                console.log('Local storage authorization: ', localStorage.getItem('authorization'))
                            }
                        }
                    });
            }
        }
    }

    async me () {
        let response = await this.instance.get('/me');

        let user = response.data.user;
        user.permissions = response.data.user_permissions;

        this.loggedInUser = user;
        this.permissions = response.data.permissions;
        this.fields = response.data.fields;

        this.sso = { ...this.sso, ...response.data.sso };
        this.emit('sso-config', this.sso);

        let organizations = await this.getOrganizations();
        organizations = organizations.data.organizations;
        organizations.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        organizations.forEach(o => o.dnPath = organizationDnToPath(o.dn));

        this.organizations = organizations;

        if (this._waitingOnAuthorized) {
            let localWait = this._waitingOnAuthorized;
            this._waitingOnAuthorized = [];

            for (let cb of localWait) {
                cb();
            }
        }

        return response;
    }

    async checkAuthToken () {
        // After the SSO authentication succeeds, the backend sets a 'AuthToken'
        // cookie on its own domain. Do get this JWT token, we issue a request to the
        // server, which will then check the cookies set in the request header and
        // return the JWT authentication token if it is indeed set on the backend domain.

        return this.instance.get('/login', { withCredentials: true })
            .then(response => response && response.data && response.data.token)
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    console.log('No AuthToken cookie set');
                } else {
                    console.log(err);
                }
            });
    }

    async logout () {
        this.authorization = null;
    }

    /* User management */

    async lockUser (guid) {
        return this.post('/users/' + guid + '/lock');
    }

    async unlockUser (guid) {
        return this.post('/users/' + guid + '/unlock');
    }

    async resetTfaForUser (guid) {
        return this.post('/users/' + guid + '/reset-security-method/2fa');
    }

    async resetU2fForUser (guid) {
        return this.post('/users/' + guid + '/reset-security-method/u2f');
    }

    async getOrganizations () {
        return this.get('/organizations');
    }

    async getPermissions (guid) {
        return this.get('/users/' + guid + '/permissions');
    }

    async createPermission (guid, permission) {
        return this.post('/users/' + guid + '/permissions', permission);
    }

    async updatePermission (guid, permission_id, permission) {
        return this.put('/users/' + guid + '/permissions/' + permission_id, permission);
    }

    async deletePermission (guid, permission_id) {
        return this.delete('/users/' + guid + '/permissions/' + permission_id);
    }

    async getApplications (guid) {
        return this.get('/users/' + guid + '/applications');
    }

    async setApplication (guid, application_id, data) {
        return this.put('/users/' + guid + '/applications/' + application_id, data);
    }

    async getUsers (fields) {
        let query = (fields ? '?fields=' + fields.join(',') : '');

        return this.get('/users' + query);
    }

    async getUser (guid) {
        return this.get('/users/' + guid);
    }

    async getUserLogins (guid, limit) {
        return this.get('/users/' + guid + '/logins/' + (limit || 10));
    }

    async getUserKnownDevices (guid) {
        return this.get('/users/' + guid + '/known-devices');
    }

    async deleteUserKnownDevice (guid, deviceId) {
        return this.delete('/users/' + guid + '/known-devices/' + deviceId);
    }

    async getUserGroups (guid) {
        return this.get('/users/' + guid + '/groups');
    }

    async createUser (user) {
        return this.post('/users', user);
    }

    async putUser (guid, changes) {
        return this.put('/users/' + guid, changes);
    }

    async addUserToGroup (guid, group) {
        return this.post('/users/' + guid + '/groups/' + group);
    }

    async removeUserFromGroup (guid, group) {
        return this.delete('/users/' + guid + '/groups/' + group);
    }

    async changeMyPassword (password, new_password) {
        return this.post('/me/changepassword', { password, new_password });
    }

    /* 2FA */

    async setupMyTwoFactorAuth () {
        return this.post('/me/2fa/setup');
    }

    async verifyMyTwoFactorAuth (token) {
        return this.put('/me/2fa/setup', { token });
    }

    async disableMyTwoFactorAuth () {
        return this.delete('/me/2fa/setup');
    }

    /* U2F */

    async disableMyU2fAuth () {
        return this.delete('/me/u2f/setup');
    }

    /* Computer Management */

    async getComputers () {
        return this.get('/computers');
    }

    async getComputer (guid) {
        return this.get('/computers/' + guid);
    }

    async modifyComputer (guid, changes) {
        return this.put('/computers/' + guid, changes);
    }

    async createComputer (data) {
        return this.post('/computers', data);
    }

    /* Group Management */

    async getGroups () {
        return this.get('/groups');
    }

    async getGroup (guid) {
        return this.get('/groups/' + guid);
    }

    async getGroupMembers (guid) {
        return this.get('/groups/' + guid + '/members');
    }

    async modifyGroup (guid, changes) {
        return this.put('/groups/' + guid, changes);
    }

    async createGroup (data) {
        return this.post('/groups', data);
    }

    async getGroupTypes () {
        return this.get('/groups/types');
    }

    /* Roles Management */

    async getRoles () {
        return this.get('/roles');
    }

    async getRole (id) {
        return this.get('/roles/' + id);
    }

    async getRoleMembers (id) {
        return this.get('/roles/' + id + '/members')
    }

    async putRole (id, changes) {
        return this.put('/roles/' + id, changes);
    }

    async deleteRole (id) {
        return this.delete('/roles/' + id);
    }

    async createRole(data) {
        return this.post('/roles', data);
    }

    async addUserToRole(id, guid) {
        return this.post('/roles/' + id + '/members/' + guid);
    }

    async removeUserFromRole(id, guid) {
        return this.delete('/roles/' + id + '/members/' + guid);
    }

    async getRolePermissions (id) {
        return this.get('/roles/' + id + '/permissions');
    }

    async createRolePermission (id, permission) {
        return this.post('/roles/' + id + '/permissions', permission);
    }

    async updateRolePermission (id, permission_id, permission) {
        return this.put('/roles/' + id + '/permissions/' + permission_id, permission);
    }

    async deleteRolePermission (id, permission_id) {
        return this.delete('/roles/' + id + '/permissions/' + permission_id);
    }


    /* Password Management */

    async getPasswords () {
        return this.get('/passwords');
    }

    async getPassword (id) {
        return this.get('/passwords/' + id);
    }

    async createPassword (password) {
        return this.post('/passwords', password);
    }

    async updatePassword (password) {
        return this.put('/passwords/' + password.id, password);
    }

    async addUserToPassword (password, guid, data) {
        return this.post('/passwords/' + password + '/users/' + guid, data);
    }

    async removeUserFromPassword (password, guid) {
        return this.delete('/passwords/' + password + '/users/' + guid);
    }

    async deletePassword (password) {
        return this.delete('/passwords/' + password);
    }

    async getPasswordLabels () {
        return this.get('/passwords/labels');
    }

    async getMyKeys () {
        return this.get('/me/keys');
    }

    async postMyKeys (data) {
        return this.post('/me/keys', data);
    }

    async getPublicKey (guid) {
        return this.get('/users/' + guid + '/keys/public');
    }

    async getServerPublicKey () {
        return this.get('/server/keys/public');
    }

    async getMyKeyForPassword (passwordId) {
        return this.get('/me/keys/password/' + passwordId);
    }

    async setActiveKey (keyId) {
        return this.post('/me/keys/set-active/' + keyId);
    }

    /* Inventory Management */

    async getCustomInventoryDevices () {
        return this.get('/devices/custom');
    }

    async createCustomInventoryDevice (data) {
        return this.post('/devices/custom', data);
    }

    async deleteCustomInventoryDevice (id) {
        return this.delete('/devices/custom/' + id);
    }

    /* NFC Management */

    async getNfcTags() {
        return this.get('/nfc/tags');
    }

    async getNfcUsers() {
        return this.get('/nfc/users');
    }

    async getDoors() {
        return this.get('/nfc/doors');
    }

    async getAccessList(filter) {
        let query = '';

        if (filter && filter.user_id && filter.user_id.length > 0) {
            query += '&user_id=' + filter.user_id.join(',');
        }

        if (filter && filter.door_id && filter.door_id.length > 0) {
            query += '&door_id=' + filter.door_id.join(',');
        }

        if (filter && filter.nfctag_id && filter.nfctag_id.length > 0) {
            query += '&nfctag_id=' + filter.nfctag_id.join(',');
        }

        if (filter && filter.from && filter.from.length > 0) {
            query += '&from=' + filter.from;
        }

        if (filter && filter.to && filter.to.length > 0) {
            query += '&to=' + filter.to;
        }

        if (query[0] === '&') {
            query = '?' + query.substring(1);
        }

        return this.get('/nfc/accesslist' + query);
    }

    async get(url) {
        return this.requestData(url, 'get');
    }

    async put(url, data) {
        return this.requestData(url, 'put', data);
    }

    async post(url, data) {
        return this.requestData(url, 'post', data);
    }

    async delete(url) {
        return this.requestData(url, 'delete');
    }

    async requestData (url, method = 'get', data = null) {
        try {
            let response = await this.instance[method](url, data);

            return response;
        } catch (e) {
            // Unauthorized
            if (e.response && e.response.status === 401) {
                // we are unauthenticated
                this.authorization = null;
                return null;
            }

            throw e;
        }
    }


}
