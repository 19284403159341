import xkpasswd from 'xkpasswd/generate';

export default {
    // https://docs.microsoft.com/en-us/windows/desktop/adschema/a-samaccountname
    'windows_username': {
        hint: 'Must be 20 characters or less and not contain any of: "/\\[]:;|=,+*?<>',
        validate: validateWindowsUsername,
    },

    'password': {
        hint: 'The password must be at least 8 characters long ' +
            'and contain at least a lowercase letter, ' +
            'an uppercase letter, a number and a special character (one of @$!%*?&-)',
        validate: validateUserPassword,
        generate: generateUserPassword,
    },

    'gid_number': {
        hint: 'Must be a number > 1000',
        validate: el => validateNumber(el, 1001),
    },

    'uid_number': {
        hint: 'Must be a number > 1000',
        validate: el => validateNumber(el, 1001),
    },

    'group_type': {
        hint: 'If the \'Security\' attribute is not set, the group is created as a distribution group',
    },
};


function validateElement(el, pattern, validityText) {
    // Simple validating of string
    if (typeof el === 'string') {
        return pattern.test(el);
    }

    let valid = pattern.test(el.value);
    if (!valid) {
        el.setCustomValidity(validityText);
        el.parentNode.classList.add('was-validated', 'validation-has-error');
    } else {
        el.setCustomValidity('');
        el.parentNode.classList.remove('was-validated', 'validation-has-error');
    }

    return valid;
}

function validateNumber (el, min, max) {
    if (el.value && isNaN(parseInt(el.value))) {
        el.setCustomValidity('Value is not a number');
        el.parentNode.classList.add('was-validated', 'validation-has-error');
    } else if ((min && el.value < min) || (max && el.value > max)) {
        el.setCustomValidity('Value is not in range');
        el.parentNode.classList.add('was-validated', 'validation-has-error');
    } else {
        el.setCustomValidity('');
        el.parentNode.classList.remove('was-validated', 'validation-has-error');
    }
}

/*
 * Validates the passed sAMAccountName according to the rules specified here:
 *  - https://ldapwiki.com/wiki/SamAccountName#
 *  - https://docs.microsoft.com/en-us/windows/desktop/adschema/a-samaccountname
 *
 * tl;dr
 * sAMAccountName must not contain any of these characters: "/\[]:;|=,+*?<>
 * and can must be 20 characters or less long
 *
 * `el` must be either a string to perform simple validation, or it can be a form
 * element to set the form validity and apprioriate bootstrap classes (was-validated).
 *
 * Returns true if it is valid, otherwise false
 */
function validateWindowsUsername (el) {
    // Regex taken from: https://ldapwiki.com/wiki/SamAccountName#section-SamAccountName-RegExForVerification2
    // Slightly modified to restrict to 20 charactes length

    const pattern = /^(?:(?:[^. "/\\[\]:|\\+=;?*<>,][^"/\\[\]:|\\+=;?*<>,]{0,18}[^. "/\\[\]:|\\+=;?*<>,])|[^."/\\[\]:|\\+=;?*<>,])$/;

    return validateElement(el, pattern, 'Invalid sAMAccountName value');
}

function validateUserPassword (el) {
    // https://stackoverflow.com/a/21456918
    // "Minimum eight characters, at least one uppercase letter,
    // one lowercase letter, one number and one special character"
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/g;

    return validateElement(el, pattern, 'Invalid user password');
}

function generateUserPassword (complexity) {
    let options = {
        separators: '-',
        transform: 'lowercase',
        complexity,
    };

    // Do not allow a to simple complexity for passwords
    if (!complexity || complexity < 3) {
        options.pattern = 'wswsdd';
    }

    // We capitalize the first letter of each word, so that uppercase letters are guaranteed included.
    let password = xkpasswd(options)
        .replace(/(^|-)(\w)/g, s => s.toUpperCase());

    return password;
}
