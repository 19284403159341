import Unaccent from './unaccent.js';


/* Determines the difference between the original and the passed object */
export function getDifference (original, updated) {
    let difference = {};

    for (let prop in original) {

        if (original[prop] !== updated[prop] && updated[prop] !== '') {
            difference[prop] = updated[prop];
        }
    }

    for (let prop in updated) {
        if (original[prop] !== updated[prop] && updated[prop] !== '') {
            difference[prop] = updated[prop];
        }
    }

    return difference;
}

export function filterAndSortData (data, filter, column, direction) {
    data = data.filter(item => {
        let result = true;

        for (var property in filter) {
            if (filter[property] !== null && filter[property] instanceof Array && filter[property].length > 0) {
                if (filter[property].indexOf(item[property].toString()) === -1) {
                    result = false;
                }
            } else if (filter[property] !== null && typeof filter[property] === 'object' && filter[property].exactMatch) {
                const value = Unaccent.unaccent((item[property] || '').toLowerCase());
                const search = Unaccent.unaccent(filter[property].value.toLowerCase());

                if (search.length > 0) {
                    result &= value === search;
                }
            } else if (typeof filter[property] === 'string') {
                const value = Unaccent.unaccent((item[property] || '').toLowerCase());
                const search = Unaccent.unaccent(filter[property].toLowerCase());

                result &= value.indexOf(search) !== -1;
            } else if (typeof filter[property] === 'boolean'){
                result &= item[property] === filter[property];
            }

            if (!result) {
                break;
            }
        }

        return result;
    });

    let dirValue = direction === 'asc' ? 1 : -1;

    data.sort((a, b) => {
        if (a[column] === null || !b[column] === null) {
            return dirValue;
        }

        if (b[column] === null || !a[column] === null) {
            return -dirValue;
        }

        return (a[column] || '').localeCompare(b[column] || '') * dirValue;
    });

    return data;
}

export function onCellHoverSelect (e) {
    let selection = window.getSelection();
    let range = document.createRange();
    range.selectNodeContents(e.target);
    selection.removeAllRanges();
    selection.addRange(range);
}

export function organizationDnToPath (dn) {
    let parts = dn.split(',')
        .map(part => part.split('='))
        .filter(part => part[0] !== 'dc');

    if (parts.length > 1) {
        parts = parts.slice(0, parts.length - 1);
    }

    return parts
        .map(part => part[1])
        .reverse()
        .join(' / ');
}

export function sortByOrganizationDn (objects) {
    objects.sort((a, b) => {
        a = a.dn.split(',').map(p => p.split('=')[1]);
        b = b.dn.split(',').map(p => p.split('=')[1]);

        let max = Math.max(a.length, b.length);
        while (max-- > 0) {
            const ap = a[max];
            const bp = b[max];

            if (!ap) { return -1; }
            if (!bp) { return 1; }

            const res = ap.localeCompare(bp);
            if (res !== 0) { return res; }
        }

        return 0;
    });

    objects.sort((a, b) => a.dnPath.localeCompare(b.dnPath));

    return objects;
}

export function formatObjectGuid (guid) {
    const parts = guid.match(/.{2}/g);

    const rawGuid = [
        parts[3],
        parts[2],
        parts[1],
        parts[0],
        '-',
        parts[5],
        parts[4],
        '-',
        parts[7],
        parts[6],
        '-',
        parts[8],
        parts[9],
        '-',
        ...parts.slice(10),
    ];

    return rawGuid.join('').toUpperCase();
}

export default {
    getDifference,
    filterAndSortData,
    onCellHoverSelect,
    organizationDnToPath,
    sortByOrganizationDn,
    formatObjectGuid,
};
