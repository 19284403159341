import React, { Fragment } from 'react';
import { Link, Route, Switch } from 'react-router-dom';

import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import AlertMessageDialog from '../AlertMessageDialog.js';
import Helper from '../helper.js';

export default class Roles extends React.Component {
    constructor (props) {
        super(props);

        this.original_result = [];

        this.state = {
            result: [],
            sortColumn: 'name',
            sortDirection: 'asc',

            success: null,

            /* set to error message if a click on a user is performed without the user_list permission */
            permError: null,

            loading_list: { op: null, error: null },
            loading_del: { op: null, error: null },
        };
    }

    static newFilter () {
        return {
            name: '',
        };
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        let loading_list = new Cancelable(this.props.backend.getRoles());

        loading_list
            .then(response => {
                this.original_result = response.data.roles;
                this.doFilter(this.props.filter);

                this.setState({ loading_list: { op: null, error: null }});
            })
            .catch(err => {
                this.setState({ loading_list: { op: null, error: err }});
            });

        this.setState({
            loading_list: { op: loading_list, error: null },
        });
    }

    componentWillUnmount () {
        if (this.state.loading_list.op) {
            this.state.loading_list.op.cancel();
        }
    }

    onNew (e) {
        this.props.history.push('/roles/new');
        e.preventDefault();
    }

    onEdit (item, e) {
        this.props.history.push('/roles/' + item.id);
        e.preventDefault();
    }

    displaySuccessMessage(message) {
        this.setState({ success: message });
        setTimeout(() => this.setState({ success: null }), 4000);
    }

	onDelete (item, e) {
		let loading_del = new Cancelable(this.props.backend.deleteRole(item.id));

		loading_del
			.then(() => {
				this.displaySuccessMessage('Deleted role');

				this.loadData()
				this.setState({ loading_del: { op: null, error: null }});
			})
			.catch(e => {
				this.setState({ loading_del: { op: null, error: e }});
			});

		this.setState({ loading_del: { op: loading_del }});

		e.preventDefault();
	}

    selectFilter (property, value, e) {
        e.preventDefault();

        let filter = Object.assign({}, this.props.filter);
        filter[property] = value;

        this.doFilter(filter);
    }

    filter (field, event) {
        let filter = Object.assign({}, this.props.filter);

        if (!event && field === 'organization') {
            // react-select will pass null if the search is cleared
            filter[field] = { value: '', exactMatch: true };
        } else if (typeof event === 'string') {
            filter[field] = event;
        } else if (event.value) {
            filter[field] = event.value;
        } else if (field === 'organization') {
            filter[field] = { value: event.dn, exactMatch: true };
        } else {
            filter[field] = event.target.value;
        }

        this.doFilter(filter);
    }

    selectSort (column) {
        if (column === this.state.sortColumn) {
            let direction = this.state.sortDirection === 'asc' ? 'desc' : 'asc';

            this.doFilter(this.props.filter, column, direction);
        } else {
            this.doFilter(this.props.filter, column, this.state.sortDirection);
        }
    }

    getEntries () {
        return this.state.result.map((item, index) => {
            let members = <Link to={'/roles/' + item.id + '/members'} className="btn btn-outline-secondary btn-sm">Members</Link>
            let permissions = <Link to={'/roles/' + item.id + '/permissions'} className="btn btn-outline-secondary btn-sm">Permissions</Link>
            let del = null;

            if (item &&
                this.props.permissionManager.hasPermission('roles_delete')) {
                del = <button onClick={this.onDelete.bind(this, item)} className="btn btn-outline-danger btn-sm">Delete</button>
            }

            const makeCell = value => (
                <span onMouseEnter={Helper.onCellHoverSelect}
                      onMouseLeave={() => window.getSelection().removeAllRanges()}>
                    {value}
                </span>
            );

            return (
                <Link to={'/roles/' + item.id} key={item.id}>
                    {makeCell(item.name)}
                    <span style={{textAlign: 'right'}}>
                    	{permissions}&nbsp;
                    	{members}&nbsp;
                        {del}
                    </span>
                </Link>
            )
        });
    }

    doFilter (filter, column, direction) {
        filter = filter || this.props.filter;
        column = column || this.state.sortColumn;
        direction = direction || this.state.sortDirection;

        this.setState({
            result: Helper.filterAndSortData(this.original_result, filter, column, direction),
            sortColumn: column,
            sortDirection: direction
        });

        this.props.setFilter(filter);
    }

    onClearFilter () {
        this.doFilter(Roles.newFilter());
    }

    render () {
        let new_item = null;

        if (this.props.permissionManager.hasPermission('roles_create')) {
            new_item = <button onClick={this.onNew.bind(this)} className="btn btn-outline-success btn-sm">New role</button>
        }

        let entries = this.getEntries();

        return (
            <div className="container">
                <Switch>
                    <Route exact path="/roles">
                        <Fragment>
                            <PageMenu
                                onReload={this.loadData.bind(this)}
                                loaders={[ this.state.loading_list, this.state.loading_del ]}

                                middle={() => {
                                    return <AlertMessageDialog
                                        success={[this.state.success]}
                                    />;
                                }}

                                right={() => {
                                    return (
                                        <Fragment>
                                            <span style={{padding: '1rem'}}>{this.state.result.length} of {this.original_result.length} records</span>
                                            {new_item}
                                        </Fragment>
                                    );
                                }}
                            />
                            <div className="table">
                                <div className="table-head">
                                    <div className="table-filter-header">
                                        <span>
                                            <input className="form-control" placeholder="Name" type="text" value={this.props.filter.name} onChange={this.filter.bind(this, 'name')} />
                                        </span>
                                        <span>
                                            <button type="button" className="btn btn-outline-secondary float-right" onClick={this.onClearFilter.bind(this)}>
                                                Clear
                                            </button>
                                        </span>
                                    </div>
                                    <div className="table-header">
                                        <span onClick={this.selectSort.bind(this, 'name')}>Name</span>
                                        <span className="actions">Actions</span>
                                    </div>
                                </div>
                                <div className="table-body">
                                    {entries}
                                </div>
                            </div>

                            <AlertMessageDialog sticky
                                errors={[this.state.permError]}
                            />
                        </Fragment>
                    </Route>
                </Switch>
            </div>
        )
    }
}