import React from 'react';

import PageMenu from './PageMenu.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import Cancelable from './cancelable.js';

export default class CustomInventoryDevices extends React.Component {
    state = {
        customDevices: [],

        newDevice: this.newCustomDevice(),

        loadingDevices: { op: null },
    }

    newCustomDevice () {
        return {
            name: '',
            mac_address: '',
            asset_tag: '',
        };
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        const loadingDevices = new Cancelable(this.props.backend.getCustomInventoryDevices());

        loadingDevices
            .then(response => {
                this.setState({
                    loadingDevices: { op: null },
                    customDevices: response.data.devices,
                });
            })
            .catch(e => {
                console.log(e);

                this.setState({ loadingDevices: {
                    op: null,
                    error: new Error('Failed to retrieve custom inventory devices'),
                }});
            });

        this.setState({
            loadingDevices,
        })
    }

    componentWillUnmount () {
        if (this.state.loadingDevices.op) {
            this.state.loadingDevices.op.cancel();
        }
    }

    onNewDeviceChange (field, e) {
        let device = Object.assign({}, this.state.newDevice);

        device[field] = e.target.value;

        this.setState({ newDevice: device });
    }

    onCreateCustomDevice (e) {
        e.preventDefault();

        let creating = new Cancelable(this.props.backend.createCustomInventoryDevice(this.state.newDevice));

        creating
            .then(response => {
                let devices = this.state.customDevices.slice();
                devices.push(response.data);

                this.setState({
                    customDevices: devices,
                    newDevice: this.newCustomDevice(),
                    loadingDevices: { op: null, success: 'Sucessfully created custom inventory device' },
                });
                setTimeout(() => this.setState({ loadingDevices: { op: null }}), 4000);
            })
            .catch(err => {
                console.log(err);

                this.setState({
                    loadingDevices: { op: null, error: err },
                });
            });

        this.setState({
            loadingDevices: { op: creating },
        });
    }

    onDeleteCustomDevice (dev, e) {
        e.preventDefault();

        let deleting = new Cancelable(this.props.backend.deleteCustomInventoryDevice(dev.id));

        deleting
            .then(response => {
                let devices = this.state.customDevices.slice();

                const idx = devices.findIndex(d => d.id === dev.id);
                devices.splice(idx, 1);

                this.setState({
                    customDevices: devices,
                    newDevice: this.newCustomDevice(),
                    loadingDevices: { op: null, success: 'Sucessfully deleted custom inventory device' },
                });
                setTimeout(() => this.setState({ loadingDevices: { op: null }}), 4000);
            })
            .catch(err => {
                console.log(err);

                this.setState({
                    loadingDevices: { op: null, error: err },
                });
            });

        this.setState({
            loadingDevices: { op: deleting },
        });
    }

    getEntries () {
        return (
            <div className="container">
                <table className="table table-striped table-hover text-center" style={{ tableLayout: 'fixed', wordWrap: 'break-word' }}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: '300px' }}>Name</th>
                            <th scope="col" style={{ width: '250px' }}>Mac Address</th>
                            <th scope="col" style={{ width: '350px' }}>Asset Tag</th>
                            <th scope="col" style={{ width: 'auto', textAlign: 'right' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.customDevices.map(dev => (
                            <tr key={dev.id}>
                                <td className="ellipsis-overflowing-text" title={dev.name}>
                                    {dev.name}
                                </td>
                                <td>
                                    {dev.mac_address}
                                </td>
                                <td className="ellipsis-overflowing-text" title={dev.asset_tag}>
                                    {dev.asset_tag}
                                </td>
                                <td className="float-right">
                                    <button type="button" className="btn btn-sm btn-outline-danger" onClick={this.onDeleteCustomDevice.bind(this, dev)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}

                        <tr key="new-device">
                            <td>
                                <input type="text" value={this.state.newDevice.name} placeholder="Name" className="form-control"
                                    style={{ width: '265px' }} onChange={this.onNewDeviceChange.bind(this, 'name')} />
                            </td>
                            <td>
                                <input type="text" value={this.state.newDevice.mac_address} placeholder="Mac Address" className="form-control"
                                    style={{ width: '215px' }} onChange={this.onNewDeviceChange.bind(this, 'mac_address')} />
                            </td>
                            <td>
                                <input type="text" value={this.state.newDevice.asset_tag} placeholder="Asset Tag" className="form-control"
                                    style={{ width: '315px' }} onChange={this.onNewDeviceChange.bind(this, 'asset_tag')} />
                            </td>
                            <td className="float-right">
                                <button type="button" className="btn btn-sm btn-outline-success" onClick={this.onCreateCustomDevice.bind(this)}>
                                    Create
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    render () {
        return (
            <div className="container">
                <PageMenu
                    loaders={[ this.state.newDevice ]}

                    middle={() => {
                        return <AlertMessageDialog
                            success={[ this.state.loadingDevices.success ]}
                            errors={[ this.state.loadingDevices.error ]}
                        />;
                    }}
                />

                <div className="row mt-3">
                    <h5 className="col-sm">Custom Inventory Devices</h5>
                </div>
                <hr className="mt-0"/>

                <p style={{ maxWidth: '70%' }}>
                    Custom inventory devices are devices, identified by their MAC address, which can still
                    authenticated on the network but are not registered in the inventory.
                </p>
                <br/>

                {this.getEntries()}
            </div>
        );
    }
}
