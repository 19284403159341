import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import Select, { components } from 'react-select'

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import { getDifference } from './helper.js';
import ValidationRules from './validation.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import UserPasswordField from './UserPasswordField.js';

class EditUser extends React.Component  {
    state = {
        original: null,
        user: null,
        isChanged: false,
        users: [],
        selectedManager: null,
        selectedDotted: null,
        saving: { op: null, error: null },
        loading: { op: null, error: null },
        loading_users: { op: null, error: null },
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        let loading = new Cancelable(this.props.backend.getUser(this.props.guid));

        loading
            .then((response) => {
                let loading_users = new Cancelable(this.props.backend.getUsers([ 'fullname', 'dn' ]))

                loading_users
                    .then((response) => {
                        let users = response.data.users;

                        users.sort((a, b) => {
                            return a.fullname.localeCompare(b.fullname);
                        });

                        let new_state = {
                            loading_users: { op: null, error: null },
                            users: users
                        };

                        if (this.state.user) {
                            new_state.selectedManager = users.filter((a) => a.dn === this.state.user.manager);
                            new_state.selectedDotted = users.filter((a) => a.dn === this.state.user.dotted);
                        }

                        this.setState(new_state);
                    });

                this.setState(
                    {
                        loading: { op: null, error: null },
                        loading_users: { op: loading_users, error: null },
                        original: response.data,
                        user: response.data,
                    }
                );
            })
            .catch((e) => {
                this.setState({ loading: { op: null, error: e }});
            });

        this.setState({ loading: { op: loading, error: null } });
    }

    comonentWillUnmount () {
        if (this.loading.op) {
            this.loading.op.cancel()
        }

        if (this.loading_users.op) {
            this.loading_users.op.cancel()
        }
    }

    box (description, name, value, read, write) {
        return (
            <div key={name} className="form-group row">
                <label className="col-sm-3 col-form-label">{description}</label>
                <div className="col-sm-9">
                    <input type="text" className={"form-control " + (this.isChanged(name) ? 'border-warning' : '')} onChange={this.onChange.bind(this, name)}
                        readOnly={read && !write} value={value || ''} />
                    <small className="form-text text-muted">
                        {ValidationRules[name] && ValidationRules[name].hint}
                    </small>
                </div>
            </div>
        );
    }

    /* applies a change to the current item */
    onChange (field, event) {
        let item = Object.assign({}, this.state.user);

        if (ValidationRules[field] && ValidationRules[field].validate) {
            ValidationRules[field].validate(event.target);
        }

        item[field] = event.target.value;

        let difference = getDifference(this.state.original, item);

        this.setState({ user: item, isChanged: Object.keys(difference).length > 0 });
    }

    onChangeOrganization (org) {
        let item = Object.assign({}, this.state.user);

        if (org) {
            item['organization'] = org.dn;
        } else {
            item['organization'] = null;
        }

        let difference = getDifference(this.state.original, item);

        this.setState({ user: item, isChanged: Object.keys(difference).length > 0 });
    }

    onChangeManager (user) {
        let item = Object.assign({}, this.state.user);

        if (user) {
            item['manager'] = user.dn;
        } else {
            item['manager'] = null;
        }

        let difference = getDifference(this.state.original, item);

        this.setState({ user: item, isChanged: Object.keys(difference).length > 0, selectedManager: user });
    }

    onChangeDotted (user) {
        let item = Object.assign({}, this.state.user);

        if (user) {
            item['dotted'] = user.dn;
        } else {
            item['dotted'] = null;
        }

        let difference = getDifference(this.state.original, item);

        this.setState({ user: item, isChanged: Object.keys(difference).length > 0, selectedDotted: user });
    }

    onChangePassword (password) {
        let user = Object.assign({}, this.state.user);
        user.password = password;

        this.setState({
            user,
            isChanged: true,
        });
    }

    onResetTfa () {
        let saving = this.props.backend.resetTfaForUser(this.props.guid)
            .then(response => {
                let user = Object.assign({}, this.state.user);

                user.tfa_enabled = 0;

                this.setState({
                    user,
                    success: 'Two-factor authentication disabled for user',
                    saving: { op: null, error: null },
                });
            })
            .catch(e => {
                if (e.response && e.response.status === 403) {
                    e = 'Your are not allowed to perform this action.';
                }

                this.setState({ saving: { op: null, error: e } });

            });

        this.setState({ saving: { op: saving, error: null }});
    }

    onResetU2f () {
        let saving = this.props.backend.resetU2fForUser(this.props.guid)
            .then(response => {
                let user = Object.assign({}, this.state.user);

                user.u2f_enabled = 0;

                this.setState({
                    user,
                    success: 'U2F authentication disabled for user',
                    saving: { op: null, error: null },
                });
            })
            .catch(e => {
                if (e.response && e.response.status === 403) {
                    e = 'Your are not allowed to perform this action.';
                }

                this.setState({ saving: { op: null, error: e } });

            });

        this.setState({ saving: { op: saving, error: null }});
    }

    isChanged (prop) {
        return this.state.user[prop] !== '' && this.state.original[prop] !== this.state.user[prop];
    }

    save () {
        let difference = getDifference(this.state.original, this.state.user);

        console.log(this.state.original, this.state.user, difference);

        if (this.state.user.windows_username && !ValidationRules.windows_username.validate(this.state.user.windows_username)) {
            this.setState({ saving: { op: null, error: new Error('Invalid value for sAMAccountName') }});
            setTimeout(() => this.setState({ saving: { op: null, error: null }}), 4000);
            return;
        }

        let saving = this.props.backend.putUser(this.props.guid, difference)
            .then((response) => {
                if (response.status === 200) {
                    let original = Object.assign({}, this.state.original);

                    for (let prop in response.data.changes) {
                        original[prop] = response.data.changes[prop];
                    }

                    this.setState({
                        saving: { op: null },
                        original: original,
                        user: original,
                        isChanged: false,
                        success: 'User sucessfully saved'
                    });
                }
            })
            .catch((e) => {
                let error = e;

                if (e.response && e.response.status === 403) {
                    error = 'Your are not allowed to change this field.'
                }

                this.setState({ saving: { op: null, error: error } });
            });

        this.setState({ saving: { op: saving }, success: null });
    }

    render () {
        let item = this.state.user;
        let saveButton = null;

        if (!this.state.loading.op) {
            if (this.state.saving.op) {
                saveButton = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
            } else {
                saveButton = <button className="btn btn-outline-success btn-sm" disabled={(this.state.isChanged ? false : true)} onClick={this.save.bind(this)}>Save</button>
            }
        }

        const OrganizationControl = (props) => <components.Control className={(this.state.original['organization'] !== item.organization ? 'border-warning' : '')} {...props} />
        const ManagerControl = (props) => <components.Control className={(this.state.original['manager'] !== item.manager ? 'border-warning' : '')} {...props} />
        const DottedControl = (props) => <components.Control className={(this.state.original['dotted'] !== item.dotted ? 'border-warning' : '')} {...props} />

        const organizationSelect = (read, write) => (
            <div key="organization" className="form-group row">
                <label className="col-sm-3 col-form-label">Organization</label>
                <div className="col-sm-9">
                    <Select components={{ Control: OrganizationControl }}
                            options={this.props.organizations} getOptionLabel={(o) => o.name} getOptionValue={(o) => o.dn}
                            isClearable value={this.props.organizations.filter(a => a.dn === this.state.user.organization)} onChange={this.onChangeOrganization.bind(this)}
                            isDisabled={read && !write} placeholder="Select Organization..."
                    />
                </div>
            </div>
        );

        const managerSelect = (read, write) => (
            <div key="manager" className="form-group row">
                <label className="col-sm-3 col-form-label">Manager</label>
                <div className="col-sm-9">
                    <Select components={{ Control: ManagerControl }} isLoading={this.state.loading_users.op !== null}
                            options={this.state.users} getOptionLabel={(o) => o.fullname} getOptionValue={(o) => o.dn}
                            isClearable value={this.state.selectedManager} onChange={this.onChangeManager.bind(this)}
                            isDisabled={read && !write} placeholder="Select Manager..."
                    />
                </div>
            </div>
        );

        const dottedSelect = (read, write) => (
            <div key="dotted" className="form-group row">
                <label className="col-sm-3 col-form-label">Dotted</label>
                <div className="col-sm-9">
                    <Select components={{ Control: DottedControl }} isLoading={this.state.loading_users.op !== null}
                            options={this.state.users} getOptionLabel={(o) => o.fullname} getOptionValue={(o) => o.dn}
                            isClearable value={this.state.selectedDotted} onChange={this.onChangeDotted.bind(this)}
                            isDisabled={read && !write} placeholder="Select Dotted..."
                    />
                </div>
            </div>
        );

        const passwordControl = (read, write) => (
            <div key="password" className="form-group row">
                <label className="col-sm-3 col-form-label">Password</label>
                <div className="col-sm-9">
                    <UserPasswordField onChange={this.onChangePassword.bind(this)} isDisabled={read && !write} />
                </div>
            </div>
        );

        const canResetTfa = item && this.props.permissionManager.isAllowedOrganization('users_reset_2fa', item.organization);

        const tfaU2fStatusControl = (name, prop, cb) => (
            <div key={prop} className="form-group row">
                <label className="col-sm-3 col-form-label">{name + ' Status'}</label>
                <div className="col-sm-9 form-row">
                    <div className="col">
                        <input type="text" readOnly className="form-control-plaintext"
                               value={item[prop] ? 'Enabled' : 'Disabled'} />
                    </div>

                    {item[prop] !== 0 &&
                        <div className="col">
                            <button type="button" className="btn btn-sm btn-outline-danger float-right"
                                    style={{marginRight: '-8px'}} disabled={!canResetTfa} onClick={cb}>
                                {'Reset ' + name}
                            </button>
                        </div>
                    }
                </div>
            </div>
        );

        let view_properties = [];
        let edit_properties = [];

        let view_fields = this.props.permissionManager.getAllowedFields('users_list');
        let edit_fields = this.props.permissionManager.getAllowedFields('users_edit');

        if (item &&
            !this.props.permissionManager.isAllowedOrganization('users_list', item.organization)) {
            view_fields = [];
        }

        if (item &&
            !this.props.permissionManager.isAllowedOrganization('users_edit', item.organization)) {
            edit_fields = []
        }

        let all_properties = [];

        if (this.props.fields) {
            for (let i = 0; i < this.props.fields.length; i++) {
                let field = Object.assign({}, this.props.fields[i]);

                switch (field.field) {
                    case 'organization':
                        field.control = organizationSelect;
                        break;
                    case 'manager':
                        field.control = managerSelect;
                        break;
                    case 'dotted':
                        field.control = dottedSelect;
                        break;
                    case 'password':
                        field.control = passwordControl;
                        break;
                    case 'tfa_enabled':
                        field.control = tfaU2fStatusControl.bind(this, '2FA', 'tfa_enabled', this.onResetTfa.bind(this));
                        break;
                    case 'u2f_enabled':
                        field.control = tfaU2fStatusControl.bind(this, 'U2F', 'u2f_enabled', this.onResetU2f.bind(this));
                        break;
                    default:
                        break;
                }

                if (view_fields.indexOf(field.field) !== -1) {
                    view_properties.push(field);
                }

                if (edit_fields.indexOf(field.field) !== -1) {
                    edit_properties.push(field);
                }

                all_properties.push(field);
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loading ]}

                    left={() =>
                        <Fragment>
                            <Link to={'/users'} className="btn btn-outline-secondary btn-sm">Back</Link>&nbsp;
                            <Link to={'/users/' + this.props.guid + ''} className="btn btn-outline-secondary btn-sm">View</Link>
                        </Fragment>
                    }

                    middle={() => {
                        return <AlertMessageDialog
                            success={[this.state.success]}
                            errors={[this.state.saving.error]}
                        />;
                    }}

                    right={() => saveButton}
                />
                {(() => {
                    if (!item) {
                        return null;
                    }

                    return (
                        <form className="offset-md-2 col-sm-8 mt-3 needs-validation">
                            {all_properties.map((prop) => {
                                let view = false;
                                let edit = false;

                                if (view_properties.indexOf(prop) !== -1) {
                                    view = true;
                                }

                                if (edit_properties.indexOf(prop) !== -1) {
                                    edit = true;
                                }

                                if (!view && !edit) {
                                    return null;
                                }

                                if (prop.control) {
                                    return prop.control(view, edit);
                                }

                                return this.box(prop.name, prop.field, item[prop.field], view || edit, edit);
                            })}
                        </form>
                    );
                })()}
            </div>
        )
    }
}

export default EditUser;
