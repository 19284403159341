export function ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
}

export function str2ab(str) {
    var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
    var bufView = new Uint16Array(buf);

    for (let i = 0, strLen = str.length; i < strLen; i++) {

        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

export function ab2hex(buffer) { // buffer is an ArrayBuffer
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
}

export function hex2ab(hex) {
    var view = new Uint8Array(hex.length / 2)

    for (var i = 0; i < hex.length; i += 2) {
        view[i / 2] = parseInt(hex.substring(i, i + 2), 16)
    }

    return view.buffer
}