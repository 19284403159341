import React, { Fragment } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from 'reactstrap';
import jQuery from 'jquery';

import Backend from './backend.js';

/* UI */
import Dashboard from './dashboard.js';
import Users from './Users.js';
import ShowUser from './ShowUser.js';
import EditUser from './EditUser.js';
import NewUser from './NewUser.js';
import EditUserPermissions from './EditUserPermissions.js';
import EditUserApplications from './EditUserApplications.js';
import EditUserGroups from './EditUserGroups.js';
import Computers from './Computers.js';
import NewComputer from './NewComputer.js';
import ShowComputer from './ShowComputer.js';
import EditComputer from './EditComputer.js';
import Groups from './Groups.js';
import NewGroup from './NewGroup.js';
import ShowGroup from './ShowGroup.js';
import EditGroup from './EditGroup.js';
import EditGroupMembers from './EditGroupMembers.js';
import ChangePassword from './ChangePassword.js';
import SecuritySetup from './SecuritySetup.js';
import PasswordList from './Passwords/PasswordList.js';
import CustomInventoryDevices from './CustomInventoryDevices.js';

import Roles from './Roles/Roles.js';
import NewRole from './Roles/NewRole.js';
import EditRole from './Roles/EditRole.js';
import EditRoleMembers from './Roles/EditRoleMembers.js';
import EditRolePermissions from './Roles/EditRolePermissions.js';

import NfcAccessList from './NfcAccessList/NfcAccessList.js';

import { BrowserRouter as Router, Route, Switch, Redirect, NavLink } from 'react-router-dom';

const MyBackend = new Backend({
    url: process.env.REACT_APP_BACKEND,
    ssoConfig: process.env.REACT_APP_SSO_CONFIGURATION,
});

const MyPermissions = MyBackend;

class Main extends React.Component {
    constructor (props) {
        super(props);

        this.backend = MyBackend;

        this.state = {
            isOpen: true,
            isConnected: true,
            isLoggedIn: this.backend.authorization != null,
            loggedInUser: this.backend.loggedInUser,
            fields: {},

            usersFilter: Users.newFilter(),
            groupsFilter: Groups.newFilter(),
            computersFilter: Computers.newFilter(),
            rolesFilter: Roles.newFilter(),
            accessListFilter: NfcAccessList.newFilter(),
        };

        this.backend.on('loggedin', (status, user) => {
            console.log('Status updated:', status, user);

            this.setState({
                isLoggedIn: status,
                loggedInUser: user,
                fields: this.backend.fields,
                permissions: this.backend.permissions
            });

            const shownSecurityWarning = localStorage.getItem('shown_sec_warning');
            if (!shownSecurityWarning && user && user.enforce_security && !(user.has_tfa_enabled || user.has_u2f_enabled)) {
                if (this.securityModalRef.current) {
                    jQuery(this.securityModalRef.current)
                        .on('hidden.bs.modal', () => {
                            localStorage.setItem('shown_sec_warning', true);
                        })
                        .modal('show');
                }
            }
        });

        this.backend.on('connected', (connected) => {
            this.setState({
                isConnected: connected
            })
        });

        this.securityModalRef = React.createRef();
    }

    logout (e) {
        console.log('Logging out!');

        this.backend.logout();
        localStorage.removeItem('shown_sec_warning');

        e.preventDefault();
    }

    setFilter (prop, filter) {
        this.setState({ [prop]: filter });
    }

    showDashboard () {
        return <Dashboard backend={this.backend} />
    }

    showUsers ({ history, match }) {
        return <Users
            backend={this.backend} permissionManager={MyPermissions}
            filter={this.state.usersFilter} setFilter={this.setFilter.bind(this, 'usersFilter')}
            match={match} history={history}
        />;
    }

    newUser () {
        return <NewUser backend={this.backend} permissionManager={MyPermissions} fields={this.state.fields.user} />
    }

    showUser ({ match }) {
        return <ShowUser backend={this.backend} permissionManager={MyPermissions} guid={match.params.guid} fields={this.state.fields.user} />
    }

    editUser ({ match }) {
        return <EditUser
            backend={this.backend} permissionManager={MyPermissions} guid={match.params.guid} fields={this.state.fields.user}
            organizations={this.backend.organizations}
        />
    }

    editUserPermissions ({ match }) {
        return <EditUserPermissions
            backend={this.backend} guid={match.params.guid} permissions={this.state.permissions || []} fields={this.state.fields}
            organizations={this.backend.organizations}
        />
    }

    editUserApplications ({ match }) {
        return <EditUserApplications
            backend={this.backend} guid={match.params.guid} permissionManager={MyPermissions}
        />
    }

    editUserGroups ({ match }) {
        return <EditUserGroups backend={this.backend} permissionManager={MyPermissions} guid={match.params.guid} />
    }

    showComputers ({ match, history }) {
        return <Computers
            backend={this.backend} permissionManager={MyPermissions}
            filter={this.state.computersFilter} setFilter={this.setFilter.bind(this, 'computersFilter')}
            match={match} history={history}
        />
    }

    newComputer () {
        return <NewComputer backend={this.backend} permissionManager={MyPermissions} fields={this.state.fields.computer} />
    }

    showComputer ({ match }) {
        return <ShowComputer backend={this.backend} permissionManager={MyPermissions} guid={match.params.guid} fields={this.state.fields.computer} />
    }

    editComputer ({ match }) {
        return <EditComputer backend={this.backend} permissionManager={MyPermissions} guid={match.params.guid} fields={this.state.fields.computer} />
    }

    showGroups ({ match, history }) {
        return <Groups
            backend={this.backend} permissionManager={MyPermissions}
            filter={this.state.groupsFilter} setFilter={this.setFilter.bind(this, 'groupsFilter')}
            match={match} history={history}
        />
    }

    newGroup () {
        return <NewGroup
            backend={this.backend} permissionManager={MyPermissions} fields={this.state.fields.group}
        />
    }

    showGroup ({ match, history }) {
        return <ShowGroup
            backend={this.backend} permissionManager={MyPermissions} guid={match.params.guid}
            history={history} fields={this.state.fields.group}
        />
    }

    editGroup ({ match, history }) {
        return <EditGroup
            backend={this.backend} permissionManager={MyPermissions} guid={match.params.guid}
            history={history} fields={this.state.fields.group}
        />
    }

    editGroupMembers ({ match }) {
        return <EditGroupMembers backend={this.backend} permissionManager={MyPermissions} guid={match.params.guid} />;
    }

    showRoles ({ match, history }) {
        return <Roles
            backend={this.backend} permissionManager={MyPermissions}
            filter={this.state.rolesFilter} setFilter={this.setFilter.bind(this, 'rolesFilter')}
            match={match} history={history}
        />
    }

    newRole ({ match, history }) {
        return <NewRole
            backend={this.backend} permissionManager={MyPermissions}
            history={history} fields={this.state.fields.role} />
    }

    editRole ({ match, history }) {
        return <EditRole
            backend={this.backend} permissionManager={MyPermissions} id={match.params.id}
            history={history} fields={this.state.fields.role} />
    }

    editRoleMembers ({ match, history }) {
        return <EditRoleMembers
            backend={this.backend} permissionManager={MyPermissions} id={match.params.id}
            history={history} />
    }

    editRolePermissions ({ match, history }) {
        return <EditRolePermissions
            backend={this.backend} permissionManager={MyPermissions} id={match.params.id}
            permissions={this.state.permissions || []} history={history}
            fields={this.state.fields} organizations={this.backend.organizations} />
    }

    showNfcAccessList ({ match, history }) {
        return <NfcAccessList
            backend={this.backend} permissionManager={MyPermissions}
            filter={this.state.accessListFilter} setFilter={this.setFilter.bind(this, 'accessListFilter')}
            history={history}
        />
    }

    showCustomInventoryDevices () {
        return <CustomInventoryDevices backend={this.backend} />
    }

    showChangePassword () {
        return <ChangePassword backend={this.backend} />
    }

    showSecuritySetup () {
        return <SecuritySetup backend={this.backend} />
    }

    showPasswordList ({ history }) {
        return <PasswordList backend={this.backend} history={history} />
    }

    renderSecurityWarningModal () {
        return (
            <div ref={this.securityModalRef} className="modal fade" tabIndex="-1" role="dialog" aria-labelledby="Security Warning Modal">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Security Warning</h5>
                        </div>
                        <div className="modal-body">
                            <p className="container">
                                You currently have neither <strong>Two-Factor Authentication</strong> nor
                                &nbsp;<strong>Universal 2nd-Factor Authentication</strong> enabled.
                                <br/><br/>
                                These methods will additionally verify your authenticity by the means of your smartphone
                                or USB-based device when logging in through Creamfinance SSO.
                                <br/><br/>
                                <strong>This will increase the security of your company account and is recommended.</strong>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Skip</button>

                            <NavLink to="/securitysetup" className="btn btn-success"
                                     onClick={() => jQuery(this.securityModalRef.current).modal('hide') }>
                                Set up now
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render () {
        let routes = null;
        let topLeft = null;
        let topRight = null;

        if (this.state.isLoggedIn && this.state.isConnected) {
            topLeft = (
                <Fragment>
                    <NavItem>
                        <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/users" className="nav-link">Users</NavLink>
                    </NavItem>
                    {MyPermissions.getAllowedOrganizations('groups_list').length > 0 &&
                        <NavItem>
                            <NavLink to="/groups" className="nav-link">Groups</NavLink>
                        </NavItem>
                    }
                    {MyPermissions.getAllowedOrganizations('computers_list').length > 0 &&
                        <NavItem>
                            <NavLink to="/computers" className="nav-link">Computers</NavLink>
                        </NavItem>
                    }
                    {MyPermissions.hasPermission('roles_list') &&
                        <NavItem>
                            <NavLink to="/roles" className="nav-link">Roles</NavLink>
                        </NavItem>
                    }

                    {MyPermissions.hasPermission("nfc_list_access") &&
                        <NavItem>
                            <NavLink to="/nfc/accesslist" className="nav-link">Nfc Access List</NavLink>
                        </NavItem>
                    }
                    <NavItem>
                        <NavLink to="/passwords" className="nav-link">Passwords</NavLink>
                    </NavItem>
                </Fragment>
            );

            routes = (
                <Switch>
                    <Route exact path="/dashboard" component={this.showDashboard.bind(this)} />

                    <Route exact path="/users" render={this.showUsers.bind(this)} />
                    <Route exact path="/users/new" render={this.newUser.bind(this)} />
                    <Route exact path="/users/:guid" render={this.showUser.bind(this)} />
                    <Route exact path="/users/:guid/edit" render={this.editUser.bind(this)} />
                    <Route exact path="/users/:guid/groups" render={this.editUserGroups.bind(this)} />
                    <Route exact path="/users/:guid/permissions" render={this.editUserPermissions.bind(this)} />
                    <Route exact path="/users/:guid/applications" render={this.editUserApplications.bind(this)} />

                    <Route exact path="/computers" render={this.showComputers.bind(this)} />
                    <Route exact path="/computers/new" render={this.newComputer.bind(this)} />
                    <Route exact path="/computers/:guid" render={this.showComputer.bind(this)} />
                    <Route exact path="/computers/:guid/edit" render={this.editComputer.bind(this)} />

                    <Route exact path="/groups" render={this.showGroups.bind(this)} />
                    <Route exact path="/groups/new" render={this.newGroup.bind(this)} />
                    <Route exact path="/groups/:guid" render={this.showGroup.bind(this)} />
                    <Route exact path="/groups/:guid/edit" render={this.editGroup.bind(this)} />
                    <Route exact path="/groups/:guid/members" render={this.editGroupMembers.bind(this)} />

                    <Route exact path="/roles" render={this.showRoles.bind(this)} />
                    <Route exact path="/roles/new" render={this.newRole.bind(this)} />
                    <Route exact path="/roles/:id" render={this.editRole.bind(this)} />
                    <Route exact path="/roles/:id/members" render={this.editRoleMembers.bind(this)} />
                    <Route exact path="/roles/:id/permissions" render={this.editRolePermissions.bind(this)} />

                    <Route exact path="/nfc/accesslist" render={this.showNfcAccessList.bind(this)} />

                    <Route exact path="/custominventorydevices" render={this.showCustomInventoryDevices.bind(this)} />
                    <Route exact path="/changepassword" render={this.showChangePassword.bind(this)} />
                    <Route exact path="/securitysetup" render={this.showSecuritySetup.bind(this)} />
                    <Route       path="/passwords" render={this.showPasswordList.bind(this)} />

                    <Redirect to="/dashboard" />
                </Switch>
            );

            topRight = (
                <NavItem className="dropdown">
                    <button className="btn btn-link nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{(this.state.loggedInUser ? this.state.loggedInUser.fullname : '')}</button>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        {MyPermissions.hasPermission('users_manage_custom_devices') &&
                            <NavLink to="/custominventorydevices" className="dropdown-item">Custom Inventory Devices</NavLink>
                        }
                        <NavLink to="/changepassword" className="dropdown-item">Change Password</NavLink>
                        <NavLink to="/securitysetup" className="dropdown-item">Security</NavLink>
                        <NavLink to="/logout" className="dropdown-item" onClick={this.logout.bind(this)}>Logout</NavLink>
                    </div>
                </NavItem>
            );
        } else {
            const loginUrl = this.backend.baseUrl + '/login/' + this.backend.sso.config;

            topRight = (
                <NavItem>
                    <a href={loginUrl} className="nav-link">Login</a>
                </NavItem>
            );
        }

        return (
            <Router>
              <div>
                <Navbar color="dark" dark expand="md">
                  <div className="container">
                    <NavbarBrand></NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                      <Nav navbar>
                        {topLeft}
                      </Nav>
                      <Nav className="ml-auto" navbar>
                        {topRight}
                      </Nav>
                    </Collapse>
                  </div>
                </Navbar>
                {routes}
                {this.renderSecurityWarningModal()}
              </div>
            </Router>
        );
    }
}

export default Main;
