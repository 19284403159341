import React, { Fragment } from 'react';
import { Link, withRouter } from "react-router-dom";
import Select, { Creatable, createFilter } from 'react-select';
import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import AlertMessageDialog from '../AlertMessageDialog.js';

import { encryptPassword, decryptPassword } from './crypto_helper.js';
import { PASSWORD_SUBFIELD_TYPES } from './NewPassword.js';

class ShowPassword extends React.Component {
	original_users = [];
    original_owner = null;

	state = {
		password: null,
		password_decrypted: null,

		users: [],
		selectedUser: null,
		selectedUsers: [],
		keys: [],
        activeKey: null,
        selectedType: null,
        editFieldName: null,

        loading_pw: { op: null, error: null },
        loading_keys: { op: null, error: null },
        loading_users: { op: null, error: null },
        saving: { op: null, error: null },
	}

	componentDidMount () {
		this.loadData();
	}

    loadData () {
        const passwordId = this.props.id;

        let loading_keys = new Cancelable(this.props.backend.getMyKeys(passwordId));

        loading_keys
            .then((response) => {
                let keys = response.data.keys;
                let activeKey = response.data.active_key;

                this.setState({ keys, activeKey, loading_keys: { op: null, error: null }});
            })
            .catch((e) => {
                if (e && e.response) {
                    // keys not found -> show message
                    if (e.response.status === 404) {
                        this.setState({ loading_keys: { op: null, error: null }, key: null });
                        return;
                    }
                }

                this.setState({ loading_keys: { op: null, error: e }})
            });

		let loading_pw = new Cancelable(this.props.backend.getPassword(passwordId));

        loading_pw
            .then((response) => {
                if (response.data.updated_password) {
                    // Password id does not reference latest version -> redirect the user to new newest version
                    this.props.history.push('/passwords/' + response.data.updated_password);
                    return;
                }

                let password = response.data;
                password.users = password.users.map(u => u.guid);

				this.updateSelectedUsers(password.users);
                this.filterUsers();

                // We need to save this to check if the current user owns
                // the password, even if he changes the select value.
                this.original_owner = password.owner;

				this.setState({
					password: password,
					loading_pw: { op: null, error: null }
				});
			})
			.catch((e) => {
                console.log(e);

				this.setState({ loading_pw: { op: null, error: e }});
			});

		let loading_users = new Cancelable(this.props.backend.getUsers([ 'guid', 'dn', 'fullname', 'active_key', 'locked' ]));

        loading_users
            .then((response) => {
                let users = response.data.users.filter(u => !u.locked && !!u.active_key);

                users.sort((a, b) => {
                    return a.fullname.localeCompare(b.fullname);
                });

                this.original_users = users;

                this.updateSelectedUsers();
                this.filterUsers();

                this.setState({ loading_users: { op: null, error: null } });
            })
            .catch((e) => {
                this.setState({ loading_users: { op: null, error: e }});
            });

        this.setState({
            loading_pw: { op: loading_pw, error: null },
            loading_keys: { op: loading_keys, error: null },
            loading_users: { op: loading_users, error: null },
        });
    }

    componentWillUnmount () {
        if (this.state.loading_pw.op) {
            this.state.loading_pw.op.cancel();
        }

        if (this.state.loading_keys.op) {
            this.state.loading_keys.op.cancel();
        }

        if (this.state.loading_users.op) {
            this.state.loading_users.op.cancel();
        }

        if (this.state.saving.op) {
            this.state.saving.op.cancel();
        }
    }


    filterUsers (selected_users) {
        let user = this.props.backend.loggedInUser;

        selected_users = selected_users || this.state.selectedUsers;
        let users = this.original_users
            .filter(u => selected_users.indexOf(u) === -1 && (user && u.guid !== user.guid))

        this.setState({ users: users });
    }

    updateSelectedUsers (users) {
        users = users || this.state.selectedUsers;

        if (this.original_users.length > 0) {
            users = this.original_users.filter(u => users.includes(u.guid));
        }

        this.setState({ selectedUsers: users });
    }

    onChange (field, event) {
        let password = Object.assign({}, this.state.password);
        password[field] = event.target.value;

        this.setState({ password: password });
    }

    onEditLabel (field) {
        this.setState({ editFieldName: field });
    }

    onBlurLabel () {
        this.setState({ editFieldName: null });
    }

    onChangeValue (index, event) {
        let password = this.state.password_decrypted.slice();
        password[index].value = event.target.value;

        this.setState({ password_decrypted: password });
    }

    onChangeLabel (index, e) {
        let content = this.state.password_decrypted.slice();
        let newField = Object.assign({}, content[index]);

        newField.label = e.target.value;
        content.splice(index, 1, newField);

        this.setState({ password_decrypted: content });
    }

    onDragStartField (index, e) {
        e.dataTransfer.setData('index', index);
    }

    onDropField (toIndex, e) {
        e.preventDefault();

        let fields = this.state.password_decrypted.slice();

        const fromIndex = parseInt(e.dataTransfer.getData('index'));
        const element = fields[fromIndex];

        const rect = e.target.getBoundingClientRect();
        const threshold = rect.y + (rect.height / 2);
        const offset = (e.clientY - threshold) > 0 ? 1 : 0;

        fields.splice(fromIndex, 1); // Remove element from old position
        fields.splice(toIndex + offset, 0, element); // and insert at new position

        this.setState({ password_decrypted: fields });
    }

	async copyField(field, e) {
		e.preventDefault();

		// copy value to clipboard
		await navigator.clipboard.writeText(field.value);
	}

    removeField (index, e) {
        let fields = this.state.password_decrypted.slice();

        fields.splice(index, 1);

        this.setState({ password_decrypted: fields });
    }

    onOpenUrl (field, e) {
        window.open(field.value, '_blank');
    }

	addSelectedUser () {
		let selectedUsers = this.state.selectedUsers.slice();
		selectedUsers.push(this.state.selectedUser);

		selectedUsers.sort((a, b) => {
			return a.fullname.localeCompare(b.fullname);
		});

		this.setState({ selectedUser: null, selectedUsers });

		this.filterUsers(selectedUsers);

	}

	onChangeSelectedUser (user) {
        this.setState({ selectedUser: user });
	}

    removeUser (user) {
        let selectedUsers = this.state.selectedUsers.slice();
        let idx = selectedUsers.indexOf(user);

        selectedUsers.splice(idx, 1);

        selectedUsers.sort((a, b) => {
            return a.fullname.localeCompare(b.fullname);
        });

        this.setState({ selectedUser: null, selectedUsers });

        this.filterUsers(selectedUsers);
    }

    changeDecryptPassword (e) {
        this.setState({ decrypt_password: e.target.value });
    }

    getUserKeyById(id) {
        return this.state.keys.find(k => k.id === id);
    }

    onChangeKey (key) {
        let password = Object.assign({}, this.state.password);
        password.key_id = key.id;

        this.setState({ password });
    }

    onChangeOwner (owner) {
        let password = Object.assign({}, this.state.password);
        password.owner = owner.guid;

        this.setState({ password });
    }

    onChangeType (type) {
        this.setState({ selectedType: type });
    }

    addType (e) {
        e.preventDefault();
        if (!this.state.selectedType) {
            return;
        }

        let content = this.state.password_decrypted.slice();
        const nextId = Math.max(...content.map(p => p.id)) + 1;

        let field = {
            id: nextId,
            type: this.state.selectedType.type,
            label: this.state.selectedType.name,
            value: ''
        };

        content.push(field);

        this.setState({ password_decrypted: content, selectedType: null });
    }

    onChangePasswordLabels (labels) {
        let password = Object.assign({}, this.state.password);
        password.labels = labels;

        this.setState({ password });
    }

    onCreateNewPasswordLabel (title) {
        let password = Object.assign({}, this.state.password);
        password.labels = password.labels.concat({ title });

        this.setState({ password });
    }

    async onDecrypt (e) {
        e.preventDefault();
        let key = this.getUserKeyById(this.state.password.key_id);
        if (!key) {
            return;
        }

        if (this.state.decrypt_password) {
            decryptPassword(
                this.state.password.content,
                key.privatekey,
                this.state.decrypt_password,
                this.state.password.key,
            ).then(password => {
                this.setState({ password_decrypted: password, show_decrypt_password_entry: false, decrypt_password: null });
            }).catch(e => {
                console.log(e);
                this.setState({ password_decrypted: null, show_decrypt_password_entry: false, decrypt_password: null, decrypt_error: e });
                // Let message disappear after 4 seconds.
                setTimeout(() => this.setState({ decrypt_error: null }), 4000);
            });
		} else {
			// show decrypt
			this.setState({ show_decrypt_password_entry: true });
		}
	}

    async onSave (e) {
        e.preventDefault();

        let password = Object.assign({}, this.state.password);
        password.content = this.state.password_decrypted;
        let key = this.getUserKeyById(this.state.password.key_id);

        let saving = new Cancelable(encryptPassword({
            password,
            backend: this.props.backend,
            users: this.state.selectedUsers,
            userKey: key && { id: key.id, key: key.publickey },
        }));

        saving
            .then(password => {
                return this.props.backend.updatePassword(password);
            })
            .then(response => {
                this.props.onSuccess('Successfully updated password');
                this.props.history.push('/passwords/' + response.data.id);
            })
            .catch(e => {
                let message = (e && e.response && e.response.data) ? e.response.data.error : e.message;

                this.setState({ saving: { op: null, error: message }});
                setTimeout(() => this.setState({ saving: { op: null, error: null }}), 4000);
            });

        this.setState({ saving: { op: saving, error: null }});
    }

    onLock (e) {
        e.preventDefault();

        this.setState({ password_decrypted: null });
    }

    render () {
        let item = this.state.password;
        let saveButton = null;
        let lockButton = null;
        let content = null;

        if (item) {
            if (!this.state.password_decrypted) {
                let key = this.getUserKeyById(this.state.password.key_id);
                let decryptBtnText = "Decrypt with key '" + (key && key.name) + "'";

                if (this.state.show_decrypt_password_entry) {
                    saveButton = (
                        <Fragment>
                            <form className="form-row justify-content-end">
                                <div className="d-table-cell mr-3">
                                    <input ref={(input) => { if (input) { input.focus(); }}} type="password" className="form-control form-control-sm" value={this.state.decrypt_password || ''} onChange={this.changeDecryptPassword.bind(this)} />
                                </div>
                                <div className="d-table-cell mr-1">
                                    <button className="btn btn-outline-success btn-sm" onClick={this.onDecrypt.bind(this)}>Decrypt</button>
                                </div>
                            </form>
                            <small className="form-text text-muted">{decryptBtnText}</small>
                        </Fragment>
                    );
                } else {
                    saveButton = (
                        <Fragment>
                            <button type="button" className="btn btn-outline-danger btn-sm" onClick={this.onDecrypt.bind(this)}>Decrypt</button>
                            <small className="form-text text-muted">{decryptBtnText}</small>
                        </Fragment>
                    );
                }

            } else {
                lockButton = <button type="button" className="btn btn-outline-danger btn-sm mr-2" onClick={this.onLock.bind(this)}>Lock</button>
                saveButton = <button type="button" className="btn btn-outline-success btn-sm" onClick={this.onSave.bind(this)}>Save</button>
            }

			content = (
				<Fragment>
					<form className="offset-md-2 col-sm-8 mt-3">
						<h5>Password</h5>

                        {this.state.password_decrypted &&
                            <div key="key" className="form-group row">
                                <label className="col-sm-3 col-form-label">Key</label>
                                <div className="col-sm-9">
                                    <Select
                                        options={this.state.keys} getOptionLabel={o => o.name} getOptionValue={o => o.id}
                                        value={this.state.keys.find(k => k.id === item.key_id)}
                                        onChange={this.onChangeKey.bind(this)}
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </div>
                            </div>
                        }

                        {this.state.password_decrypted && this.original_owner === this.props.backend.loggedInUser.guid &&
                            <div key="owner" className="form-group row">
                                <label className="col-sm-3 col-form-label">Owner</label>
                                <div className="col-sm-9">
                                    <Select
                                        options={this.original_users} getOptionLabel={o => o.fullname} getOptionValue={o => o.guid}
                                        value={this.original_users.find(u => u.guid === item.owner)}
                                        onChange={this.onChangeOwner.bind(this)}
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </div>
                            </div>
                        }

	                	<div key="name" className="form-group row">
			                <label className="col-sm-3 col-form-label">Name</label>
			                <div className="col-sm-9">
                                <input type="text" className="form-control" onChange={this.onChange.bind(this, "name")}
                                       readOnly={!this.state.password_decrypted} value={item.name} />
			                </div>
			            </div>

                        {this.state.password.recover_key && /* Currently only one option to display */
                            <div key="options" className="form-group row">
                                <label className="col-sm-3 col-form-label">Options</label>
                                <div className="col-sm-9 password-options password-options-show">
                                    <ul>
                                        <li>
                                            {this.state.password.recover_key && /* Only show option if applicable */
                                                <label className="form-check-label" htmlFor="password-option-recoverable">
                                                    <i className="fas fa-check text-success"></i>
                                                    Password recoverable by admin
                                                </label>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }

			            <div key="description" className="form-group row">
			                <label className="col-sm-3 col-form-label">Description</label>
			                <div className="col-sm-9">
                                <textarea className="form-control" onChange={this.onChange.bind(this, "description")}
                                          readOnly={!this.state.password_decrypted} value={item.description} rows="3" />
			                </div>
			            </div>

                        <div key="labels" className="form-group row">
                            <label className="col-sm-3 col-form-label">Labels</label>
                            <div className="col-sm-9">
                                <Creatable
                                    isDisabled={!this.state.password_decrypted} isClearable isMulti placeholder="Select labels ..."
                                    options={this.props.labels} getOptionLabel={o => o.title} getOptionValue={o => o.id || o.title}
                                    value={this.state.password.labels} onChange={this.onChangePasswordLabels.bind(this)}
                                    isValidNewOption={s => s.length > 0}
                                    getNewOptionData={(title, label) => ({title: label})}
                                    onCreateOption={this.onCreateNewPasswordLabel.bind(this)}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                                    }}
                                />
                            </div>
                        </div>

                        <br/>
                        {this.state.password_decrypted &&
                            <div className="form-group row flex-row justify-content-end">
                                <div className="d-table-cell col-sm-6 ml-3">
                                    <Select
                                        options={PASSWORD_SUBFIELD_TYPES}
                                        getOptionLabel={o => o.name} getOptionValue={o => o.id}
                                        isClearable
                                        value={this.state.selectedType}
                                        onChange={this.onChangeType.bind(this)}
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </div>
                                <div className="d-table-cell mr-3">
                                    <button className="btn btn-outline-success" onClick={this.addType.bind(this)} disabled={this.state.selectedType === null}>Add</button>
                                </div>
                            </div>
                        }

                        {this.state.password_decrypted ? this.state.password_decrypted.map((field, index) => {
                            let label = null;
                            let input = null;
                            let additionalInput = null;

                            if (index === this.state.editFieldName) {
                                label = <input
                                    type="text" className="col-sm-3 col-form-label form-control" onChange={this.onChangeLabel.bind(this, index)}
                                    onBlur={this.onBlurLabel.bind(this)} value={field.label}
                                />;
                            } else {
                                label = (
                                    <label className="col-form-label col-sm-3" onClick={this.onEditLabel.bind(this, index)} draggable="true"
                                           onDragStart={this.onDragStartField.bind(this, index)}
                                           onDragOver={e => e.preventDefault()}
                                           onDrop={this.onDropField.bind(this, index)}>
                                        {field.label}
                                    </label>
                                );

                            }

                            switch (field.type) {
                            case 'text':
                            case 'email':
                                input = <input type="text" className="form-control" onChange={this.onChangeValue.bind(this, index)} value={field.value} autoComplete="off" />
                                break;

                            case 'url':
                                input = <input type="url" className="form-control" onChange={this.onChangeValue.bind(this, index)} value={field.value} autoComplete="off" />
                                additionalInput = (
                                    <button type="button" className="btn btn-outline-secondary" tabIndex="-1" onClick={this.onOpenUrl.bind(this, field)}>
                                        <i className="fas fa-external-link-alt"></i>
                                    </button>
                                );
                                break;

                            case 'password':
                                input = <input type="password" className="form-control" onChange={this.onChangeValue.bind(this, index)} value={field.value} autoComplete="new-password" />
                                break;

                            default:
                                input = <div className="form-control-plaintext">Not Implemented!</div>
                                break;
                            }

			            	return (
			            		<div key={field.id} className="form-group row">
					                {label}
					                <div className="col-sm-9 input-group">
					                	{input}
                                        <div className="input-group-append">
                                            {additionalInput}
                                            <button type="button" className="btn btn-outline-secondary" tabIndex="-1" onClick={this.copyField.bind(this, field)}>
                                                <i className="fas fa-copy"></i>
                                            </button>
                                            <button type="button" className="btn btn-outline-danger" tabIndex="-1" onClick={this.removeField.bind(this, index)}>
                                                <i className="fas fa-times"></i>
                                            </button>
                                        </div>
					                </div>
					            </div>
			            	)
			            }) : null }
	                </form>
	                <div className="offset-md-2 col-sm-8 mt-3 mb-3">
	                	<h5>Users with Access</h5>
                        {this.state.password_decrypted &&
                            <div key="users" className="form-group row flex-row justify-content-end">
                                <div className="d-table-cell col-sm-6 ml-3">
                                    <Select
                                        options={this.state.users} getOptionLabel={(o) => o.fullname} getOptionValue={(o) => o.dn}
                                        isClearable value={this.state.selectedUser} onChange={this.onChangeSelectedUser.bind(this)}
                                        filterOption={createFilter({ ignoreAccents: false })}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </div>
                                <div className="d-table-cell mr-3">
                                    <button className="btn btn-outline-success" onClick={this.addSelectedUser.bind(this)} disabled={this.state.selectedUser === null}>Add</button>
                                </div>
                            </div>
                        }

                        {this.state.selectedUsers.map((user, index) => {
                            if (!user || !user.guid) {
                                return <div key={index}></div>;
                            }

			            	return (
			            		<div key={user.guid} className="form-group row">
			            			<div className="d-table-cell offset-sm-3 col-sm-8">
					                	{user.fullname}
					                </div>
					                <div className="d-table-cell mr-3">
                                        {this.state.password_decrypted &&
                                            <button className="btn btn-outline-danger" onClick={this.removeUser.bind(this, user)}>X</button>
                                        }
					            	</div>
			            		</div>
			            	);
			            })}
	                </div>
	            </Fragment>
			)
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loading_keys, this.state.loading_pw, this.state.loading_users ]}

                    left={() => {
                        return <Link to={'/passwords'} className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    middle={() => {
                        return <AlertMessageDialog
                            success={[this.props.successMessage]}
                            errors={[
                                this.state.loading_keys.error,
                                this.state.loading_pw.error,
                                this.state.loading_users.error,
                                this.state.decrypt_error,
                                this.state.saving.error,
                            ]}
                        />;
                    }}

                    right={() => {
                        if (!item) {
                            return null;
                        }

                        return (
                            <Fragment>
                                {lockButton}
                                {saveButton}
                            </Fragment>
                        )
                    }}
                />
                {content}
            </div>
        );
    }
}

export default withRouter(ShowPassword);
