import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select'

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import ValidationRules from './validation.js';
import AlertMessageDialog from './AlertMessageDialog.js';
import UserPasswordField from './UserPasswordField.js';

class NewUser extends React.Component {
	state = {
		user: this.newUser(),

        users: [],
        organizations: [],
        selectedOrganization: null,
        selectedManager: null,
        selectedDotted: null,
        password: null,

        loading_users: { op: null, error: null },
        saving: { op: null, error: null }
	}

    newUser () {
        return {
            firstname: null,
            lastname: null,
            email: null,
            jobtitle: null,
            department: null,
            phone: null,
            pager: null,
            employee_number: null,
            organization: null,
            manager: null,
            dotted: null,
        };
    }

	componentDidMount () {
        this.loadData();
    }

    loadData () {
        let loading_users = new Cancelable(this.props.backend.getUsers());

        loading_users
            .then((response) => {
                let users = response.data.users;

                users.sort((a, b) => {
                    return a.fullname.localeCompare(b.fullname);
                });

                this.setState({ loading_users: { op: null, error: null }, users: users });
            })
            .catch((e) => {
                this.setState({ loading_users: { op: null, error: e }});
            });

        this.setState({
            loading_users: { op: loading_users, error: null },
            organizations: this.props.backend.getAllowedOrganizations('users_create'),
        });
	}

	componentWillUnmount () {
        if (this.state.loading_users.op) {
            this.state.loading_users.op.cancel();
        }
	}

    box (description, name, value) {
        return (
            <div key={name} className="form-group row">
                <label className="col-sm-3 col-form-label">{description}</label>
                <div className="col-sm-9">
                    <input type="text" className="form-control" onChange={this.onChange.bind(this, name)}
                        value={value || ''} />
                    <small className="form-text text-muted">
                        {ValidationRules[name] && ValidationRules[name].hint}
                    </small>
                </div>
            </div>
        );
    }

    /* applies a change to the current item */
    onChange (field, event) {
        let item = Object.assign({}, this.state.user);

        if (ValidationRules[field] && ValidationRules[field].validate) {
            ValidationRules[field].validate(event.target);
        }

        item[field] = event.target.value;

        // let difference = this.getDifference(item);

        this.setState({ user: item });

    }

    onChangeOrganization (org) {
        let item = Object.assign({}, this.state.user);

        if (org) {
            item['organization'] = org.dn;
        } else {
            item['organization'] = null;
        }

        this.setState({ user: item, selectedOrganization: org });
    }

    onChangeManager (user) {
        let item = Object.assign({}, this.state.user);

        if (user) {
            item['manager'] = user.dn;
        } else {
            item['manager'] = null;
        }

        this.setState({ user: item, selectedManager: user });
    }

    onChangeDotted (user) {
        let item = Object.assign({}, this.state.user);

        if (user) {
            item['dotted'] = user.dn;
        } else {
            item['dotted'] = null;
        }

        this.setState({ user: item, selectedDotted: user });
    }

    onChangePassword (password) {
        let user = Object.assign({}, this.state.user);
        user.password = password;

        this.setState({ user });
    }

    getNextHighestUidNumber () {
        const uids = this.state.users
            .filter(u => u.uid_number && u.uid_number.startsWith('11'))
            .map(u => parseInt(u.uid_number))
            .filter(n => !isNaN(n));

        return Math.max(...uids) + 1;
    }

    makeLinuxUser () {
        let user = Object.assign({}, this.state.user);

        const fields = this.props.permissionManager.getAllowedFields('users_create');

        const setProp = (prop, value) => {
            if (!user[prop] && fields.indexOf(prop) !== -1) {
                user[prop] = value;
            }
        };

        setProp('uid_number', this.getNextHighestUidNumber());
        setProp('gid_number', 6004);
        setProp('login_shell', '/bin/bash');

        if (user.username) {
            setProp('unix_login_name', user.username);
            setProp('unix_home_directory', '/home/' + user.username);
        }

        this.setState({ user });
    }

    save () {
        if (this.state.user.windows_username && !ValidationRules.windows_username.validate(this.state.user.windows_username)) {
            this.setState({ saving: { op: null, error: new Error('Invalid value for sAMAccountName') }});
            setTimeout(() => this.setState({ saving: { op: null, error: null }}), 4000);
            return;
        }

        let saving = new Cancelable(this.props.backend.createUser(this.state.user));

        saving
            .then((response) => {
                this.setState({ saving: { op: null, error: null }, user: this.newUser(), success: 'User created successfully'});
            })
            .catch((e) => {
                let error = e;

                if (e.response && e.response.data) {
                    error = error + " " + (JSON.stringify(e.response.data));
                }

                this.setState({ saving: { op: null, error: error }});
            });

        this.setState({ saving: { op: saving, error: null }});
    }

    render () {
        let item = this.state.user;
        let saveButton = null;

        if (this.state.saving.op) {
            saveButton = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
        } else {
            saveButton = <button className="btn btn-outline-success btn-sm" onClick={this.save.bind(this)}>Create</button>
        }

        const organizationSelect = () => (
            <div key="organization" className="form-group row">
                <label className="col-sm-3 col-form-label">Organization</label>
                <div className="col-sm-9">
                    <Select options={this.state.organizations} getOptionLabel={(o) => o.name} getOptionValue={(o) => o.dn} isClearable value={this.state.selectedOrganization} onChange={this.onChangeOrganization.bind(this)} />
                </div>
            </div>
        );

        const managerSelect = () => (
            <div key="manager" className="form-group row">
                <label className="col-sm-3 col-form-label">Manager</label>
                <div className="col-sm-9">
                    <Select options={this.state.users} getOptionLabel={(o) => o.fullname} getOptionValue={(o) => o.dn} isClearable value={this.state.selectedManager} onChange={this.onChangeManager.bind(this)} />
                </div>
            </div>
        );

        const dottedSelect = () => (
            <div key="dotted" className="form-group row">
                <label className="col-sm-3 col-form-label">Dotted</label>
                <div className="col-sm-9">
                    <Select options={this.state.users} getOptionLabel={(o) => o.fullname} getOptionValue={(o) => o.dn} isClearable value={this.state.selectedDotted} onChange={this.onChangeDotted.bind(this)} />
                </div>
            </div>
        );

        const passwordControl = () => (
            <div key="password" className="form-group row">
                <label className="col-sm-3 col-form-label">Password</label>
                <div className="col-sm-9">
                    <UserPasswordField onChange={this.onChangePassword.bind(this)} />
                </div>
            </div>
        );

        let new_fields = this.props.permissionManager.getAllowedFields('users_create');
        let all_properties = [];

        if (this.props.fields) {
            for (let i = 0; i < this.props.fields.length; i++) {
                let field = Object.assign({}, this.props.fields[i]);

                switch (field.field) {
                    case 'organization':
                        field.control = organizationSelect;
                        break;
                    case 'manager':
                        field.control = managerSelect;
                        break;
                    case 'dotted':
                        field.control = dottedSelect;
                        break;
                    case 'password':
                        field.control = passwordControl;
                        break;
                    default:
                        break;
                }

                if (new_fields.indexOf(field.field) !== -1) {
                    all_properties.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loading_users ]}

                    left={() => {
                        return <Link to={'/users'} className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    middle={() => {
                        return <AlertMessageDialog
                            success={[this.state.success]}
                            errors={[ this.state.saving.error, this.state.loading_users.error ]}
                        />;
                    }}

                    right={() => {
                        if (!item) {
                            return null;
                        }

                        return (
                            <Fragment>
                                <button className="btn btn-outline-secondary btn-sm mr-1" onClick={this.makeLinuxUser.bind(this)}>
                                    Make Linux User
                                </button>

                                {saveButton}
                            </Fragment>
                        )
                    }}
                />
                <form className="offset-md-2 col-sm-8 mt-3 needs-validation">
                    {all_properties.map((prop) => {
                        if (prop.control) {
                            return prop.control();
                        }

                        return this.box(prop.name, prop.field, item[prop.field]);
                    })}
                </form>
            </div>
	    )
  	}
}

export default NewUser;
