import { KEYUTIL, KJUR } from 'jsrsasign';

import { str2ab, ab2str, ab2hex, hex2ab } from './binary_helper.js';


function generateRandom (length) {
    let ab = new ArrayBuffer(length);
    let array = new Uint8Array(ab);
    window.crypto.getRandomValues(array);

    return ab;
}

async function importEncryptionKey (key) {
    return crypto.subtle.importKey('raw', key, 'AES-CBC', true, [ 'encrypt' ]);
}

async function encryptString (key, iv, data) {
    let encodedData = str2ab(data);
    return await crypto.subtle.encrypt({ name: 'AES-CBC', iv: iv }, key, encodedData);
}

export async function encryptPassword ({ password, backend, users, serverKey, userKey }) {
    // create our data object
    let data = JSON.stringify(password.content);

    // generate a iv and a random encryption key
    let iv  = generateRandom(16);
    let key = generateRandom(32);
    // Import key into suble Crypto
    let encrypt_key = await importEncryptionKey(key);
    // Encrypt our keys
    let encrypted_data = await encryptString(encrypt_key, iv, data);

    // create the final data we will save:
    let final_data = {
        iv: ab2hex(iv),
        data: ab2hex(encrypted_data)
    };

    password.content = JSON.stringify(final_data);
    // TODO: error handling if a user has no public key
    //       maybe select as soon as the user is added to the list

    let userCodes = {};

    // encode for ourselves
    let me = backend.loggedInUser;

    try {
        // If the caller passed a specific userkey, use that. Otherwise, use
        // the currently active key of the user.
        let response = await backend.getPublicKey(me.guid);
        let meKey = userKey || response.data;

        let mePubKey = KEYUTIL.getKey(meKey.key);
        let meEncHex = KJUR.crypto.Cipher.encrypt(ab2str(key), mePubKey, 'RSA');

        userCodes[me.guid] = { id: meKey.id, key: meEncHex };
    } catch (e) {
        throw new Error('No public key available for ' + me.fullname);
    }

    // retrieve our recipients
    for (let i = 0; i < users.length; i++) {
        let user = users[i];

        try {
            let response = await backend.getPublicKey(user.guid);
            let userKey = response.data;

            // encrypt our random key
            let pubKey = KEYUTIL.getKey(userKey.key);
            let encHex = KJUR.crypto.Cipher.encrypt(ab2str(key), pubKey, 'RSA');

            userCodes[user.guid] = { id: userKey.id, key: encHex };
        } catch (e) {
            throw new Error('No public key available for ' + user.fullname);
        }
    }

    if (password.recover_key || (password.options && password.options.recoverable)) {
        try {
            if (!serverKey) {
                const response = await backend.getServerPublicKey();
                serverKey = response.data.key;
            }

            if (serverKey) {
                let pubKey = KEYUTIL.getKey(serverKey);
                let encHex = KJUR.crypto.Cipher.encrypt(ab2str(key), pubKey, 'RSA');

                password.recover_key = encHex;
            } else {
                throw new Error();
            }
        } catch (e) {
            throw new Error('No public key for recovery defined');
        }
    }

    // Assign decryption keys to password
    password.users = userCodes;
    return password;
}


export async function decryptPassword (content, privateKey, keyPassword, passwordKey) {
    // try to decrypt
    content = JSON.parse(content);

    // Decrypt from here:
    // Load our
    let privKey = KEYUTIL.getKey(privateKey, keyPassword);

    // decode with private key
    let decPlain = KJUR.crypto.Cipher.decrypt(passwordKey, privKey, 'RSA');

    if (decPlain === null) {
        throw new Error("Invalid password for private key.");
    }

    let decKey = str2ab(decPlain);
    let iv = hex2ab(content.iv);
    let data = hex2ab(content.data);

    let dec_key = await crypto.subtle.importKey('raw', decKey, 'AES-CBC', true, [ 'decrypt' ]);

    let decoded = await crypto.subtle.decrypt({ name: 'AES-CBC', iv: iv }, dec_key, data);
    let strData = ab2str(decoded);

    return JSON.parse(strData);
}
