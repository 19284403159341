class EventEmitter {
    has (event) {
        if (!this.eventHandlers)
            return false;

        return event in this.eventHandlers && this.eventHandlers[event].length > 0;
    }

    emit (event) {
        if (!this.eventHandlers)
            return;

        if (event in this.eventHandlers) {
            var params = Array.prototype.slice.call(arguments, 1);
            var handlers = this.eventHandlers[event].handlers;

            for (var i = 0; i < handlers.length; i++) {
                if (handlers[i].callback) {
                    handlers[i].callback.apply(null, params);
                }
            }
        }
    }

    on (event, callback) {
        if (!this.eventHandlers) {
            this.eventHandlers = {};
        }

        if (!(event in this.eventHandlers)) {
            this.eventHandlers[event] = {
                handlers: []
            };
        }

        this.eventHandlers[event].handlers.push({ callback: callback });
    }
}

export default EventEmitter;