import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select, { createFilter } from 'react-select';

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import Helper from './helper.js';

export default class Computers extends React.Component {
    allComputers = []

    state = {
        computers: [],

        sorting: { column: 'computername', direction: 'asc' },

        loadingComputers: { op: null },
    }

    static newFilter () {
        return {
            computername: '',
            os_name: '',
            organization: { value: '', exactMatch: true },
        };
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingComputers.op) {
            this.state.loadingComputers.op.cancel();
        }
    }

    loadData () {
        const loadingComputers = new Cancelable(this.props.backend.getComputers());

        loadingComputers
            .then(response => {
                this.allComputers = response.data.computers;
                this.doFiltering();

                this.setState({
                    loadingComputers: { op: null },
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingComputers: { op: null, error: err }});
            });

        this.setState({
            loadingComputers: { op: loadingComputers },
        });
    }

    setSorting (prop) {
        if (prop === this.state.sorting.column) {
            let direction = this.state.sorting.direction === 'asc' ? 'desc' : 'asc';

            this.doFiltering(this.props.filter, prop, direction);
        } else {
            this.doFiltering(this.props.filter, prop, this.state.sorting.direction);
        }
    }

    setFilter(prop, event) {
        let filter = Object.assign({}, this.props.filter);

        if (!event && prop === 'organization') {
            // react-select will pass null if the search is cleared
            filter[prop] = { value: '', exactMatch: true };
        } else if (event.target) {
            filter[prop] = event.target.value;
        } else if (prop === 'organization') {
            filter[prop] = { value: event.dn, exactMatch: true };
        } else {
            filter[prop] = event;
        }

        this.doFiltering(filter);
    }

    doFiltering (filter, column, direction) {
        filter = filter || this.props.filter;
        column = column || this.state.sorting.column;
        direction = direction || this.state.sorting.direction;

        this.setState({
            computers: Helper.filterAndSortData(this.allComputers, filter, column, direction),
            sorting: { column, direction },
        });

        this.props.setFilter(filter);
    }

    onEditBtn (computer, event) {
        event.preventDefault();

        this.props.history.push('/computers/' + computer.guid + '/edit');
    }

    getEntries () {
        return this.state.computers.map(item => {
            let editBtn = null;
            if (item.organization &&
                this.props.permissionManager.isAllowedOrganization('computers_edit', item.dn)) {
                editBtn = (
                    <button className="btn btn-sm btn-outline-secondary" onClick={this.onEditBtn.bind(this, item)}>
                        Edit
                    </button>
                );
            }

            const makeCell = value => (
                <span onMouseEnter={Helper.onCellHoverSelect}
                      onMouseLeave={() => window.getSelection().removeAllRanges()}>
                    {value}
                </span>
            );

            return (
                <Link to={'/computers/' + item.guid} key={item.guid}>
                    {makeCell(item.computername)}
                    {makeCell(item.os_name)}
                    {makeCell(Helper.organizationDnToPath(item.organization || ''))}
                    <span style={{textAlign: 'right'}}>
                        {editBtn}
                    </span>
                </Link>
            );
        });
    }

    onNew () {
        this.props.history.push('/computers/new');
    }

    onClearFilter () {
        this.doFiltering(Computers.newFilter());
    }

    render () {
        let newComputerBtn = null;

        const organizations = this.props.permissionManager.getAllowedOrganizations('computers_list')
            .filter(o => this.allComputers.findIndex(r => r.organization === o.dn) !== -1);

        const allowedOrgs = this.props.permissionManager.getAllowedOrganizations('computers_create');
        if (allowedOrgs.length > 0) {
            newComputerBtn = <button onClick={this.onNew.bind(this)} className="btn btn-outline-success btn-sm">New Computer</button>
        }

        const orgFilter = organizations.find(o => o.dn === this.props.filter.organization.value);

        return (
             <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingComputers ]}

                    right={() => {
                        return (
                            <Fragment>
                                <span style={{padding: '1rem'}}>{this.state.computers.length} of {this.allComputers.length} records</span>
                                {newComputerBtn}
                            </Fragment>
                        );
                    }}
                />

                <div className="table">
                    <div className="table-head">
                        <div className="table-filter-header">
                            <span>
                                <input className="form-control" placeholder="Computername" type="text"
                                       value={this.props.filter.computername} onChange={this.setFilter.bind(this, 'computername')}
                                />
                            </span>
                            <span>
                                <input className="form-control" placeholder='Operating system' type="text"
                                       value={this.props.filter.os_name} onChange={this.setFilter.bind(this, 'os_name')}
                                />
                            </span>
                            <span>
                                <Select
                                    isClearable
                                    placeholder="Org."
                                    onChange={this.setFilter.bind(this, 'organization')}
                                    options={organizations}
                                    value={orgFilter || null}
                                    getOptionValue={o => o.dn}
                                    getOptionLabel={o => o.dnPath}
                                    menuPortalTarget={document.body}
                                    styles={{
                                        placeholder: base => ({ ...base, fontWeight: 'normal' }),
                                        singleValue: base => ({ ...base, fontWeight: 'normal' }),
                                    }}
                                    filterOption={createFilter({ ignoreAccents: false })}
                                />
                            </span>
                            <span>
                                <button type="button" className="btn btn-outline-secondary float-right" onClick={this.onClearFilter.bind(this)}>
                                    Clear
                                </button>
                            </span>
                        </div>

                        <div className="table-header">
                            <span onClick={this.setSorting.bind(this, 'computername')}>Name</span>
                            <span onClick={this.setSorting.bind(this, 'os_name')}>Operating System</span>
                            <span onClick={this.setSorting.bind(this, 'organization')}>Org.</span>
                            <span className="actions">Actions</span>
                        </div>
                    </div>
                    <div className="table-body">
                        {this.getEntries()}
                    </div>
                </div>
             </div>
        );
    }
}
