import React, { Fragment } from 'react';

import ValidationRules from './validation.js';


class UserPasswordField extends React.Component {
    state = {
        password: null,
        complexity: 2,
    }

    constructor (props) {
        super(props);

        this.passwordRef = React.createRef();
    }

    onChange (e) {
        e.preventDefault();

        ValidationRules.password.validate(e.target);
        this.setState({ password: e.target.value });

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    onGeneratePassword () {
        const password = ValidationRules.password.generate(this.state.complexity);

        // Validate password only after state has been set
        this.setState({ password }, () => {
            if (this.passwordRef.current) {
                ValidationRules.password.validate(this.passwordRef.current);
            }
        });

        if (this.props.onChange) {
            this.props.onChange(password);
        }
    }

    onCopyValue (e) {
        e.preventDefault();

        navigator.clipboard.writeText(this.state.password);
    }

    onToggleType (e) {
        e.preventDefault();

        const target = this.passwordRef.current;
        const type = target.getAttribute('type') === 'password' ? 'text' : 'password';

        target.setAttribute('type', type);
    }

    onChangeComplexity (e) {
        this.setState({ complexity: e.target.value });
    }

    render () {
        return (
            <Fragment>
                <div className="input-group">
                    <input type="password" ref={this.passwordRef} className="form-control" onChange={this.onChange.bind(this)} value={this.state.password || ''} />
                    <div className="input-group-append">
                        <button type="button" className="btn btn-outline-secondary" tabIndex="-1" onMouseUp={this.onToggleType.bind(this)}>
                            <i className="fas fa-eye"></i>
                        </button>

                        <button type="button" className="btn btn-outline-secondary" tabIndex="-1" onClick={this.onCopyValue.bind(this)}>
                            <i className="fas fa-copy"></i>
                        </button>

                        <button type="button" className="btn btn-outline-secondary" tabIndex="-1" onClick={this.onGeneratePassword.bind(this)}>Generate</button>

                        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" tabIndex="-1"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className="sr-only">Toggle dropdown</span>
                        </button>

                        <div className="dropdown-menu px-4 py-3" style={{maxWidth: '100px'}}>
                            <label htmlFor="complexity-range">Complexity: {this.state.complexity}</label>
                            <input type="range" className="custom-range" id="complexity-range" min="2" max="6" tabIndex="-1"
                                   disabled={this.props.isDisabled}
                                   value={this.state.complexity} onChange={this.onChangeComplexity.bind(this)} />
                        </div>
                    </div>
                </div>
                <small className="form-text text-muted">{ValidationRules.password && ValidationRules.password.hint}</small>
            </Fragment>
        );
    }
}

export default UserPasswordField;
