import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select'

import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import Helper from '../helper.js';
import AlertMessageDialog from '../AlertMessageDialog.js';


export default class EditRoleMembers extends React.Component {
    allUsers = []
    allMembers = []

    state = {
        members: [],
        selectedUser: null,
        selectableUsers: [],

        userFilter: '',

        loadingUsers: { op: null },
        loadingRoleMembers: { op: null },
        addingAction: { op: null },
        deletingAction: { op: null },
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingUsers.op) {
            this.state.loadingUsers.op.cancel();
        }

        if (this.state.loadingRoleMembers.op) {
            this.state.loadingRoleMembers.op.cancel();
        }

        if (this.state.addingAction.op) {
            this.state.loadingAction.op.cancel();
        }

        if (this.state.deletingAction.op) {
            this.state.deletingAction.op.cancel();
        }
    }

    loadData () {
        let loadingUsers = new Cancelable(this.props.backend.getUsers([ 'guid', 'fullname', 'locked', 'organization', 'dn' ]));

        loadingUsers
            .then(response => {
                this.allUsers = response.data.users;
                let selectable = response.data.users
                    .filter(u => !u.locked)
                    .filter(u => this.state.members.findIndex(m => m.guid === u.value) === -1)
                    .map(u => ({ label: u.fullname, value: u.guid }));

                selectable.sort((a, b) => a.label.localeCompare(b.label));

                this.setState({
                    selectableUsers: selectable,
                    loadingUsers: { op: null }
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingUsers: { op: null, error: err }});
            });

        let loadingRoleMembers = new Cancelable(this.props.backend.getRoleMembers(this.props.id));

        loadingRoleMembers
            .then(response => {
                let members = response.data.members;
                this.sortMembers(members);

                const selectable = this.state.selectableUsers.slice()
                    .filter(u => members.findIndex(m => m.guid === u.value) === -1);

                this.allMembers = members;
                this.setState({
                    members,
                    selectableUsers: selectable,
                    loadingRoleMembers: { op: null },
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingRoleMembers: { op: null, error: err }});
            })

        this.setState({
            loadingUsers: { op: loadingUsers },
            loadingRoleMembers: { op: loadingRoleMembers },
        });
    }

    onAddMember (event) {
        event.preventDefault();

        const user = this.allUsers.find(u => u.guid === this.state.selectedUser.value);
        let addingAction = new Cancelable(this.props.backend.addUserToRole(this.props.id, user.guid));

        addingAction
            .then(response => {
                let members = this.state.members.slice();
                members.push(user);

                this.allMembers.push(user);

                let selectable = this.state.selectableUsers.slice();
                selectable.splice(selectable.indexOf(this.state.selectedUser), 1);

                this.setState({
                    members,
                    selectableUsers: selectable,
                    selectedUser: null,
                    addingAction: { op: null },
                });

            })
            .catch(err => {
                console.log(err);
                this.setState({ addingAction: { op: null, error: err } });
            });

        this.setState({ addingAction: { op: addingAction }});
    }

    onRemoveMember (user, event) {
        event.preventDefault();

        let deletingAction = new Cancelable(this.props.backend.removeUserFromRole(this.props.id, user.guid));

        deletingAction
            .then(response => {
                let members = this.state.members.slice();
                members.splice(members.indexOf(user), 1);

                this.allMembers.splice(this.allMembers.indexOf(user), 1);

                let selectable = this.state.selectableUsers.slice();
                selectable.push({ label: user.fullname, value: user.guid });
                selectable.sort((a, b) => a.label.localeCompare(b.label));

                this.setState({
                    members,
                    selectableUsers: selectable,
                    selectedUser: null,
                    deletingAction: { op: null },
                });

            })
            .catch(err => {
                console.log(err);
                this.setState({ deletingAction: { op: null, error: err } });
            });

        this.setState({ deletingAction: { op: deletingAction }});
    }

    onChangeAddUser (user) {
        this.setState({ selectedUser: user });
    }

    sortMembers (members) {
        members.sort((a, b) => {
            return a.fullname.localeCompare(b.fullname);
        });
    }

    setUserFilter (event) {
        const filter = { fullname: event.target.value };
        let members = Helper.filterAndSortData(this.allMembers, filter, 'fullname', 'asc');
        this.sortMembers(members);

        this.setState({
            userFilter: event.target.value,
            members,
        });
    }

    getEntries () {
        return this.state.members.map(user => (
            <div key={user.guid} className="card bg-light mt-3">
                <div className="card-body">
                    <div className="float-right">
                        {this.props.permissionManager.hasPermission('roles_update') &&
                            <button
                                type="button" className="btn btn-outline-danger btn-sm" disabled={this.state.deletingAction.op}
                                onClick={this.onRemoveMember.bind(this, user)}>
                                    {this.state.deletingAction.op && this.state.deletingAction.id === user.guid ? 'Deleting...' : 'Delete'}
                            </button>
                        }
                    </div>
                    <h5 className="card-title">{Helper.organizationDnToPath(user.dn)}</h5>
                </div>
            </div>
        ));
    }

    render () {
        let addBtn = null;

        if (this.state.addingAction.op) {
            addBtn = <button className="btn btn-outline-success btn-sm" disabled>Adding...</button>
        } else {
            addBtn = (
                <button className="btn btn-outline-success btn-sm" onClick={this.onAddMember.bind(this)}
                    disabled={!this.state.selectedUser}>Add
                </button>
            );
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingUsers, this.state.loadingRoleMembers, this.state.addingAction, this.state.deletingAction ]}

                    left={() => {
                        return (
                            <Fragment>
                                <Link to="/roles" className="btn btn-outline-secondary btn-sm">Back</Link>&nbsp;
                                <Link to={'/roles/' + this.props.id} className="btn btn-outline-secondary btn-sm">Edit</Link>
                            </Fragment>
                        );
                    }}

                    middle={() => (
                        <AlertMessageDialog
                            errors={[ this.state.loadingUsers.error, this.state.loadingRoleMembers.error, this.state.addingAction.error, this.state.deletingAction.error ]}
                        />
                    )}

                />
                <form className="offset-md-2 col-sm-8 mt-3">
                    <div className="card bg-light mt-3">
                        <div className="card-body">
                            <div className="float-right">
                                {addBtn}
                            </div>

                            <h5 className="card-title">Add role member</h5>
                            <div className="form-group row">
                                <div className="col-sm">
                                    <Select
                                        options={this.state.selectableUsers}
                                        value={this.state.selectedUser}
                                        onChange={this.onChangeAddUser.bind(this)}
                                        placeholder="Select a user to add.."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <form className="offset-md-2 col-sm-8 mt-3">
                    <input className="form-control" placeholder="Name of user to find.." type="text"
                           value={this.state.userFilter} onChange={this.setUserFilter.bind(this)}
                    />

                    {this.getEntries()}
                </form>
            </div>
        )
    }
}
