import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

import Cancelable from '../cancelable.js';
import PageMenu from '../PageMenu.js';
import { getDifference } from '../helper.js';
import ValidationRules from '../validation.js';
import AlertMessageDialog from '../AlertMessageDialog.js';

export default class EditRole extends React.Component  {
    state = {
        original: null,
        role: null,
        isChanged: false,
        saving: { op: null, error: null },
        loading: { op: null, error: null },
    }

    componentDidMount () {
        this.loadData();
    }

    loadData () {
        let loading = new Cancelable(this.props.backend.getRole(this.props.id));

        loading
            .then((response) => {
                this.setState(
                    {
                        loading: { op: null, error: null },
                        original: response.data,
                        role: response.data,
                    }
                );
            })
            .catch((e) => {
                this.setState({ loading: { op: null, error: e }});
            });

        this.setState({ loading: { op: loading, error: null } });
    }

    comonentWillUnmount () {
        if (this.loading.op) {
            this.loading.op.cancel()
        }
    }

    box (description, name, value, read, write) {
        return (
            <div key={name} className="form-group row">
                <label className="col-sm-3 col-form-label">{description}</label>
                <div className="col-sm-9">
                    <input type="text" className={"form-control " + (this.isChanged(name) ? 'border-warning' : '')} onChange={this.onChange.bind(this, name)}
                        readOnly={read && !write} value={value || ''} />
                    <small className="form-text text-muted">
                        {ValidationRules[name] && ValidationRules[name].hint}
                    </small>
                </div>
            </div>
        );
    }

    /* applies a change to the current item */
    onChange (field, event) {
        let item = Object.assign({}, this.state.role);

        if (ValidationRules[field] && ValidationRules[field].validate) {
            ValidationRules[field].validate(event.target);
        }

        item[field] = event.target.value;

        let difference = getDifference(this.state.original, item);

        this.setState({ role: item, isChanged: Object.keys(difference).length > 0 });
    }

    isChanged (prop) {
        return this.state.role[prop] !== '' && this.state.original[prop] !== this.state.role[prop];
    }

    save () {
        let difference = getDifference(this.state.original, this.state.role);

        let saving = this.props.backend.putRole(this.props.id, difference)
            .then((response) => {
                if (response.status === 200) {
                    let original = response.data;

                    this.setState({
                        saving: { op: null },
                        original: original,
                        role: original,
                        isChanged: false,
                        success: 'Role sucessfully saved'
                    });
                }
            })
            .catch((e) => {
                let error = e;

                if (e.response && e.response.status === 403) {
                    error = 'Your are not allowed to change this field.'
                }

                this.setState({ saving: { op: null, error: error } });
            });

        this.setState({ saving: { op: saving }, success: null });
    }

    render () {
        let item = this.state.role;
        let saveButton = null;

        if (!this.state.loading.op) {
            if (this.state.saving.op) {
                saveButton = <button className="btn btn-outline-success btn-sm" disabled>Saving...</button>
            } else {
                saveButton = <button className="btn btn-outline-success btn-sm" disabled={(this.state.isChanged ? false : true)} onClick={this.save.bind(this)}>Save</button>
            }
        }

        let view_properties = [];
        let edit_properties = [];

        let view_fields = [ 'name' ];
        let edit_fields = [ 'name' ];

        let all_properties = [];

        if (this.props.fields) {
            for (let i = 0; i < this.props.fields.length; i++) {
                let field = Object.assign({}, this.props.fields[i]);

                if (view_fields.indexOf(field.field) !== -1) {
                    view_properties.push(field);
                }

                if (edit_fields.indexOf(field.field) !== -1) {
                    edit_properties.push(field);
                }

                all_properties.push(field);
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loading ]}

                    left={() =>
                        <Fragment>
                            <Link to={'/roles'} className="btn btn-outline-secondary btn-sm">Back</Link>&nbsp;
                        </Fragment>
                    }

                    middle={() => {
                        return <AlertMessageDialog
                            success={[this.state.success]}
                            errors={[this.state.saving.error]}
                        />;
                    }}

                    right={() =>
                        <Fragment>
                            <Link to={'/roles/' + this.props.id + '/permissions'} className="btn btn-outline-secondary btn-sm">Permissions</Link>&nbsp;
                            <Link to={'/roles/' + this.props.id + '/members'} className="btn btn-outline-secondary btn-sm">Members</Link>&nbsp;
                            {saveButton}
                        </Fragment>
                    }
                />
                {(() => {
                    if (!item) {
                        return null;
                    }

                    return (
                        <form className="offset-md-2 col-sm-8 mt-3 needs-validation">
                            {all_properties.map((prop) => {
                                let view = false;
                                let edit = false;

                                if (view_properties.indexOf(prop) !== -1) {
                                    view = true;
                                }

                                if (edit_properties.indexOf(prop) !== -1) {
                                    edit = true;
                                }

                                if (!view && !edit) {
                                    return null;
                                }

                                if (prop.control) {
                                    return prop.control(view, edit);
                                }

                                return this.box(prop.name, prop.field, item[prop.field], view || edit, edit);
                            })}
                        </form>
                    );
                })()}
            </div>
        )
    }
}