import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import Helper from './helper.js';


export default class ShowComputer extends React.Component {
    state = {
        computer: {},

        loadingComputer: { op: null },
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingComputer.op) {
            this.state.loadingComputer.op.cancel();
        }
    }

    loadData () {
        let loadingComputer = new Cancelable(this.props.backend.getComputer(this.props.guid));

        loadingComputer
            .then(response => {
                this.setState({
                    computer: response.data,
                    loadingComputer: { op: null },
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingComputer: { op: null, error: err }});
            });

        this.setState({
            loadingComputer: { op: loadingComputer },
        })
    }

    box (prop, value) {
        return (
            <div key={prop.field} className="form-group row">
                <label className="col-sm-3 col-form-label">{prop.name}</label>
                <div className="col-sm-9">
                    {prop.control}

                    {!prop.control &&
                        <input type="text" readOnly className="form-control" value={value || ''}
                        />
                    }
                </div>
            </div>
        );
    }

    render () {
        const allowedOrgs = this.props.permissionManager.getAllowedOrganizations('computers_list');

        let editBtn = null;
        let fields = [];

        if (this.props.permissionManager.isAllowedOrganization('computers_edit', this.state.computer.organization)) {
            editBtn = (
                <Fragment>
                    <Link to={'/computers/' + this.props.guid + '/edit'} className="btn btn-outline-secondary btn-sm">
                        Edit
                    </Link>
                </Fragment>
            );
        }

        if (allowedOrgs.find(o => o.dn === this.state.computer.organization)) {
            fields = this.props.permissionManager.getAllowedFields('computers_list');
        }

        let viewFields = [];
        if (this.props.fields) {
            for (const field of this.props.fields) {
                if (fields.includes(field.field)) {
                    viewFields.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingComputer ]}

                    left={() => {
                        return <Link to="/computers" className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    right={() => {
                        if (!this.state.computer) {
                            return null;
                        }

                        return (
                            <Fragment>
                                {editBtn}
                            </Fragment>
                        )
                    }}
                />

                {this.state.computer &&
                    <form className="offset-md-2 col-sm-8 mt-3">
                        {viewFields.map(prop => {
                            let value = this.state.computer[prop.field];

                            switch (prop.field) {
                            case 'organization':
                                prop.control = null;
                                value = Helper.organizationDnToPath(value);
                                break;

                            case 'guid':
                                value = Helper.formatObjectGuid(value);
                                break;

                            default: break;
                            }

                            return this.box(prop, value);
                        })}
                        {this.props.fields && viewFields.length === 0 &&
                            <p className="text-center">No fields are allowed to be viewed.</p>
                        }
                    </form>
                }
            </div>
        );
    }
}
