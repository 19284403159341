import React, { Fragment } from 'react';
import Cancelable from './cancelable.js';

class Dashboard extends React.Component {
    state = {
        ssoStatusLoading: null,
        ssoStatus: null,

        sloUrl: null,
    }

    componentWillMount () {
        const sso = this.props.backend.sso;

        if (sso && sso.status_url && sso.logout_url) {
            this.loadData(sso);
        } else {
            this.props.backend.on('sso-config', this.loadData.bind(this))
        }
    }

    componentWillUnmount () {
        if (this.state.ssoStatusLoading) {
            this.state.ssoStatusLoading.cancel();
        }
    }

    constructSsoUrl (base) {
        if (/\/\d+$/.test(base)) {
            return new URL(base);
        }

        return new URL(base + '/' + this.props.backend.sso.config);
    }

    loadData (sso) {
        this.setState({ sloUrl: sso.logout_url });

        if (sso.status_url) {
            this.loadSsoStatus(sso.status_url);
        }
    }

    loadSsoStatus (url) {
        url = this.constructSsoUrl(url);
        let loading = new Cancelable(
            this.props.backend.instance.get(url.toString(), {withCredentials: true})
        );

        loading
            .then(response => {
                this.setState({
                    ssoStatusLoading: null,
                    ssoStatus: response.data.status,
                });
            })
            .catch(err => {
                this.setState({
                    ssoStatusLoading: null,
                    ssoStatus: 'Could not retrieve current status',
                });
            });

        this.setState({
            ssoStatusLoading: loading,
        });
    }

    render () {
        let ssoStatus = null;
        let sloButton = null;

        if (this.state.sloUrl && this.state.ssoStatus === 'authenticated') {
            let sloUrl = this.constructSsoUrl(this.state.sloUrl);
            sloUrl.searchParams.append('next', window.location.href);

            sloButton = (
                <a className="btn btn-sm btn-primary" href={sloUrl.toString()}>
                    Log out from SSO
                </a>
            );
        }

        if (this.state.ssoStatus) {
            if (this.state.ssoStatus === 'authenticated') {
                ssoStatus = (
                    <span className="text-success">
                        <i className="fas fa-check"></i>&nbsp;Authenticated
                    </span>
                );
            } else {
                let status = this.state.ssoStatus === 'unauthenticated' ? 'Logged out' : this.state.ssoStatus;
                status = status.charAt(0).toUpperCase() + status.slice(1);

                ssoStatus = (
                    <span className="text-danger">
                        <i className="fas fa-times"></i>&nbsp;{status}
                    </span>
                );
            }
        }

        return (
            <div className="container">
                {(ssoStatus || sloButton) &&
                    <Fragment>
                        <div className="row mt-3">
                            <h5 className="col-sm">SSO Status</h5>
                        </div>
                        <hr className="mt-0"/>

                        <div className="row">
                            <div className="col-sm-8">
                                {ssoStatus}
                            </div>

                            <div className="col-sm-4 text-right">
                                {sloButton}
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        );
    }
}

export default Dashboard;
