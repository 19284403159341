import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import Select from 'react-select';
import Helper from './helper.js';
import AlertMessageDialog from './AlertMessageDialog.js';

class EditUserApplications extends React.Component {
    field_map = {}
    state = {
        application_map: {},

        user_applications: [],

        new_application: this.newApplication(),

        actions: [
            { id: 0, name: 'Deny access', color: 'red' },
            { id: 1, name: 'Allow access', color: 'green' },
        ],

        loadingApplications: { op: null, error: null },

        saving: { op: null, error: null },
        saving_edit: { op: null, error: null, id: null },
        deleting: { op: null, error: null }
    }

    newApplication () {
        return { application: null, action: null };
    }

    componentDidUpdate (prevProps) {

    }

    componentDidMount () {

        this.setState({ actions_map: this.state.actions.reduce((obj, item) => { obj[item.id] = item; return obj; }, {}) });

        this.loadData();
    }

    async loadData () {
        await this.props.backend.waitAuthorized();

        let loadingUser = new Cancelable(this.props.backend.getUser(this.props.guid));

        loadingUser
            .then(response => {
                this.setState({ loadingUser: { op: null }, user: response.data });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingUser: { op: null, error: err } });
            });

        let loadingApplications = new Cancelable(this.props.backend.getApplications(this.props.guid));

        loadingApplications
            .then((response) => {
                let applications = response.data.available_applications;
                let user_applications = response.data.assigned_applications.map((m) => { m.original_action = m.action; return m });

                this.setState({
                    loadingApplications: { op: null },
                    applications: Helper.filterAndSortData(applications, {}, 'name', 'asc'),
                    user_applications: Helper.filterAndSortData(user_applications, {}, 'name', 'asc'),
                });
            })
            .catch((e) => {
                this.setState({ loadingApplications: { op: null, error: e } });
            });

        this.setState({
            loadingUser: { op: loadingUser },
            loadingApplications: { op: loadingApplications, },
        });
    }

    componentWillUnmount () {
        if (this.state.loadingApplications.op) {
            this.state.loadingApplications.op.cancel();
        }
    }

    box (name, value) {
        return (
            <div className="form-group row">
                <label className="col-sm-3 col-form-label">{name}</label>
                <div className="col-sm-9">
                    <input type="text" readOnly className="form-control" value={value || ''} />
                </div>
            </div>
        );
    }

    save (e) {
        e.preventDefault();

        let appid = this.state.new_application.application.id;
        let action = this.state.new_application.action.id;

        let saving = this.props.backend.setApplication(this.props.guid, appid, { action: action })
            .then(async () => {
                this.setState({
                    saving: { op: null, error: null },
                    new_application: this.newApplication()
                });

                this.loadData();
            })
            .catch((e) => {
                this.setState({ saving: { op: null, error: e }});
            });

        this.setState({ saving: { op: saving, error: null }});
    }

    onApplicationChanged (appid) {
        let obj = Object.assign({}, this.state.new_application);
        obj.application = appid;
        this.setState({ new_application: obj });
    }

    onActionChanged (action) {
        let obj = Object.assign({}, this.state.new_application);
        obj.action = action;
        this.setState({ new_application: obj });
    }

    onExistingActionChanged (app, action) {
        // retrieve old index, replace with new object
        let obj = Object.assign({}, app);
        obj.action = action.id;

        let idx = this.state.user_applications.indexOf(app);
        let spliced = this.state.user_applications.slice();
        spliced.splice(idx, 1, obj);

        this.setState({ user_applications: spliced });
    }

    editCancel (app) {
        let action = this.state.actions_map[app.original_action];
        this.onExistingActionChanged(app, action);
    }

    editSave (app) {
        let saving = this.props.backend.setApplication(this.props.guid, app.id, { action: app.action })
            .then((response) => {
                this.setState({
                    saving_edit: { op: null, error: null },
                });

                this.loadData();
            })
            .catch((e) => {
                this.setState({ saving_edit: { op: null, error: e }});
            });

        this.setState({ saving_edit: { op: saving, error: null, id: app.id }});
    }

    editDelete (app) {
        let deleting = this.props.backend.setApplication(this.props.guid, app.id, { action: null })
            .then((response) => {
                this.setState({
                    deleting: { op: null, error: null },
                });

                this.loadData();
            })
            .catch((e) => {
                this.setState({ saving_edit: { op: null, error: e }});
            });

        this.setState({ deleting: { op: deleting, error: null, id: app.id }});
    }

    render () {
        let saveButton = null;

        let can_edit = this.props.permissionManager.hasPermission('users_service_providers_set');


        if (!this.state.loadingApplications.op && can_edit) {
            if (this.state.saving.op) {
                saveButton = <button type="button" className="btn btn-outline-success btn-sm" disabled>Saving...</button>
            } else {
                let disabled = this.state.new_application.application == null || this.state.new_application.action == null;
                saveButton = <button type="button" className="btn btn-outline-success btn-sm" disabled={disabled} onClick={this.save.bind(this)}>Save</button>
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingApplications ]}

                    left={() => {
                        return (
                            <Fragment>
                                <Link to={'/users'} className="btn btn-outline-secondary btn-sm">Back</Link>&nbsp;
                                <Link to={'/users/' + this.props.guid} className="btn btn-outline-secondary btn-sm">View</Link>
                            </Fragment>
                        );
                    }}
                    middle={() => (
                        <Fragment>
                            <AlertMessageDialog
                                errors={[ this.state.loadingApplications.error, this.state.saving.error ]}
                            />
                             <h5 style={{textAlign: 'center'}}>{this.state.user ? this.state.user.firstname + ' ' + this.state.user.lastname : null}</h5>
                        </Fragment>
                    )}
                    right={() => {
                        return null;
                    }}
                />
                <form className="offset-md-2 col-sm-8 mt-3">
                    <div className="card bg-light mt-3">
                        <div className="card-body">
                            <div className="float-right">
                                {saveButton}
                            </div>
                            <h5 className="card-title">New Application</h5>
                            <div className="form-group row">
                                <div className="col-sm">
                                    <Select options={this.state.applications} getOptionLabel={(p) => p.name} getOptionValue={(o) => o.id}
                                            value={this.state.new_application.application} onChange={this.onApplicationChanged.bind(this)}
                                            isLoading={this.state.loadingApplications.op}
                                            placeholder="Select an application..."
                                    />
                                </div>
                                <div className="col-sm">
                                    <Select options={this.state.actions} getOptionLabel={(p) => p.name} getOptionValue={(o) => o.id}
                                            value={this.state.new_application.action} onChange={this.onActionChanged.bind(this)}
                                            isLoading={this.state.loadingApplications.op}
                                            placeholder="Select an action..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="col-sm-8 offset-md-2 mt-3">
                    {
                        this.state.user_applications.map((app) => {
                            let buttons = null;
                            let deletebutton = null;
                            let action = this.state.actions[app.action];

                            if (app.original_action !== app.action && can_edit) {
                                buttons = (
                                    <Fragment>
                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={this.editCancel.bind(this, app)}>Cancel</button>&nbsp;
                                        <button type="button" className="btn btn-outline-success btn-sm" onClick={this.editSave.bind(this, app)}>{((this.state.saving_edit.op !== null && this.state.saving_edit.id === app.id) ? 'Saving...' : 'Save')}</button>&nbsp;
                                    </Fragment>
                                );
                            }

                            if (can_edit) {
                                deletebutton = (
                                    <Fragment>
                                        <button type="button" className="btn btn-outline-danger btn-sm" onClick={this.editDelete.bind(this, app)}>{((this.state.deleting.op !== null && this.state.deleting.id === app.id) ? 'Deleting...' : 'Delete')}</button>&nbsp;
                                    </Fragment>
                                )
                            }

                            return (
                                <div key={app.id} className="card bg-light mt-3">
                                    <div className="card-body">
                                        <div className="float-right">
                                            {deletebutton}
                                            {buttons}
                                        </div>
                                        <h5 className="card-title">{app.name}</h5>
                                        <Select options={this.state.actions} getOptionLabel={(p) => p.name} getOptionValue={(o) => o.id}
                                            value={action} onChange={this.onExistingActionChanged.bind(this, app)}
                                            isLoading={this.state.loadingApplications.op}
                                            placeholder="Select an action..."
                                            disabled={!can_edit}
                                            theme={(theme) => ({
                                              ...theme,
                                              colors: {
                                              ...theme.colors,
                                                neutral80: action.color,
                                              },
                                            })}
                                        />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default EditUserApplications;
