import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select'

import Cancelable from './cancelable.js';
import PageMenu from './PageMenu.js';
import Helper from './helper.js';
import AlertMessageDialog from './AlertMessageDialog.js';


export default class ShowGroup extends React.Component {
    state = {
        group: {},

        loadingGroup: { op: null },
        loadingAction: { op: null },
    }

    componentDidMount () {
        this.loadData();
    }

    componentWillUnmount () {
        if (this.state.loadingGroup.op) {
            this.state.loadingGroup.op.cancel();
        }
    }

    loadData () {
        let loadingGroup = new Cancelable(this.props.backend.getGroup(this.props.guid));

        loadingGroup
            .then(response => {
                let group = response.data;

                if (group.group_type) {
                    group.group_type = Object.keys(group.group_type)
                        .filter(t => group.group_type[t])
                        .map(t => ({ label: t, value: t }));
                }

                try {
                    group.allowed_hosts = JSON.parse(group.allowed_hosts)
                        .map(h => ({ value: h, label: h }));
                } catch (err) {
                    group.allowed_hosts = [];
                }

                this.setState({
                    loadingGroup: { op: null },
                    group,
                });
            })
            .catch(err => {
                console.log(err);
                this.setState({ loadingGroup: { op: null, error: err }});
            });

        this.setState({
            loadingGroup: { op: loadingGroup },
        });
    }

    box (prop, value) {
        return (
            <div key={prop.field} className="form-group row">
                <label className="col-sm-3 col-form-label">{prop.name}</label>
                <div className="col-sm-9">
                    {prop.control}

                    {!prop.control &&
                        <input type="text" readOnly className="form-control" value={value || ''}
                        />
                    }
                </div>
            </div>
        );
    }

    render () {
        let editBtn = null;
        let editMembersBtn = null;
        let fields = [];

        if (this.props.permissionManager.isAllowedOrganization('groups_edit', this.state.group.organization)) {
            editBtn = (
                <Link to={'/groups/' + this.props.guid + '/edit'} className="btn btn-outline-secondary btn-sm">
                    Edit
                </Link>
            );
        }

        if (this.props.permissionManager.isAllowedGroup(this.state.group.dn, 'groups_list')) {
            editMembersBtn = (
                <Link to={'/groups/' + this.props.guid + '/members'} className="btn btn-outline-secondary btn-sm mr-1">
                    Members
                </Link>
            );
        }

        if (this.props.permissionManager.isAllowedOrganization('groups_list', this.state.group.organization)) {
            fields = this.props.permissionManager.getAllowedFields('groups_list');
        }

        const groupTypeControl = (
            <Select
                options={this.state.group.group_type} defaultValue={this.state.group.group_type}
                isMulti isDisabled={true} placeholder="No types"
            />
        );

        const allowedHostsControl = (
            <Select
                value={this.state.group.allowed_hosts} defaultValue={this.state.group.allowed_hosts}
                isMulti isDisabled={true} placeholder="No allowed hosts"
            />
        );

        let viewFields = [];
        if (this.props.fields) {
            for (const field of this.props.fields) {
                switch (field.field) {
                    case 'group_type':
                        field.control = groupTypeControl;
                        break;

                    case 'allowed_hosts':
                        field.control = allowedHostsControl;
                        break;

                    default: break;
                }

                if (field.field === 'group_type') {
                    field.control = groupTypeControl;
                }

                if (fields.includes(field.field)) {
                    viewFields.push(field);
                }
            }
        }

        return (
            <div className="container">
                <PageMenu
                    onReload={this.loadData.bind(this)}
                    loaders={[ this.state.loadingGroup ]}

                    left={() => {
                        return <Link to={'/groups'} className="btn btn-outline-secondary btn-sm">Back</Link>
                    }}

                    middle={() => (
                        <Fragment>
                            <AlertMessageDialog
                                errors={[ this.state.loadingGroup.error ]}
                            />
                            <h5>{this.state.group ? this.state.group.groupname : null}</h5>
                        </Fragment>
                    )}

                    right={() => {
                        if (!this.state.group) {
                            return null;
                        }

                        return (
                            <Fragment>
                                {editMembersBtn}
                                {editBtn}
                            </Fragment>
                        )
                    }}
                />

                {this.state.group &&
                    <form className="offset-md-2 col-sm-8 mt-3">
                        {viewFields.map(prop => {
                            let value = this.state.group[prop.field];

                            switch (prop.field) {
                            case 'organization':
                                prop.control = null;
                                value = Helper.organizationDnToPath(value);
                                break;

                            case 'guid':
                                value = Helper.formatObjectGuid(value);
                                break;

                            default: break;
                            }

                            return this.box(prop, value);
                        })}
                        {this.props.fields && viewFields.length === 0 &&
                            <p className="text-center">No fields are allowed to be viewed.</p>
                        }
                    </form>
                }
            </div>
        );
    }
}
